import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Formik } from "formik";
import * as Yup from "yup";
//component
import { Container } from 'app/components/Content/Container';
import { Box, Button, Grid, TextField, Dialog, DialogTitle, DialogContent } from '@mui/material';

import { SimpleCard, Breadcrumb } from "app/components";
import { TextFieldReadOnlyDisabled, StyledTextField } from "app/components/Content/CustomInput";
import MGPhoneInput from "app/components/Content/MGPhoneInput";
import { LoadButton } from "app/components/Content/CustomLoadButton";
import SuccessDisplay from "app/components/Content/SuccessDisplay";
import ErrorDisplay from "app/components/Content/ErrorDisplay";
import SimpleTable from "app/components/table/SimpleTable";

import { findApplicationByReference, updateApplication, updateExpireDateApiKey,addNewApiKey } from "app/services/ApiKeyService";
import { formatDate } from "app/services/Utils";

const Application = () => {
     /* get application from url */
     const { reference } = useParams();
     const [app, setApp] = useState({application:{}});

     const getApp = (reference) => {
          findApplicationByReference(reference).then(res => {
               console.log(res.data.data)
               setApp(res.data.data)
          }).catch(err => {
               console.log(err)
          })
     }

     useEffect(() => {
          getApp(reference)
     }, []);

     /* form update application */
     const validationSchema = Yup.object().shape({
          appName: Yup.string().required('Nom requis'),
          appDescription: Yup.string(),
          managerName: Yup.string().required('Responsable requis'),
          managerEmail: Yup.string().email('Email invalide'),
          managerTelephone: Yup.string()
     });
     const [loading, setLoading] = useState(false);
     const [errorUpdate, setErrorUpdate] = useState(false);
     const [successUpdate, setSuccessUpdate] = useState(false);
     const [messageErrorUpdate, setMessageErrorUpdate] = useState('');


     const updateApp = (values) => {
          setLoading(true);
          updateApplication(app.reference, values).then((res) => {
               setLoading(false);
               setErrorUpdate(false);
               setSuccessUpdate(true);
          }).catch((err) => {
               setLoading(false);
               setErrorUpdate(true);
               setSuccessUpdate(false);
               setMessageErrorUpdate(err.response.data.message);
          });
     }

     /* get and display all apikeys of an app */
     const [loadingApiKeys, setLoadingApiKeys] = useState(false);

     const columnsApiKey = [
          { name: "Nom", selector: (row) => row.name || "", style: { width: '180px' } },
          { name: "Clé", selector: (row) => row.key || "", style: { width: '250px' } },
          { name: "Date d'expiration", selector: (row) => row.expiredAt ? formatDate(new Date(row.expiredAt)) : "", style: { width: '200px' } },

          {
               name: "Modifier date d'expiration", selector: (row) => {

                    return (
                         <>
                              <Box sx={{
                                   '& .MuiTextField-root': { m: 1, width: '25ch' },
                              }}>
                                   <TextField
                                        size="small"
                                        type="datetime-local"
                                        name="appName"
                                        label="Date d'expiration"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        id={"apikey-" + row.idApiKey}
                                   />
                                   <Button variant="contained" sx={{ marginTop: "9px" }} onClick={() => updateExpireDate(row.idApiKey)} >Modifier</Button>
                              </Box>
                         </>
                    )
               }, style: { width: '400px' }
          },
     ];

     /* form update expire date of an apikey */
     const updateExpireDate = (id) => {
          var tempDate = document.querySelector("#apikey-" + id).value;
          tempDate = tempDate == '' ? null : ((tempDate + ":00").replace("T", " "));
          console.log(tempDate);
          updateExpireDateApiKey(id, tempDate).then((res) => {
               getApp(reference);
          }).catch((err) => {
               console.error(err)
          })
     }

     /* form add an apikey */
     const [open, setOpen] = useState(false);
     const handleOpen = () => setOpen(true);
     const handleClose = () => setOpen(false);

     const generateApiKey=()=>{
          var date=document.querySelector("#newApikey-date").value;
          date = date == '' ? null : ((date + ":00").replace("T", " "));
          addNewApiKey(reference, date).then((res)=>{
               getApp(reference);
               handleClose();
          }).catch((err)=>{
               console.error(err);
          })
     }
     return (
          <Container>
               <SuccessDisplay open={successUpdate} setOpen={setSuccessUpdate} message="Modifications enregistrées" />

               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={[{ name: 'Applications', path: '/applications' }, { name: `` }]}
                    />
               </Box>

               <SimpleCard title="Applications">
                    <Formik
                         onSubmit={(values) => {
                              updateApp(values);
                         }}
                         validationSchema={validationSchema}
                         initialValues={app.application}
                         enableReinitialize={true}
                         method="POST"
                    >
                         {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                              <form onSubmit={handleSubmit}>
                                   <Grid container spacing={6}>
                                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                             <h4>Informations de l'application</h4>
                                             <StyledTextField
                                                  size="small"
                                                  type="text"
                                                  name="appName"
                                                  label="Nom"
                                                  variant="outlined"
                                                  onBlur={handleBlur}
                                                  value={values.appName || ""}
                                                  onChange={handleChange}
                                                  helperText={touched.appName && errors.appName}
                                                  error={Boolean(errors.appName && touched.appName)}
                                             />
                                             <TextFieldReadOnlyDisabled label="Reférence"
                                                  value={app.application?.reference || ""}
                                             />
                                             <StyledTextField
                                                  size="small"
                                                  type="text"
                                                  name="appDescription"
                                                  label="Description"
                                                  variant="outlined"
                                                  multiline={true}
                                                  rows={3}
                                                  onBlur={handleBlur}
                                                  value={values.appDescription || ""}
                                                  onChange={handleChange}
                                                  helperText={touched.appDescription && errors.appDescription}
                                                  error={Boolean(errors.appDescription && touched.appDescription)}
                                             />

                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                             <h4>Informations du responsable</h4>
                                             <StyledTextField
                                                  size="small"
                                                  type="text"
                                                  name="managerName"
                                                  label="Responsable"
                                                  variant="outlined"
                                                  onBlur={handleBlur}
                                                  value={values.managerName || ""}
                                                  onChange={handleChange}
                                                  helperText={touched.managerName && errors.managerName}
                                                  error={Boolean(errors.managerName && touched.managerName)}
                                             />
                                             <StyledTextField
                                                  size="small"
                                                  type="text"
                                                  name="managerEmail"
                                                  label="Email Responsable"
                                                  variant="outlined"
                                                  onBlur={handleBlur}
                                                  value={values.managerEmail || ""}
                                                  onChange={handleChange}
                                                  helperText={touched.managerEmail && errors.managerEmail}
                                                  error={Boolean(errors.managerEmail && touched.managerEmail)}
                                             />
                                             <MGPhoneInput name="managerTelephone" onChange={(event) => {
                                                  setFieldValue("managerTelephone", event);
                                             }}
                                                  value={values.managerTelephone || ""}
                                                  onBlur={handleBlur}
                                                  helperText={touched.managerTelephone && errors.managerTelephone}
                                                  error={Boolean(errors.managerTelephone && touched.managerTelephone)}
                                                  withValidation={false}
                                             />
                                        </Grid>
                                   </Grid>
                                   <LoadButton loading={loading} >Enregistrer les modifications</LoadButton>
                                   <ErrorDisplay error={errorUpdate} messageError={messageErrorUpdate} />
                              </form>
                         )}
                    </Formik>
               </SimpleCard>
               <br />
               <SimpleCard title="Liste des clés API">
                    {/* form apikey */}
                    <Button variant="contained" onClick={handleOpen}>
                         + Nouvelle Clé
                    </Button>
                    <Dialog onClose={handleClose} aria-labelledby="form-dialog-title" open={open}>
                         <DialogContent dividers>
                              <DialogTitle id="form-dialog-title" onClose={handleClose} component="div">
                                   Générer une nouvelle clé
                              </DialogTitle>
                              <Box sx={{
                                   '& .MuiTextField-root': { m: 1, width: '25ch' },
                              }}>
                                   <p>Vous pouvez définir ou non la date d'expiration</p>
                                   <TextField
                                        size="small"
                                        type="datetime-local"
                                        name="appName"
                                        label="Date d'expiration"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        id="newApikey-date"
                                   />
                                   <Button variant="contained" sx={{ marginTop: "9px" }} onClick={()=>generateApiKey()}>Générer</Button>
                              </Box>
                         </DialogContent>
                    </Dialog>

                    {app.apiKeys ? <SimpleTable data={app.apiKeys} columns={columnsApiKey} loading={loading}></SimpleTable> : ""}

               </SimpleCard>
          </Container>
     );
}

export default Application;
