
//component
import { useState } from "react";
import * as Yup from 'yup';
import { Grid,Typography } from "@mui/material";

import ClientStatus from "app/components/client/ClientStatus";
import SuccessDisplay from "app/components/Content/SuccessDisplay";
import ErrorDisplay from "app/components/Content/ErrorDisplay";
import { Formik } from "formik";
import { StyledTextField, TextFieldReadOnlyDisabled } from "app/components/Content/CustomInput";
import { LoadButton } from "app/components/Content/CustomLoadButton";

import { formatCompleteDate } from "app/services/Utils";
import { updateClientBeforeValidation } from "app/services/ClientService";

const validationSchema = Yup.object().shape({
     lastName: Yup.string().required('Nom requis'),
     firstNames: Yup.string().required('Prénom(s) requis'),
     telephone: Yup.string().required('Téléphone requis').min(10, 'Téléphone invalide'),
     email: Yup.string().required("Email requis").email('Email invalide'),
     identityNumber: Yup.string().required("Numéro de pièce d'identité requis"),
     comments: Yup.string().nullable(),
});

const ClientInfo = ({ client }) => {
     const [loading, setLoading] = useState(false);
     const [messageError, setMessageError] = useState('');
     const [error, setError] = useState(false);
     const [success, setSuccess] = useState(false);

     const handleFormSubmit = async (values) => {
          setLoading(true);
          setSuccess(false);
          values.statusCode = client.statusCode;
          await updateClientBeforeValidation(values, client.clientUID).then((res) => {
               setLoading(false);
               setError(false);
               setSuccess(true);
               // dispatchClientInfo(dispatch, clientUID);
          }).catch((err) => {
               setMessageError(err.response.data.message);
               setLoading(false);
               setError(true);
          })
     }
     return (
          <>
               <SuccessDisplay open={success} setOpen={setSuccess} message="Informations modifiées avec succès" />
               <ErrorDisplay open={error} setOpen={setError} message={messageError} />
               <Formik
                    onSubmit={handleFormSubmit}
                    validationSchema={validationSchema}
                    initialValues={client}
                    method="POST"
                    enableReinitialize
               >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                         <form onSubmit={handleSubmit}>
                              <Grid container spacing={6}>
                                   <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                        <StyledTextField
                                             size="small"
                                             type="text"
                                             name="lastName"
                                             label="Nom"
                                             variant="outlined"
                                             onBlur={handleBlur}
                                             value={values.lastName || ""}
                                             onChange={handleChange}
                                             helperText={touched.lastName && errors.lastName}
                                             error={Boolean(errors.lastName && touched.lastName)}
                                        />
                                        <StyledTextField
                                             size="small"
                                             type="text"
                                             name="firstNames"
                                             label="Prénom(s)"
                                             variant="outlined"
                                             onBlur={handleBlur}
                                             value={values.firstNames || ""}
                                             onChange={handleChange}
                                             helperText={touched.firstNames && errors.firstNames}
                                             error={Boolean(errors.firstNames && touched.firstNames)}
                                        />
                                        <StyledTextField
                                             size="small"
                                             type="text"
                                             name="identityNumber"
                                             label="CIN"
                                             variant="outlined"
                                             onBlur={handleBlur}
                                             value={values.identityNumber || ""}
                                             onChange={handleChange}
                                             helperText={touched.identityNumber && errors.identityNumber}
                                             error={Boolean(errors.identityNumber && touched.identityNumber)}
                                        />
                                        <StyledTextField
                                             size="small"
                                             type="text"
                                             name="telephone"
                                             label="Téléphone"
                                             variant="outlined"
                                             onBlur={handleBlur}
                                             value={values.telephone || ""}
                                             onChange={handleChange}
                                             helperText={touched.telephone && errors.telephone}
                                             error={Boolean(errors.telephone && touched.telephone)}
                                        />
                                        <StyledTextField
                                             size="small"
                                             type="text"
                                             name="email"
                                             label="Email"
                                             variant="outlined"
                                             onBlur={handleBlur}
                                             value={values.email || ""}
                                             onChange={handleChange}
                                             helperText={touched.email && errors.email}
                                             error={Boolean(errors.email && touched.email)}
                                        />
                                        <TextFieldReadOnlyDisabled label="ClientUID"
                                             value={client.clientUID || ""}
                                             multiline={true}
                                             rows="2"
                                        />
                                   </Grid>
                                   <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                        <Typography>Statut : <ClientStatus code={client.statusCode} /></Typography>
                                        <TextFieldReadOnlyDisabled label="Compte de frais (principal)"
                                             value={client.feeAccountUID || ""}
                                        />
                                        <StyledTextField
                                             size="small"
                                             type="text"
                                             name="comments"
                                             label="Commentaire"
                                             variant="outlined"
                                             multiline={true}
                                             rows="3"
                                             onBlur={handleBlur}
                                             value={values.comments || ""}
                                             onChange={handleChange}
                                             helperText={touched.comments && errors.comments}
                                             error={Boolean(errors.comments && touched.comments)
                                             }
                                        />
                                        <TextFieldReadOnlyDisabled type="datetime"
                                             label="Date de création"
                                             value={formatCompleteDate(client.dateTimeCreated) || ""}
                                        />
                                        <TextFieldReadOnlyDisabled
                                             type="datetime"
                                             label="Date de modification"
                                             value={formatCompleteDate(client.dateTimeModified) || ""}
                                        />
                                   </Grid>
                              </Grid>
                              <LoadButton loading={loading}>Modifier</LoadButton>

                              <ErrorDisplay error={error} messageError={messageError} />
                         </form>
                    )}
               </Formik >

          </>
     );
};

export default ClientInfo;
