import { Card, Fab, Grid, Icon, lighten, styled, useTheme } from '@mui/material';

const ContentBox = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
}));

const FabIcon = styled(Fab)(() => ({
  width: '44px !important',
  height: '44px !important',
  boxShadow: 'none !important',
}));

const H3 = styled('h3')(({ textcolor }) => ({
  margin: 0,
  color: textcolor,
  fontWeight: '500',
  marginLeft: '12px',
}));

const H1 = styled('h1')(({ theme }) => ({
  margin: 0,
  flexGrow: 1,
  color: theme.palette.text.secondary,
}));

const Span = styled('span')(({ textcolor }) => ({
  fontSize: '13px',
  color: textcolor,
  marginLeft: '4px',
}));

const IconBox = styled('div')(() => ({
  width: 16,
  height: 16,
  color: '#fff',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '300px ',
  justifyContent: 'center',
  '& .icon': { fontSize: '14px' },
}));
const StatCard = ({ icon="trending_up",  statName, statCount, percentage="", expandIconPercentage=false, color=[9, 182,109]
, sx,...props}) => {
     const baseColor=`rgb(${color[0]}, ${color[1]}, ${color[2]})`;
     const baseColorLight=`rgb(${color[0]}, ${color[1]}, ${color[2]}, 0.15)`;
     return (
               <Card elevation={3} sx={{ p: 2,...sx }} {...props}>
                    <ContentBox>
                         <FabIcon size="medium" sx={{ background: baseColorLight}}>
                              <Icon sx={{ color: baseColor }}>{icon}</Icon>
                         </FabIcon>
                         <H3 textcolor={baseColor}>{statName}</H3>
                    </ContentBox>

                    <ContentBox sx={{ pt: 2 }}>
                         <H1>{statCount}</H1>
                        {expandIconPercentage===true? <IconBox sx={{ background: baseColorLight }} >
                              <Icon className="icon">expand_less</Icon>
                         </IconBox>:""}
                         <Span textcolor={baseColor}>{percentage}</Span>
                    </ContentBox>
               </Card>
     )
}
export default StatCard;
