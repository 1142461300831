import React, { useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

const SuccessDisplay = ({ message, open = false, setOpen, severity="success" }) => {
     return (
          <Snackbar open={open} autoHideDuration={100000000} onClose={() => { setOpen(false) }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
          >
               <Alert onClose={() => { setOpen(false) }} severity={severity} sx={{ width: '100%'}} variant="filled">
                    {message}
               </Alert>
          </Snackbar>
     )
}
export default SuccessDisplay;
