import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { dispatchClientInfo } from 'app/redux/store';

//component
import { Grid, Icon, IconButton, Dialog, Switch, DialogTitle, DialogContent, FormControlLabel, MenuItem, Button } from "@mui/material";
import ErrorDisplay from "app/components/Content/ErrorDisplay";
import SuccessDisplay from "app/components/Content/SuccessDisplay";
import { LoadButton } from "app/components/Content/CustomLoadButton";
import { TextFieldReadOnlyDisabled, StyledTextField } from "app/components/Content/CustomInput";

import { updateAccount } from "app/services/AccountService";
import { formatCompleteDate, formattedNumber } from "app/services/Utils";


const validationSchema = Yup.object().shape({
     status: Yup.string().required('Le statut est requis'),
});

const Account = ({ clientUID, account }) => {
     const dispatch = useDispatch();
     const [loading, setLoading] = useState(false);
     const [messageError, setMessageError] = useState('');
     const [error, setError] = useState(false);
     const [success, setSuccess] = useState(false);

     const [open, setOpen] = useState(false);
     const handleOpen = () => setOpen(true);
     const handleClose = () => {
          setError(false);
          setOpen(false);
     }

     const initialValues = {
          accountUID: account.accountUID,
          balance: account.balance,
          balanceModified: account.balanceModified,
          gatewayReference: account.gatewayReference,
          acceptsDeposit: account.acceptsDeposit,
          acceptsWithdrawal: account.acceptsWithdrawal,
          status: account.statusCode,
          accountTypeName: account.accountTypeName,
     }

     const handleFormSubmit = async (values) => {
          setLoading(true);
          setSuccess(false);
          try {
               await updateAccount(account.accountUID, values.status).then((res) => {
                    handleClose();
                    setLoading(false);
                    setError(false);
                    dispatchClientInfo(dispatch, clientUID);
                    setSuccess(true);
               })
          } catch (e) {
               setMessageError(e.response.data.message)
               setLoading(false);
               setError(true);
          }
     }
     return (
          <div>
               <SuccessDisplay open={success} setOpen={setSuccess} message="Compte modifié avec succès" />

               <Button onClick={handleOpen}>
                    {account.accountUID}
               </Button>

               <Dialog onClose={handleClose} aria-labelledby="form-dialog-title" open={open}>
                    <DialogContent dividers>
                         <DialogTitle id="form-dialog-title" onClose={handleClose} component="div">
                              Informations générales
                         </DialogTitle>


                         <Formik
                              onSubmit={handleFormSubmit}
                              validationSchema={validationSchema}
                              initialValues={initialValues}
                              method="POST"
                         >
                              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                   <form onSubmit={handleSubmit}>
                                        <Grid container spacing={6}>
                                             <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                                  <TextFieldReadOnlyDisabled label="Numéro de compte"
                                                       value={values.accountUID || ""}
                                                       multiline={true}
                                                       rows="3"
                                                  />
                                                  <TextFieldReadOnlyDisabled label="Solde"
                                                       value={(formattedNumber(values.balance) || 0).toString() + " Ar"}
                                                  />
                                                  <TextFieldReadOnlyDisabled type="date-time"
                                                       label="Dernière modification du solde"
                                                       value={formatCompleteDate(values.balanceModified) || ""}
                                                  />

                                             </Grid>
                                             <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                                  <TextFieldReadOnlyDisabled label="Type de compte"
                                                       multiline={true}
                                                       rows="3"
                                                       value={`${values.accountTypeName} (${account.accountType.acceptsDeposit ? "accepte les dépots" : "n'accepte pas les dépôts"}, ${account.accountType.acceptsWithdrawal ? "accepte les retraits" : "n'accepte pas les retraits"})` || ""}
                                                  />
                                                  {account.statusCode !== "ENABLED" && account.statusCode !== "BLOCKED" ?
                                                       null
                                                       :
                                                       <StyledTextField
                                                            select
                                                            size="small"
                                                            type="text"
                                                            name="status"
                                                            label="Statut"
                                                            variant="outlined"
                                                            onBlur={handleBlur}
                                                            value={values.status || ""}
                                                            onChange={handleChange}
                                                            helperText={touched.status && errors.status}
                                                            error={Boolean(errors.status && touched.status)}
                                                       >
                                                            <MenuItem value="ENABLED">Activé</MenuItem>
                                                            <MenuItem value="BLOCKED">Bloqué</MenuItem>
                                                       </StyledTextField>
                                                  }
                                             </Grid>
                                        </Grid>
                                        {account.statusCode !== "ENABLED" && account.statusCode !== "BLOCKED" ? null :
                                             <LoadButton loading={loading}>Modifier</LoadButton>}
                                        <ErrorDisplay error={error} messageError={messageError} />
                                   </form>
                              )}
                         </Formik>

                    </DialogContent>
               </Dialog>
          </div>
     )
}
export default Account;
