import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';


//component
import { Grid, Button, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { StyledTextField } from "app/components/Content/CustomInput";
import ErrorDisplay from "app/components/Content/ErrorDisplay";
import { LoadButton } from "app/components/Content/CustomLoadButton";

import { addNewClient } from "app/services/ClientService";
import MGPhoneInput from "app/components/Content/MGPhoneInput";

const validationSchema = Yup.object().shape({
     lastName: Yup.string().required('Nom requis'),
     firstNames: Yup.string().required('Prénom(s) requis'),
     telephone: Yup.string().required('Téléphone requis, et invalide'),
     email: Yup.string().email('Email invalide').required("Email requis"),
     identityNumber: Yup.string().required("Numéro de pièce d'identité requis"),
});

const FormClient = () => {
     const navigate = useNavigate();
     const [loading, setLoading] = useState(false);

     const [messageError, setMessageError] = useState('');
     const [error, setError] = useState(false);


     const [open, setOpen] = useState(false);
     const handleOpen = () => setOpen(true);
     const handleClose = () => setOpen(false);

     const initialValues = {
          firstNames: '',
          lastName: '',
          telephone: '',
          email: '',
          identityNumber: '',
          gatewayReference: ''
     }

     const handleFormSubmit = async (values) => {
          setLoading(true);
          try {
               await addNewClient(values).then((res) => {
                    //navigate to espace client
                    setLoading(false);
                    setError(false);
                    navigate(`/client/${res.data.data.clientUID}/validations`);         
               })
          } catch (e) {
               setMessageError(e.response.data.message)
               setLoading(false);
               setError(true);
          }
     }

     return (
          <div>

               <Button variant="contained" color="primary" onClick={handleOpen}>Ajouter</Button>

               <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                         Ajouter un nouveau client
                    </DialogTitle>
                    <DialogContent dividers>
                         <Formik
                              onSubmit={handleFormSubmit}
                              validationSchema={validationSchema}
                              initialValues={initialValues}
                              method="POST">
                              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                   <form onSubmit={handleSubmit}>
                                        <Grid container spacing={6}>
                                             <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                                  <StyledTextField
                                                       size="small"
                                                       type="text"
                                                       name="lastName"
                                                       label="Nom"
                                                       variant="outlined"
                                                       onBlur={handleBlur}
                                                       value={values.lastName}
                                                       onChange={handleChange}
                                                       helperText={touched.lastName && errors.lastName}
                                                       error={Boolean(errors.lastName && touched.lastName)}
                                                  />

                                                  <StyledTextField
                                                       size="small"
                                                       type="text"
                                                       name="firstNames"
                                                       label="Prénom(s)"
                                                       variant="outlined"
                                                       onBlur={handleBlur}
                                                       value={values.firstNames}
                                                       onChange={handleChange}
                                                       helperText={touched.firstNames && errors.firstNames}
                                                       error={Boolean(errors.firstNames && touched.firstNames)}
                                                  />
                                                  <StyledTextField
                                                       size="small"
                                                       type="text"
                                                       name="identityNumber"
                                                       label="CIN"
                                                       variant="outlined"
                                                       onBlur={handleBlur}
                                                       value={values.identityNumber}
                                                       onChange={handleChange}
                                                       helperText={touched.identityNumber && errors.identityNumber}
                                                       error={Boolean(errors.identityNumber && touched.identityNumber)}
                                                  />
                                             </Grid>
                                             <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                                  <MGPhoneInput name="telephone" onChange={(event) => {
                                                       setFieldValue("telephone", event);
                                                  }}
                                                       value={values.telephone || ""}
                                                       onBlur={handleBlur}
                                                       helperText={touched.telephone && errors.telephone}
                                                       error={Boolean(errors.telephone && touched.telephone)}
                                                       withValidation={true}
                                                       touched={touched}
                                                  />
                                                  <StyledTextField
                                                       size="small"
                                                       type="text"
                                                       name="email"
                                                       label="Email"
                                                       variant="outlined"
                                                       onBlur={handleBlur}
                                                       value={values.email}
                                                       onChange={handleChange}
                                                       helperText={touched.email && errors.email}
                                                       error={Boolean(errors.email && touched.email)}
                                                  />
                                             </Grid>
                                        </Grid>
                                        <LoadButton loading={loading}>Ajouter</LoadButton>
                                        <ErrorDisplay error={error} messageError={messageError} />
                                   </form>
                              )}
                         </Formik>
                    </DialogContent>
               </Dialog>
          </div>
     );
};

export default FormClient;
