import { baseUrl, getCall, postCall, putCall } from './Api.js';

export const getAccountsByClientUID = async (clientUID) => {
     return getCall(`${baseUrl}/cbs/client/${clientUID}/accounts`, true);
}

export const addNewAccount = async (clientUID,type) => {
     return postCall(`${baseUrl}/cbs/client/${clientUID}/account`,null, true, {type:type});
}

export const updateAccount = async (accountUID, status) => {
     return putCall(`${baseUrl}/cbs/account/${accountUID}`, null, true, { status: status });
}
export const updateFeeAccount = async (clientUID, param) => {
     return putCall(`${baseUrl}/cbs/client/${clientUID}/fee_account`, null, true, param);
}
export const getAccountStatement=(accountUID, startDate, endDate)=>{
     return getCall(`${baseUrl}/cbs/account/${accountUID}/statement`, true, {startDate:startDate, endDate:endDate});
}
export const getAllAccounts=(page, searchParam)=>{
     const param={
          offset:page,
          limit:10,
          type:searchParam.type ? searchParam.type : null,
          status:searchParam.status ? searchParam.status : null,
          accountUID:searchParam.accountUID ? searchParam.accountUID : null,
          clientKeyword:searchParam.clientKeyword? searchParam.clientKeyword : null,
     }
     return getCall(`${baseUrl}/cbs/accounts`,true,param);
}
export const getAccountByUID=(accountUID)=>{
     return getCall(`${baseUrl}/cbs/account/${accountUID}`, true);
}

export const getAccountParams=()=>{
     return getCall(`${baseUrl}/cbs/accountparams`, true);
}
export const getAccountTypes=()=>{
     return getCall(`${baseUrl}/cbs/account_types`, true);
}
export const getAccountStatusBeforeValidation=()=>{
     return getCall(`${baseUrl}/cbs/accountstatus/novalidate`, true);
}
export const phaseAccount=()=>{
     return [
          {step:
               {statusName: 'Création de compte', statusCode: 'CREATED', icon:'fiber_new', resultCode:['CREATED', 'FAILED']}  
                     
          },
          {step:
               {statusName: 'Premier dépôt de compte', statusCode: 'FIRST_DEPOSIT', icon: 'attach_money', resultCode:['FIRST_DEPOSIT', 'FAILED']
          }},
          {step:{statusName: 'Validation de compte', statusCode: 'ACTIVED', icon: 'priority_high', resultCode:['ACTIVED', 'REFUSED']}},
          {step:{statusName: "Retirer l'argent du compte", statusCode:"REFUSED", icon:"attach_money", resultCode:["REFUSED"]}},
     ]
}

export const  doActionAccount=(action, accountUID, comments)=>{
     const data={
          comments:comments
     }
     switch(action){
          case 'BLOCKED': return postCall(`${baseUrl}/cbs/account/${accountUID}/block`, null, true, data);
          case 'ACTIVED': return postCall(`${baseUrl}/cbs/account/${accountUID}/approve`,null,  true, data);
          case 'FIRST_DEPOSIT': return postCall(`${baseUrl}/cbs/account/${accountUID}/firstdeposit`,null, true, data);
          case 'REFUSED': return postCall(`${baseUrl}/cbs/account/${accountUID}/refuse`,null, true, data);
          case 'FAILED': return postCall(`${baseUrl}/cbs/account/${accountUID}/failedfirstdeposit`,null,  true, data);
     }
}
