import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dispatchClientInfo } from 'app/redux/store';
//component
import { Box, Switch, Icon, IconButton, Grid, TextField, Button, Typography } from "@mui/material";
import SimpleTable from "app/components/table/SimpleTable";
import FormAccount from "./FormAccount";
import Account from "./Account";
import FeeAccount from "./FeeAccount";
import ClientNav from "../clients/ClientNav";
import AccountStatus from 'app/components/account/AccountStatus';
import LoadingContainer from "app/components/Content/LoadingContainer";

import { formatDate, formattedNumber } from "app/services/Utils";


const ClientAccounts = () => {
     const { clientUID } = useParams();
     const dispatch=useDispatch();
     const navigate=useNavigate();
     const clientInfo=useSelector((state) => state.clientInfo.dataClient);
     const [loading, setLoading] = useState(false);

     const columns = [
          {
               name: 'Compte UID',
               selector: (row) => (<Account account={row} clientUID={clientUID} />),
               style: { width: '220px' }
          },
          {
               name: 'Solde',
               selector: (row) => (<>{formattedNumber(row.balance)} Ar</>),
          },
          {
               name: 'Modification solde',
               selector: (row) =>formatDate(new Date(row.balanceModified)),
               style: { width: '200px' }
          },
          {
               name: 'Statut',
               selector: (row) => (<><AccountStatus code={row.statusCode} gameColor={1} /></>),
          },
          {
               name: 'Type de compte',
               selector: (row) => (row.accountTypeName)
          },{
               name:'Relevé',
               selector:(row)=>(
                    <IconButton onClick={() => navigate(`/account/${row.accountUID}/statement`)}>
                         <Icon color="secondary">compare_arrows</Icon>
                    </IconButton>)
          }
     ];
     useEffect(() => {
          dispatchClientInfo(dispatch);
          setLoading(true);
          dispatchClientInfo(dispatch, clientUID, setLoading);
          
     }, []);
     return (
          <ClientNav client={clientInfo.client} currentValue="2" clientUID={clientUID} section={{name:"Comptes"}}>
               <Box
                    sx={{
                         display: 'flex',
                         flexDirection: 'row-reverse',
                         bgcolor: 'background.paper'
                    }}
               >
                    <FeeAccount clientUID={clientUID} accounts={clientInfo.accounts} currentFeeAccount={clientInfo.client.feeAccountUID} />
                    <FormAccount clientUID={clientUID} />
               </Box>
               <br />

               <Grid container spacing={6}>
                    <Grid item lg={6} md={6} sm={12} xs={12} >
                         <TextField
                              label="Compte de frais"
                              value={clientInfo.client.feeAccountUID || ""}
                              size="small"
                              type="text"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              sx={{ width: '100%' }}
                         />
                    </Grid>
               </Grid>
               <br />

               <SimpleTable data={clientInfo.accounts} columns={columns} loading={loading}></SimpleTable>
          </ClientNav>)
}
export default ClientAccounts;
