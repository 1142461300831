import { useState } from 'react';
import { dispatchUser } from "app/redux/store";
import { useDispatch, useSelector } from 'react-redux';
import useAuth from 'app/hooks/useAuth';

//component
import { Grid } from "@mui/material";
import { StyledTextField } from "app/components/Content/CustomInput";
import { LoadButton } from 'app/components/Content/CustomLoadButton';
import ErrorDisplay from 'app/components/Content/ErrorDisplay';
import SuccessDisplay from 'app/components/Content/SuccessDisplay';
import PrivilegeContent from 'app/components/Content/PrivilegeContent';

import { updatePassword } from 'app/services/UserService';

const Password = ({ iduser }) => {
     // const {currentUser} = useAuth();
     const dispatch = useDispatch();
     const user = useSelector((state) => state.user.user);
     const [passwords, setPasswords] = useState({
          password: '',
          passwordConfirm: ''
     });
     const [loading, setLoading] = useState(false);
     const [messageError, setMessageError] = useState('');
     const [error, setError] = useState(false);
     const [success, setSuccess] = useState(false);


     const handleChange = (event, name) => {
          setPasswords({ ...passwords, [name]: event.target.value });
     }
     const handleFormSubmit = async () => {
          setSuccess(false);
          if (passwords.password === passwords.passwordConfirm) {
               if (passwords.password.length < 8) {
                    setMessageError("Le mot de passe doit contenir au moins 8 caractères")
                    setError(true);
                    return;
               } else {
                    try {
                         setLoading(true);
                         await updatePassword(passwords.password, user).then((res) => {
                              setPasswords({
                                   password: '',
                                   passwordConfirm: ''
                              });
                              setSuccess(true);
                              setLoading(false);
                              setError(false);
                              dispatchUser(dispatch, user.iduser);
                         })
                    } catch (e) {
                         setMessageError(e.response.data.message)
                         setLoading(false);
                         setError(true);
                    }
               }
          } else {
               setMessageError("Le mot de passe ne correspond pas à la confirmation")
               setError(true);
          }
     }
     return (
          <div>
               <SuccessDisplay open={success} setOpen={setSuccess} message="Modification enregistrée" />
               <Grid container spacing={6}>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                         <PrivilegeContent role={["ADMIN"]}>
                              <StyledTextField
                                   size="small"
                                   type="password"
                                   name="actualPassword"
                                   label="Mot de passe actuel"
                                   variant="outlined"
                                   value={passwords.actualPassword || ""}
                                   onChange={(e) => handleChange(e, "actualPassword")}
                              />
                         </PrivilegeContent>
                         <StyledTextField
                              size="small"
                              type="password"
                              name="password"
                              label="Mot de passe"
                              variant="outlined"
                              value={passwords.password || ""}
                              onChange={(e) => handleChange(e, "password")}

                         />
                         <StyledTextField
                              size="small"
                              type="password"
                              name="passwordConfirm"
                              label="Confirmer le mot de passe"
                              variant="outlined"
                              value={passwords.passwordConfirm || ""}
                              onChange={(e) => handleChange(e, "passwordConfirm")}
                         />
                         <LoadButton loading={loading} onClick={handleFormSubmit}>Modifier</LoadButton>
                         <ErrorDisplay error={error} messageError={messageError} />
                    </Grid>
               </Grid>
          </div >
     )
}
export default Password;
