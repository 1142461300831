import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { dispatchUser } from "app/redux/store";

//component
import { LoadButton } from "app/components/Content/CustomLoadButton";
import { FormControlLabel, Checkbox, FormGroup, DialogTitle } from "@mui/material";
import ErrorDisplay from "app/components/Content/ErrorDisplay";
import SuccessDisplay from "app/components/Content/SuccessDisplay";

import { getAllRoles, updateRoles } from "app/services/UserService";

const Privilege = () => {
     const dispatch = useDispatch();
     const user = useSelector((state) => state.user.user);
     const [loading, setLoading] = useState(false);
     const [messageError, setMessageError] = useState('');
     const [error, setError] = useState(false);
     const [success, setSuccess] = useState(false);
     const [allRoles, setAllRoles] = useState([]);
     const [checkedValues, setCheckedValues] = useState(user.roles.map((role) => role.idrole));

     const handleFormSubmit = async () => {
          setLoading(true);
          setSuccess(false);
          try {
               await updateRoles(checkedValues, user).then((res) => {
                    setLoading(false);
                    setError(false);
                    setSuccess(true);
                    dispatchUser(dispatch, user.iduser);
               })
          } catch (e) {
               setMessageError(e.response.data.message)
               setLoading(false);
               setError(true);
          }
     }
     const isInRole = (idrole) => {
          const find = user.roles.find((role) => role.idrole === idrole);
          if (find === undefined) {
               return false;
          }
          return true;
     }
     const [initialValue, setInitialValue] = useState([]);

     const handleChecked = (e) => {
          const name = e.target.name;
          const check = e.target.checked;
          const value = e.target.value;
          setInitialValue({
               ...initialValue,
               [name]: check
          })
          setCheckedValues((prevCheckedValues) => {
               if (check) {
                    return [...prevCheckedValues, parseInt(value)];
               } else {
                    return prevCheckedValues.filter((checkedValue) => checkedValue !== parseInt(value));
               }
          });
     };
     useEffect(() => {
          getAllRoles().then((res) => {
               setAllRoles(res.data.data);
               let init = [];
               res.data.data.forEach((role) => {
                    init[role.idrole] = isInRole(role.idrole);
               })
               setInitialValue(init);
          });
     }, []);

     return (
          <div>
               <SuccessDisplay open={success} setOpen={setSuccess} message="Modification enregistrée" />
               <DialogTitle id="form-dialog-title" component="div">
                    Liste des privilèges: {user.name}
               </DialogTitle>
               <FormGroup>
                    {allRoles.map((role, index) => (
                         <FormControlLabel
                              key={index}
                              control={
                                   <Checkbox onChange={handleChecked} value={role.idrole} checked={initialValue[role.idrole]} />
                              }
                              name={role.idrole.toString()}
                              label={`${role.name} (${role.code})`}
                         />
                    ))}

                    <LoadButton loading={loading} onClick={handleFormSubmit}>Modifier</LoadButton>

                    <ErrorDisplay error={error} messageError={messageError} />

               </FormGroup>
          </div>
     )
}
export default Privilege;
