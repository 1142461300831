import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { dispatchClientInfo } from 'app/redux/store';
import { useNavigate } from "react-router-dom";

//component
import { Button, Box, Dialog, Switch, FormControlLabel, DialogTitle, DialogContent, MenuItem } from "@mui/material";
import ErrorDisplay from "app/components/Content/ErrorDisplay";
import { LoadButton } from "app/components/Content/CustomLoadButton";
import SuccessDisplay from "app/components/Content/SuccessDisplay";

import { addNewAccount, getAccountTypes } from "app/services/AccountService";
import { StyledTextField } from "app/components/Content/CustomInput";

const validationSchema = Yup.object().shape({
     code: Yup.string().required("Le code est obligatoire").min(1, "Le code est obligatoire"),
});

const FormAccount = ({ clientUID }) => {
     const dispatch = useDispatch();
     const [loading, setLoading] = useState(false);
     const [messageError, setMessageError] = useState('');
     const [error, setError] = useState(false);
     const [success, setSuccess] = useState(false);

     const [accountTypes, setAccountTypes] = useState([]);
     const [open, setOpen] = useState(false);
     const handleOpen = () => setOpen(true);
     const handleClose = () => setOpen(false);

     const initialValues = {
          code:"",
     }

     const handleFormSubmit = async (values) => {
          setLoading(true);
          setSuccess(false);
          try {
               await addNewAccount(clientUID, values.code).then((res) => {
                    handleClose();
                    setLoading(false);
                    setError(false);
                    dispatchClientInfo(dispatch, clientUID);
                    setSuccess(true);             
               })
          } catch (e) {
               setMessageError(e.response.data.message)
               setLoading(false);
               setError(true);
               setSuccess(false);
          }
     }
     useEffect(() => {
          try {
               getAccountTypes().then((res) => {
                    setAccountTypes(res.data.data);
               });
          } catch (ex) {
               console.error(ex);
          }
     }, [])
     return (
          <div>
               <SuccessDisplay open={success} setOpen={setSuccess} message="Compte ajouté avec succès" />
               <Button variant="contained" color="primary" onClick={handleOpen}>Ajouter</Button>
               <Dialog onClose={handleClose} aria-labelledby="form-dialog-title" open={open}>

                    <DialogTitle id="form-dialog-title" onClose={handleClose} component="div">
                         Ajouter un nouveau compte
                    </DialogTitle>
                    <DialogContent dividers>
                         <Formik
                              onSubmit={handleFormSubmit}
                              validationSchema={validationSchema}
                              initialValues={initialValues}
                              method="POST"
                              enableReinitialize
                         >
                              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                   <form onSubmit={handleSubmit}>
                                        <StyledTextField
                                             select
                                             size="small"
                                             type="text"
                                             name="code"
                                             label="Type de compte"
                                             variant="outlined"
                                             onBlur={handleBlur}
                                             value={values.code || ""}
                                             onChange={handleChange}
                                             helperText={touched.code && errors.code}
                                             error={Boolean(errors.code && touched.code)}
                                        >
                                             {accountTypes.map((option, index) => (
                                                  <MenuItem key={index} value={option.code}>
                                                       {option.name} ({option.code})
                                                  </MenuItem>
                                             ))}
                                        </StyledTextField>

                                        <LoadButton loading={loading}>Ajouter</LoadButton>

                                        <ErrorDisplay error={error} messageError={messageError} />
                                   </form>
                              )}
                         </Formik>
                    </DialogContent>
               </Dialog>
          </div>
     );
}
export default FormAccount;
