
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//component
import { Icon, IconButton, TextField, Box } from "@mui/material";
import { SimpleCard, Breadcrumb } from "app/components";
import { PaginationTable } from "app/components/table/PaginationTable";
import { Container } from 'app/components/Content/Container';
import Filter from "app/components/Content/Filter";
import FormClient from './FormClient';

import { findAllClients } from "app/services/ClientService";
import ClientStatus from "app/components/client/ClientStatus";
import PrivilegeContent from 'app/components/Content/PrivilegeContent';

const Clients = () => {
     const navigate = useNavigate();
     const [searchParam, setSearchParam] = useState({ status: "ENABLED" });
     const [clients, setClients] = useState([]);
     const [loading, setLoading] = useState(false);
     const [page, setPage] = useState(0);
     const [totalClient, setTotalClient] = useState(0);
     const columns = [
          {
               name: 'Nom',
               selector: (row) => row.lastName,
               style: { width: '100px' }
          },
          {
               name: 'Prénom(s)',
               selector: (row) => row.firstNames,
               style: { width: '100px' }
          },
          {
               name: 'Téléphone',
               selector: (row) => row.telephone,
               style: { width: '110px' }
          },
          {
               name: 'Email',
               selector: (row) => row.email,
               style: { width: '200px' }
          },
          {
               name: 'Statut',
               selector: (row) => (<ClientStatus code={row.statusCode} />),
               style: { width: '100px' }
          },
          {
               name: 'Action',
               selector: (row) => (<IconButton onClick={() => navigate(`/client/${row.clientUID}/detail/1`)}>
                    <Icon color="primary">edit</Icon>
               </IconButton>),
               style: { width: '50px' }
          },
          {
               name: 'Opérations',
               selector: (row) => (<PrivilegeContent roles={["ADMIN", "BASIC_USER", "OPERATION"]} alt="Non permis"><IconButton onClick={() => navigate(`/client/${row.clientUID}/operation`)}>
                    <Icon color="secondary">compare_arrows</Icon>
               </IconButton></PrivilegeContent>
               ),
               style: { width: '70px' }

          },
          {
               name: 'Historique validation',
               selector: (row) => (<PrivilegeContent roles={["ADMIN", "BASIC_USER", "OPERATION"]} alt="Non permis"><IconButton onClick={() => navigate(`/client/${row.clientUID}/validations`)}>
                    <Icon color="secondary">history</Icon>
               </IconButton></PrivilegeContent>),
               style: { width: '100px' }

          }
     ];

     const getClients = (paramPage = null) => {
          setLoading(true);
          findAllClients(searchParam, paramPage == null ? page : paramPage, true).then((response) => {
               setClients(response.data.data.list);
               setTotalClient(response.data.data.total);
               setLoading(false);
          }).catch((error) => {
               setLoading(false);
               console.error(error);
          });
     }
     const handleSearch = () => {
          getClients(0);
     }
     const handleSearchChange = (event, param) => {
          if (param === "isEnabled") {
               setSearchParam({ ...searchParam, [param]: event.target.checked });
          } else {
               setSearchParam({ ...searchParam, [param]: event.target.value });
          }
     }
     const handleChangePage = (_, newPage) => {
          newPage === 1 ? setPage(page + 1) : setPage(page - 1);
          getClients(newPage === 1 ? (page + 1) : (page - 1));
     };
     useEffect(() => {
               getClients();
     }, [page]);

     return (
          <Container>
               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={[{ name: 'Clients', path: '/clients' }]}
                    />
               </Box>

               <PrivilegeContent roles={["ADMIN", "BASIC_USER", "CLIENT"]}>
                    <Box
                         sx={{
                              display: 'flex',
                              flexDirection: 'row-reverse',
                              borderRadius: 1,
                         }}
                    >
                         <FormClient />
                    </Box>
               </PrivilegeContent>

               <br />
               <Filter handleSearch={handleSearch}>
                    <TextField id="outlined-basic" label="Recherche" variant="outlined" value={searchParam.search || ""} onChange={(event) => handleSearchChange(event, "search")} />
                    <TextField id="outlined-basic" label="Téléphone" variant="outlined" value={searchParam.telephone || ""} onChange={(event) => handleSearchChange(event, "telephone")} />
                    <TextField id="outlined-basic" label="Email" variant="outlined" value={searchParam.email || ""} onChange={(event) => handleSearchChange(event, "email")} />

                    {/* <div>
                         <FormControlLabel
                              sx={{ p: 1 }}
                              control={
                                   <Checkbox checked={searchParam.isEnabled||false} onChange={(event) => handleSearchChange(event, "isEnabled")} value="true" sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
                              }
                              label="Actif"
                         />
                    </div> */}
               </Filter>
               <br />

               <SimpleCard title="Clients">
                    <br />
                    <PaginationTable data={clients} columns={columns} handleChangePage={handleChangePage} page={page} loading={loading} count={totalClient}></PaginationTable>
               </SimpleCard>
          </Container >
     );
};

export default Clients;
