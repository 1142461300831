import { useTheme } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import { formattedNumber } from 'app/services/Utils';

//legend=[namex, name y]
const BarEchart = ({ title = "", height, color = ['#223388'], legend = ["x", "y"], data }) => {
     const theme = useTheme();
     // console.log(data);
     const option = {
          grid: { top: '10%', bottom: '10%', right: '5%' },
          legend: { show: true },
          color: color,
          barGap: 0,
          barMaxWidth: '64px',
          dataset: {
               source: [
                    legend,
                    ...data
               ]
          },
          xAxis: {
               type: 'category',
               axisLine: { show: false },
               splitLine: { show: false },
               axisTick: { show: false },
               axisLabel: {
                    fontSize: 13,
                    fontFamily: 'roboto',
                    color: theme.palette.text.secondary
               }
          },
          yAxis: {
               axisLine: { show: false },
               axisTick: { show: false },
               splitLine: {
                    lineStyle: { color: theme.palette.text.secondary, opacity: 0.15 }
               },
               axisLabel: {
                    fontSize: 13,
                    fontFamily: 'roboto',
                    color: theme.palette.text.secondary,
                    formatter: formattedNumber
               }
          },
          // Declare several bar series, each will be mapped
          // to a column of dataset.source by default.
          series: [{
               type: 'bar',
               data: data,
               label: {
                    show: true, // Display the label
                    position: 'top', // You can change the position if needed
                    formatter: ((val) => formattedNumber(val.data[1])),
                    textStyle: {
                         fontSize: 12, // Adjust the font size
                    },
               },
          }]
     };
     return (

          <ReactEcharts style={{ height: height }} option={{ ...option }} />
     );

}
export default BarEchart;
