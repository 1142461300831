import { baseUrl, getCall, postCall, putCall } from './Api.js';

const clientUrl = `${baseUrl}/cbs/client`;
export const findAllClients = async (searchParam, page, isEnabled=true) => {
     const param = {
          firstnames: searchParam.search ? searchParam.search : null,
          lastname: searchParam.search ? searchParam.search : null,
          email: searchParam.email ? searchParam.email : null,
          telephone: searchParam.telephone ? searchParam.telephone : null,
          gatewayReference: searchParam.gateway ? searchParam.gateway : null,
          offset: (page),
          limit: 10,
          status: searchParam.status ? searchParam.status : null,
          isEnabled:isEnabled
     }
     return getCall(`${clientUrl}s`, true, param);
}

export const addNewClient = async (data) => {
     data.gatewayReference = data.gatewayReference==='' ?   null:data.gatewayReference;
     return postCall(`${clientUrl}`, data, true);
}

export const getClientByUID = async (clientUID) => {
     return getCall(`${clientUrl}/${clientUID}`, true);
}
export const getClientInfoByUID = async (clientUID) => {
     return getCall(`${clientUrl}/${clientUID}/infos`, true);
}
export const updateClient = async (data, clientUID) => {
     data={
          ...data,
          status:{code:data.statusCode}
     }
     return putCall(`${clientUrl}/${clientUID}`, data, true);
}
export const updateClientBeforeValidation=(data, clientUID) =>{
     return putCall(`${clientUrl}/${clientUID}/update_before_validation`, data, true);
}
export const updateClientApplication=(data,clientUID)=>{
     return putCall(`${clientUrl}/${clientUID}/gatewayreferences`,  data,true );
}
export const elasticSearch = async (keyword) => {
     const param = {
          keyword: keyword
     }
     return getCall(`${clientUrl}s/elastic_search`, true, param);
}
export const doClientValidationAction=( codeAction,data,clientUID,accountUID) => {
     switch(codeAction){
          case "FIRST_ACCOUNT": return postCall(`${clientUrl}/${clientUID}/first_account`, null, true, data);
          case "FIRST_DEPOSIT": return postCall(`${clientUrl}/${clientUID}/account/${accountUID}/first_deposit`, null, true, data);
          case "APPROVED": return postCall(`${clientUrl}/${clientUID}/accepting_validation`, null, true, data);
          case "REFUSED":     return postCall(`${clientUrl}/${clientUID}/refusing_validation`, null, true, data);
          case "CANCELLED_FIRST_DEPOSIT":    return postCall(`${clientUrl}/${clientUID}/account/${accountUID}/cancelled_first_deposit`, null, true, data);
          case "CANCELLED_FIRST_ACCOUNT":    return postCall(`${clientUrl}/${clientUID}/cancelled_first_account`, null, true, data);
          case "CANCELLED_CREATION_CLIENT":   return postCall(`${clientUrl}/${clientUID}/cancelled`, null, true, data);
          case "REINITIALIZE_PROCESS": return postCall(`${clientUrl}/${clientUID}/reinitialize_processus`, null, true, data);
     }
}

export const getClientStatus=()=>{
     return getCall(`${clientUrl}_status`, true);
}
export const findAllValidationClient=(page, notifie=null)=>{
     return getCall(`${baseUrl}/validation_clients`, true, {offset:page, limit:10, notifie:notifie});
}    
export const nofifyLateValidationClient=(id)=>{
     return postCall(`${baseUrl}/validation_client/${id}/notify`, null, true);
}
export const reinitializeStep=(clientUID)=>{
     return postCall(`${clientUrl}/${clientUID}/reinitialize_process`, null, true);
}
