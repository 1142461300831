import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dispatchApiKeys } from "app/redux/store";
import { useNavigate } from "react-router-dom";
//component
import {
     Box, TextField, Button
} from "@mui/material";
import { Container } from 'app/components/Content/Container';
import { SimpleCard, Breadcrumb } from "app/components";
import Filter from "app/components/Content/Filter";
import { PaginationTable } from "app/components/table/PaginationTable";
import FormApplication from "./FormApplication";

const Applications = ({ }) => {
     const dispatch = useDispatch();
     const navigate = useNavigate();
     const [searchParam, setSearchParam] = useState("");
     const [page, setPage] = useState(0);

     const [loading, setLoading] = useState(false);
     const apikeys = useSelector((state) => state.apiKeys.apiKeys.list)
     const totalApikeys = useSelector((state) => state.apiKeys.apiKeys.total)
     useEffect(() => {
          setLoading(true);
          dispatchApiKeys(dispatch, searchParam, page, setLoading)
     }, []);

     const handleSearch = () => {
          setLoading(true);
          dispatchApiKeys(dispatch, searchParam, 0, setLoading);
     }
     const handleSearchChange = (event) => {
          setSearchParam(event.target.value);
     }

     const handleChangePage = (_, newPage) => {
          setLoading(true);
          newPage === 1 ? setPage(page + 1) : setPage(page - 1);

          dispatchApiKeys(dispatch, searchParam, newPage === 1 ? (page + 1) : (page - 1), setLoading);
     };

     const columns = [
          {
               name: 'Référence',
               selector: (row) => (<Button onClick={() => {
                    console.log(row)
                    navigate('/application/' + row.reference);
               }}>{row.reference || ""}</Button>),
               style: { width: '200px' }
          },
          {
               name: 'Nom',
               selector: (row) => row.appName || "",
               style: { width: '180px' }
          },
          {
               name: 'Description',
               selector: (row) => row.appName || "",
               style: { width: '250px' }
          },
          {
               name: 'Responsable',
               selector: (row) => row.managerName || "",
               style: { width: '100px' }
          },
          {
               name: 'Email du responsable',
               selector: (row) => row.managerEmail || "",
               style: { width: '200px' }
          }
     ];
     return (
          <Container>
               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={[{ name: 'Applications', path: '/applications' }]}
                    />
               </Box>
               <Box
                    sx={{
                         display: 'flex',
                         flexDirection: 'row-reverse',
                         borderRadius: 1,
                    }}
               >
                    <FormApplication />
               </Box>
               <br />
               <Filter handleSearch={handleSearch}>
                    <TextField id="outlined-basic" label="Recherche" variant="outlined" value={searchParam || ""} onChange={(event) => handleSearchChange(event)} />
               </Filter>
               <br />
               <SimpleCard title="Clés API">
                    <PaginationTable data={apikeys} columns={columns} handleChangePage={handleChangePage} page={page} loading={loading} count={totalApikeys}></PaginationTable>
               </SimpleCard>
          </Container >
     );
}
export default Applications;
