import { useState, useEffect } from "react";

//component
import { PaginationTable } from "app/components/table/PaginationTable";
import { TextField, MenuItem, Box, useTheme } from "@mui/material";
import Filter from "app/components/Content/Filter";
import DetailOperation from "./DetailOperation";
import Small from "app/components/Content/Small";
import { Container } from 'app/components/Content/Container';
import { SimpleCard, Breadcrumb } from "app/components";


import { getAllOperations, getOperationTypes } from "app/services/OperationService";
import { formatDate, formattedNumber } from "app/services/Utils";

const Operations = () => {
     const { palette } = useTheme();
     const bgPrimary = palette.primary.main;
     const bgSecondary = palette.secondary.main;

     const [searchParam, setSearchParam] = useState({});
     const [operations, setOperations] = useState([]);
     const [operationTypes, setOperationTypes] = useState([]);

     const [loading, setLoading] = useState(false);
     const [page, setPage] = useState(0);
     const [totalOperation, setTotalOperation] = useState(0);

     const columns = [
          {
               name: 'Référence',
               selector: (row) => (<DetailOperation operation={row} />),
               style: { width: '200px' }
          },
          {
               name: 'Description',
               selector: (row) => row.label,
               style: { width: '200px' }
          },
          {
               name: 'Montant',
               selector: (row) => {
                    // const amount=row.clientUID===clientUID?formattedNumber(row.amount):formattedNumber(row.amount*-1);
                    return row.amount < 0 ? (
                         <Small bgcolor={bgSecondary}>{formattedNumber(row.amount)} Ar</Small>
                    ) : (
                         <Small bgcolor={bgPrimary}>{formattedNumber(row.amount)} Ar</Small>
                    )
               },
               style: { width: '100px' }
          },
          {
               name: 'Compte',
               selector: (row) => `${row.clientName} (${row.accountUID})`,
               style: { width: '200px' },
          },
          {
               name: 'Date',
               selector: (row) => formatDate(new Date(row.dateTime)),
               style: { width: '200px' }
          },
          {
               name: 'Compte Transfert',
               selector: (row) =>
                    (<>{row.otherClientName} <div>{row.otherAccountUID?`(${row.otherAccountUID})`:""}</div></>),
               style: { width: '200px' }
          }
     ];
     const getOperation = async (currentPage = null) => {
          setLoading(true);
          getAllOperations(currentPage == null ? page : currentPage, searchParam).then((res) => {
               setOperations(res.data.data.list);
               setTotalOperation(res.data.data.total);
               setLoading(false);
          }).catch((ex)=>{
               setLoading(false);
               Promise.reject(ex);
          })
     }
     const getOperationsType = async () => {
          getOperationTypes().then((res) => {
               setOperationTypes(res.data.data)
          });
     }


     const handleChangePage = (_, newPage) => {
          newPage === 1 ? setPage(page + 1) : setPage(page - 1);
          getOperation(newPage === 1 ? (page + 1) : (page - 1));
     };
     const handleSearch = () => {
          getOperation(0);
     }
     const handleSearchChange = (event, param) => {
          setSearchParam({ ...searchParam, [param]: event.target.value })
     }
     useEffect(() => {
          try {
               getOperation();
               getOperationsType();
          } catch (e) {
               console.error(e);
          }
     }, []);
     return (
          <Container>
               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={[{ name: 'Opérations', path: '/operations' }]}
                    />
               </Box>

               <br />
               <Filter handleSearch={handleSearch}>
                    <TextField
                         id="outlined-basic" label="Référence" variant="outlined" value={searchParam.reference || ""}
                         onChange={(event) => handleSearchChange(event, "reference")} />
                    <TextField
                         id="outlined-basic" label="Date début" variant="outlined" value={searchParam.startDate || ""}
                         onChange={(event) => handleSearchChange(event, "startDate")} type="date" InputLabelProps={{ shrink: true }} />
                    <TextField
                         id="outlined-basic" label="Date fin" variant="outlined" value={searchParam.endDate || ""}
                         onChange={(event) => handleSearchChange(event, "endDate")} type="date" InputLabelProps={{ shrink: true }} />
                    <TextField
                         id="outlined-select-currency"
                         select
                         label="Compte"
                         onChange={(event) => handleSearchChange(event, "accountUID")}
                         value={searchParam.accountUID || ""}
                    >
                         <MenuItem key='1account' value={""}>
                              Tous
                         </MenuItem>
                    </TextField>
                    <TextField
                         id="outlined-select-currency"
                         select
                         label="Opération type"
                         onChange={(event) => handleSearchChange(event, "idOperationType")}
                         value={searchParam.idOperationType || ""}
                    >
                         <MenuItem key='1op' value={""}>
                              Tous
                         </MenuItem>
                         {operationTypes.map((option) => (
                              <MenuItem key={option.code} value={option.idOperationType}>
                                   {option.name}
                              </MenuItem>
                         ))}
                    </TextField>
               </Filter>
               <br />
               <SimpleCard title="Opérations">
                    <PaginationTable data={operations} columns={columns} handleChangePage={handleChangePage} page={page}
                    loading={loading} count={totalOperation}></PaginationTable>
               </SimpleCard>
          </Container>
     )
}
export default Operations;
