import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Container } from 'app/components/Content/Container';
import {
     Box, TextField, Icon, IconButton, Button, MenuItem,
     Select, useTheme
} from "@mui/material";
import { SimpleCard, Breadcrumb } from "app/components";
import SimpleTable from "app/components/table/SimpleTable";
import Small from "app/components/Content/Small";
import { LoadButton } from "app/components/Content/CustomLoadButton";
import SuccessDisplay from "app/components/Content/SuccessDisplay";

import { getReport, findApplicationByReference, sendReport } from 'app/services/ApiKeyService';
import { formatDate, formattedNumber } from "app/services/Utils";

const Report = ({ }) => {
     const { reference } = useParams();
     const [data, setData] = useState([]);
     const [loading, setLoading] = useState(false);
     const [application, setApplication] = useState({});

     const { palette } = useTheme();
     const bgSuccess = palette.success.main;
     const bgError = palette.error.main;

     const getData = () => {
          setLoading(true);
          getReport(reference).then((res) => {
               if(res.data!==""){
                    const lines = res.data.split("\n");
                    const linesObject = [];
                    for (let i = 0; i < lines.length; i++) {
                         const l = lines[i].split(";");
                         if(l.length===6){
                              linesObject.push({ clientName: l[0], email: l[1], telephone: l[2], clientUID: l[3], status: l[4], date: l[5].replace("\r", "").replace(" ", "T") })
                         }
                    }
                    setData(linesObject);
               }
               setLoading(false);

          }).catch((err) => {
               console.error(err);
               setLoading(false);
          })
     }
     const getApp = () => {
          findApplicationByReference(reference).then((res) => {
               setApplication(res.data.data);
          }).catch((err) => {
               console.error(err);
          })
     }
     const columns = [
          {
               name: 'Client',
               selector: (row) => row.clientName,
          },
          {
               name: 'Email',
               selector: (row) => row.email,
          },
          {
               name: 'Téléphone',
               selector: (row) => row.telephone,
          },
          {
               name: 'ClientUID',
               selector: (row) => row.clientUID,
          },
          {
               name: 'Statut',
               selector: (row) => {
                    return row.status === "added" ? (
                         <Small bgcolor={bgSuccess}>{row.status}</Small>
                    ) : (
                         <Small bgcolor={bgError}>{row.status}</Small>
                    )
               }
          }, {
               name: 'Date',
               selector: (row) => formatDate(new Date(row.date))
          }
     ];
     useEffect(() => {
          getData();
          getApp();
     }, [])

     const [success, setSuccess] = useState(false);
     const [failed, setFailed] = useState(false);
     const [messageError, setMessageError] = useState("");
     const [loadingReport, setLoadingReport] = useState(false);
     const report = () => {
          setLoadingReport(true);
          setSuccess(false);
          setFailed(false);
          sendReport(reference).then(() => {
               setSuccess(true);
               setLoading(false);
          }).catch((err) => {
               setFailed(true);
               setLoading(false);
               if (err.response.data.message) {
                    setMessageError(err.response.data.message);
               } else {
                    setMessageError("Erreur lors de l'envoie de rapport");
               }
          });
     }
     return (<>
          <Container>
               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={[{ name: 'Clés API', path: '/apikeys' }, { name: reference }, { name: "Rapport" }]}
                    />
               </Box>
               <SimpleCard >
                    Le dernier envoie de rapport fut le {application.lastReportDate?formatDate(new Date(application.lastReportDate)):""}.

                    <Box
                         sx={{
                              display: 'flex',
                              flexDirection: 'row-reverse',
                              borderRadius: 1,
                         }}>
                         {data.length>0?
                         <LoadButton variant="contained" loading={loadingReport} onClick={report} type="submit" color="primary">Envoyer rapport</LoadButton>:null}
                         <SuccessDisplay open={success} setOpen={setSuccess} message="Rapport envoyée" />
                         <SuccessDisplay open={failed} setOpen={setFailed} severity="error" message={messageError} />
                    </Box>
                    {data.length>0?<SimpleTable data={data} columns={columns} loading={loading} />:"Aucun rapport"}

               </SimpleCard>
          </Container>
     </>)
}
export default Report;
