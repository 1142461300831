import { createSlice } from "@reduxjs/toolkit";

const initialState = {
     dataClient: {
          client: {},
          accounts: [], 
          validationClients: [], 
          firstAccount:{}
     }
}

export const clientInfoSlice = createSlice({
     name: "clientInfo",
     initialState,
     reducers: {
          setClientInfo: (state, action) => {
               state.dataClient = action.payload;
          }
     }
});

export const { setClientInfo } = clientInfoSlice.actions;
export default clientInfoSlice.reducer;
