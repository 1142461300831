import PropTypes from 'prop-types'
import { Box, styled, Table, TableBody, TableCell, TableHead, TablePagination, 
     TableRow, TableContainer, Paper, CircularProgress } from "@mui/material";


const StyledTable = styled(Table)(() => ({
     whiteSpace: "pre",
     "& thead": {
          "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
     },
     "& tbody": {
          "& tr": { "& td": { paddingLeft: 0 } },
     },
}));

export const PaginationTable = (
     {
          data, columns, title, selectable, linkFunction=(row)=>{}, handleRowSelection, contextActions, selectableRowDisabled,
          clearRow, handleChangePage, page, rowsPerPage = 10, loading = false, count }) => {
               // console.log(data);
     if (loading) return <Box width="100%" align="center"><CircularProgress className="progress" /></Box>
     else
          return (
               <Box width="100%" overflow="auto">
                    <TableContainer component={Paper}>
                         <StyledTable>
                              <TableHead>
                                   <TableRow >
                                        {columns.map((column, index) => {
                                             return (
                                                  <TableCell key={index} align={column.align ? column.align : "left"} children={column.name} sx={column.style}>
                                                  </TableCell>
                                             );
                                        })}
                                   </TableRow>
                              </TableHead>
                              <TableBody>
                                   {data.map((row, index) => (
                                        <TableRow key={index} onClick={()=>{linkFunction(row)}}>
                                             {columns.map((column, i) => {
                                                  return (
                                                       <TableCell key={i} align={column.align ? column.align : "left"} sx={column.style}>
                                                            {column.selector(row)}
                                                       </TableCell>
                                                  );
                                             })}
                                        </TableRow>
                                   ))}
                              </TableBody>
                         </StyledTable>
                    </TableContainer>

                    <TablePagination
                         sx={{ px: 2 }}
                         page={0}
                         count={count || data.length || 0}
                         component="div"
                         rowsPerPage={data.length || rowsPerPage  }
                         onPageChange={handleChangePage}
                         rowsPerPageOptions={[10]}
                         nextIconButtonProps={{ "aria-label": "Next Page", disabled: data.length < rowsPerPage ? true : false }}
                         backIconButtonProps={{ "aria-label": "Previous Page", disabled: page === 0 ? true : false }}
                    />
               </Box>
          );
};

PaginationTable.propTypes = {
     data: PropTypes.array.isRequired,
     columns: PropTypes.array.isRequired,
     title: PropTypes.string,
     selectable: PropTypes.bool,
     linkFunction: PropTypes.func,
     handleRowSelection: PropTypes.func,
     contextActions: PropTypes.object,
     selectableRowDisabled: PropTypes.func,
     clearRow: PropTypes.bool,
     handleChangePage: PropTypes.func,
     page: PropTypes.number,
     loading: PropTypes.bool,
     count: PropTypes.number,
     
}

