import { getCall, postCall, baseUrl, putCall } from './Api';

export const findAllUser = async (searchParam, page) => {
     const param = {
          name: searchParam.name || "",
          email: searchParam.email || "",
          telephone: searchParam.telephone || "",
          offset: (page),
          limit: 10
     }
     //console.log(param);
     return getCall(`${baseUrl}/cbs/users`, true, param);
}
export const addUser = async (info, roles) => {
     const data = {
          ...info,
          roles: roles.map((role) => ({ idrole: role })),
     }
     //console.log(data);
     return postCall(`${baseUrl}/cbs/user`, data, true);
}
export const updateUser = async (data, userUID) => {
     return putCall(`${baseUrl}/cbs/user/${userUID}`, data, true);
}
export const updateUserInfo = async (data, user) => {
     data = {
          iduser: user.iduser,
          ...data,
          roles: user.roles
     };
     return updateUser(data, user.iduser);
}
export const updateRoles = async (data, user) => {
     data = {
          iduser: user.iduser,
          ...user,
          roles: data.map((role) => ({ idrole: role })),
     };
     return updateUser(data, user.iduser);
}
export const updatePassword = async (password, user) => {
     const data = {
          ...user,
          password: password,
     }
     return updateUser(data, user.iduser);
}

export const getAllRoles = async () => {
     return getCall(`${baseUrl}/cbs/roles`, true);
}
export const getUserById = async (userUID) => {
     return getCall(`${baseUrl}/cbs/user/${userUID}`, true);
}
