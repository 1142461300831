import { useNavigate } from 'react-router-dom'

import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Container } from 'app/components/Content/Container';
import { SimpleCard, Breadcrumb } from "app/components";
import useAuth from 'app/hooks/useAuth';


const ClientNav = ({ client, clientUID, currentValue,section={}, children }) => {
     const navigate=useNavigate();
     const handleChange = (event, tab) => {
          if (tab !== parseInt(currentValue) && tab !== currentValue.toString()) {
               navigate(`/client/${clientUID}/detail/${tab}`);
          }
     };
     const { user } = useAuth();

     const show=(roles)=>{
          let bool = false;
          if (roles.length === 0) bool = true;
          else {
               for (let i = 0; i < roles.length; i++) {
                    if (user.roles.includes(roles[i])) {
                         bool = true;
                         break;
                    }
               }
          }
          return bool;
     }
     return (
          <Container>
               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={[{ name: 'Clients', path: '/clients' },
                         { name: `${client.lastName || ""} ${client.firstNames || ""}` },
                         section
                         ]}
                    />
               </Box>
               <SimpleCard>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                         <TabContext value={currentValue}>
                              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                   <TabList onChange={handleChange} aria-label="espace-client">
                                        {show(['ADMIN', 'BASIC_USER', 'CLIENT'])==true?<Tab label="Informations Générales" value="1"/>:null} 
                                       
                                        {show(['ADMIN', 'BASIC_USER','ACCOUNT'])==true?<Tab label="Comptes" value="2"/>:null}
                                        {show(['ADMIN', 'BASIC_USER','OPERATION'])==true?<Tab label="Opérations" value="3" />:null}
                                        {show(['ADMIN', 'BASIC_USER','CLIENT', 'ANALYTICS'])==true?<Tab label="Analyses" value="4"/>:null}

                                        {/* <Tab label="Informations Générales" value={1} />
                                       
                                        <Tab label="Comptes" value={2} />
                                        <Tab label="Opérations" value={3} />
                                        <Tab label="Analyses" value={4} /> */}
                                   </TabList>
                              </Box>
                              <TabPanel value={currentValue}>
                                   {children}
                               </TabPanel>
                         </TabContext>
                    </Box>
               </SimpleCard>
          </Container>
     );
}
export default ClientNav;
