import { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { dispatchClientInfo } from 'app/redux/store';

//component
import { Button, Box, MenuItem, Select, DialogTitle, DialogContent, Dialog, FormControl, InputLabel } from "@mui/material";
import { LoadButton } from "app/components/Content/CustomLoadButton";
import ErrorDisplay from "app/components/Content/ErrorDisplay";
import SuccessDisplay from "app/components/Content/SuccessDisplay";

import { updateFeeAccount } from "app/services/AccountService";

const validationSchema = Yup.object().shape({
     feeAccountUID: Yup.string().min(11, 'accountUID must be 11 charactere minimum').required('Compte de frais requis'),
});

const FeeAccount = ({ clientUID, accounts, currentFeeAccount}) => {
     const dispatch = useDispatch();
     const [loading, setLoading] = useState(false);
     const [open, setOpen] = useState(false);
     const [error, setError] = useState(false);
     const [messageError, setMessageError] = useState('');
     const [success, setSuccess] = useState(false);

     const handleOpen = () => setOpen(true);
     const handleClose = () => {
          setError(false); setOpen(false);
     }

     const handleFormSubmit = async (values) => {
          setLoading(true);
          setSuccess(false);
          try {
               await updateFeeAccount(clientUID, values).then((res) => {
                    handleClose();
                    setLoading(false);
                    setError(false);
                    dispatchClientInfo(dispatch, clientUID);
                    setSuccess(true);
               })
          } catch (e) {
               setMessageError(e.response.data.message)
               setLoading(false);
               setError(true);
          }
     }
     const initialValues = {
          feeAccountUID: currentFeeAccount
     }
     return (
          <div>
               <SuccessDisplay open={success} setOpen={setSuccess} message="Compte de frais modifié avec succès" />

               <Button variant="contained" color="secondary" onClick={handleOpen} style={{ marginLeft: '10px' }}>Changer compte de frais</Button>
               <Dialog onClose={handleClose} aria-labelledby="form-dialog-title" open={open}>

                    <DialogTitle id="form-dialog-title" onClose={handleClose} component="div">
                         Changer de compte de frais
                    </DialogTitle>
                    <DialogContent dividers>
                         <Formik
                              onSubmit={handleFormSubmit}
                              validationSchema={validationSchema}
                              initialValues={initialValues}
                              method="POST"
                         >
                              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                   <form onSubmit={handleSubmit}>
                                        <Box>
                                             <FormControl fullWidth>
                                                  <InputLabel id="demo-simple-select-label">Compte de frais</InputLabel>
                                                  <Select
                                                       labelId="demo-simple-select-label"
                                                       id="demo-simple-select"
                                                       label="Choix"
                                                       value={values.feeAccountUID}
                                                       onChange={handleChange}
                                                       error={touched.feeAccountUID && Boolean(errors.feeAccountUID)}
                                                       onBlur={handleBlur}
                                                       name="feeAccountUID"
                                                  >
                                                       {accounts.map((account, index) => account.isEnabled ? (
                                                            <MenuItem key={index} value={account.accountUID}
                                                                 selected={account.accountUID === values.feeAccountUID}
                                                            >{account.accountUID}</MenuItem>
                                                       ) : null
                                                       )}

                                                  </Select>
                                                  {errors.feeAccountUID && touched.feeAccountUID ? (
                                                       <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-ftcob6-MuiFormHelperText-root">{errors.telephone}</p>
                                                  ) : null}
                                             </FormControl>
                                        </Box>
                                        <LoadButton loading={loading}>Changer</LoadButton>

                                        <ErrorDisplay error={error} messageError={messageError} />
                                   </form>
                              )}
                         </Formik>
                    </DialogContent>
               </Dialog>
          </div>
     )
}
export default FeeAccount;
