import { useState } from "react";

//component
import { Grid, Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import { TextFieldReadOnly } from "app/components/Content/CustomInput";

import { formatDate,formattedNumber } from "app/services/Utils";

const DetailOperation = ({ operation }) => {
     const [open, setOpen] = useState(false);
     const handleOpen = () => setOpen(true);
     const handleClose = () => setOpen(false);

     return (
          <div>
               {/* <IconButton onClick={handleOpen}>
                    <Icon color="primary" >call_made</Icon>
               </IconButton> */}
               <Button  onClick={handleOpen}>{operation.reference}</Button>

               <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                         Détails de l'opération
                    </DialogTitle>
                    <DialogContent dividers>

                         <Grid container spacing={6}>
                              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                                   <TextFieldReadOnly
                                        label="Description"
                                        value={operation.label || ""}
                                   />
                                   <TextFieldReadOnly
                                        label="Opération Code"
                                        value={operation.operationTypeCode || ""}
                                   />
                                   <TextFieldReadOnly
                                        label="Montant"
                                        value={formattedNumber(operation.amount) + " Ar" || ""}
                                   />
                                   <TextFieldReadOnly
                                        label="Date"
                                        value={formatDate(new Date(operation.dateTime )) || ""}
                                   />
                                   <TextFieldReadOnly
                                        label="UID Compte"
                                        value={operation.accountUID || ""}
                                   />
                                   <TextFieldReadOnly
                                        label="Client du Transfert"
                                        value={operation.otherClientUID || ""}
                                   />
                                   <TextFieldReadOnly
                                        label="UID Compte Client du Transfert"
                                        value={operation.otherAccountUID || ""}
                                   />

                              </Grid>
                              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                                   <TextFieldReadOnly
                                        label="Référence"
                                        value={operation.reference || ""}
                                   />
                                   <TextFieldReadOnly
                                        label="Référence Remote Bank"
                                        value={operation.referenceRemoteBank || ""}
                                   />
                                   <TextFieldReadOnly
                                        label="UID Opération"
                                        value={operation.operationUID || ""}
                                   />
                                   <TextFieldReadOnly
                                        label="UID de l'opération précédente"
                                        value={operation.previousOperationUID || ""}
                                   />
                              </Grid>
                         </Grid>

                    </DialogContent>
               </Dialog>
          </div>
     );
};

export default DetailOperation;
