
import { getCall, baseUrl, putCall } from "./Api";

export const getFirstDepositAmount=()=>{
     return getCall(`${baseUrl}/cbs/settings/FIRST_VERSEMENT`, true);
}
export const getIbAccount=()=>{
     return getCall(`${baseUrl}/cbs/settings/IB_ACCOUNT`, true);
}
export const changeFirstDepositAmount=(value)=>{
     return putCall(`${baseUrl}/cbs/settings/FIRST_VERSEMENT`, value, true);
}
export const changeIbAccount=(value)=>{
     return putCall(`${baseUrl}/cbs/settings/IB_ACCOUNT`, value, true);
}
