import { createSlice } from "@reduxjs/toolkit";

const initialState = {
     apiKeys:{
          list:[],
          total:0
     }
}

export const apiKeySlice = createSlice({
     name: "apiKeys",
     initialState,
     reducers: {
          setApiKeys: (state, action) => {
               state.apiKeys = action.payload;
          }
     }
});

export const { setApiKeys } = apiKeySlice.actions;
export default apiKeySlice.reducer;
