import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchClientInfo } from 'app/redux/store';

//component
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Container } from 'app/components/Content/Container';
import { SimpleCard, Breadcrumb } from "app/components";
import SingleOperation from './SingleOperation';
import Transfer from './Transfer';

const OperationType = () => {
     const dispatch = useDispatch();
     const { clientUID,type } = useParams();
     const [value, setValue] = useState(type!=null && type!==undefined?type==="withdraw"?'2':type=="transfer"?'3':'1':'1');
     const client = useSelector((state) => state.clientInfo.dataClient?.client)

     const handleChange = (event, newValue) => {
          setValue(newValue);
     };
     useEffect(() => {
          dispatchClientInfo(dispatch, clientUID);
     }, []);

     return (
          <Container>
               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={[{ name: 'Clients', path: '/clients' },
                         { name: `${client.lastName || ""} ${client.firstNames || ""}`, path: `/client/${client.clientUID}/detail/1` },
                         { name: 'Opérations' }]}
                    />
               </Box>
               <SimpleCard title="Opération">
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                         <TabContext value={value}>
                              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                   <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="Faire un dépôt" value="1" />
                                        <Tab label="Faire un retrait" value="2" />
                                        <Tab label="Faire un transfert" value="3" />
                                   </TabList>
                              </Box>
                              <TabPanel value="1"><SingleOperation clientUID={clientUID} /> </TabPanel>
                              <TabPanel value="2"><SingleOperation clientUID={clientUID} type="withdraw" /> </TabPanel>
                              <TabPanel value="3"><Transfer clientUID={clientUID} /> </TabPanel>
                         </TabContext>
                    </Box>
               </SimpleCard>
          </Container>
     )
}
export default OperationType;
