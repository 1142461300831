import { baseUrl, getCall } from './Api.js';
import { formatOnlyDate } from './Utils.js';
const moment = require("moment");

const analyticsUrl=`${baseUrl}/cbs/analytics`;


export const getGlobalAnalytics = (year = null) => {
     if (year == null) {
          year = new Date().getFullYear();
     }
     return getCall(analyticsUrl, true, { year: year });
}
export const getOperationAnalytics=(year=null, week=null)=>{
     if (year == null) {
          year = new Date().getFullYear();
     }
     if (week == null) {
          week = moment().week()-1;
     }
     return getCall(`${analyticsUrl}/operations`, true, { year: year, week: week });
}
export const getTransactionAnalytics = (type="jour", startDate=null, endDate=new Date()) => {
     if (startDate == null) {
          startDate = endDate;
          startDate.setMonth(startDate.getMonth() == 1 ? 12 : startDate.getMonth() - 1)
          startDate = formatOnlyDate(startDate);
     }
     type=type.toLowerCase();
     
     return getCall(`${analyticsUrl}/transactions`, true, {type:type, startDate: startDate,endDate:endDate});
}
export const getTransactionTypeAnalytics=(type="jour", startDate=null, endDate=new Date())=>{
     if(startDate==null){
          startDate=endDate;
          startDate.setMonth(startDate.getMonth()==1?12:startDate.getMonth()-1)
          startDate=formatOnlyDate(startDate);
     }
     // console.log(startDate, endDate);
     return getCall(`${analyticsUrl}/transactions-type`, true, { startDate: startDate,endDate:endDate});
}

export const getCurrentWeek=()=>{  
     return moment().year()+"-W"+(moment().week()-1);
}
export const getGlobalAnalyticsClient=(clientUID, year=null)=>{
     if (year == null) {
          year = new Date().getFullYear();
     }
     return getCall(`${analyticsUrl}/client/${clientUID}`, true, { year: year });
}
export const getTransactionAnalyticsClient=(clientUID,type="jour",startDate=null, endDate=new Date())=>{  
     if(startDate==null){
          startDate=endDate;
          startDate.setMonth(startDate.getMonth()==1?12:startDate.getMonth()-1)
          startDate=formatOnlyDate(startDate);
     }
     type=type.toLowerCase();
     return getCall(`${analyticsUrl}/client/${clientUID}/transactions`, true,{type:type, startDate: startDate,endDate:endDate});
}
export const getGlobalAnalyticsGateway=(gatewayUID, year=null)=>{
     if (year == null) {
          year = new Date().getFullYear();
     }
     return getCall(`${analyticsUrl}/api/${gatewayUID}`, true, { year: year });
}

export const getTransactionAnalyticsGateway=(gatewayUID,type, startDate=null, endDate=new Date())=>{
     if(startDate==null){
          startDate=endDate;
          startDate.setMonth(startDate.getMonth()==1?12:startDate.getMonth()-1)
          startDate=formatOnlyDate(startDate);
     }
     type=type.toLowerCase();
     return getCall(`${analyticsUrl}/api/${gatewayUID}/transactions`, true, {type:type, startDate: startDate,endDate:endDate});
}
