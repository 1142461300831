import { Accordion, AccordionDetails, AccordionSummary, Typography, Button, Box, styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledButton = styled(Button)(({ theme }) => ({
     margin: theme.spacing(1),
}));

const Filter = ({ children, handleSearch }) => {
     return (
          <Accordion style={{ "borderRadius": '5px', "boxShadow": "none", "&:before": { display: "none" } }}>
               <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Filtre</Typography>
               </AccordionSummary>
               <AccordionDetails >

                    <Box
                         component="form"
                         sx={{
                              '& .MuiTextField-root': { m: 1, width: '25ch' },
                         }}
                         noValidate
                         autoComplete="off"
                    >
                         <div>
                              {children}
                         </div>
                         <StyledButton variant="contained" color="primary" onClick={handleSearch} >
                              Rechercher
                         </StyledButton>
                    </Box>

               </AccordionDetails>
          </Accordion >
     );
}
export default Filter;
