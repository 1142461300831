import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

//component
import { PaginationTable } from "app/components/table/PaginationTable";
import { TextField, MenuItem, Box, Button, useTheme } from "@mui/material";
import Filter from "app/components/Content/Filter";
import DetailOperation from "./DetailOperation";
import ClientNav from "../clients/ClientNav";
import Small from "app/components/Content/Small";

import { getOperationsForClient, getOperationTypes } from "app/services/OperationService";
import { formatDate, formattedNumber } from "app/services/Utils";
import { getClientInfoByUID } from "app/services/ClientService";

const ClientOperations = () => {
     const { palette } = useTheme();
     const bgPrimary = palette.primary.main;
     const bgSecondary = palette.secondary.main;

     const navigate = useNavigate();
     const { clientUID } = useParams();

     const [searchParam, setSearchParam] = useState({});

     const [client, setClient] = useState({});
     const [accounts, setAccounts] = useState([]);
     const [operations, setOperations] = useState([]);
     const [totalOperations, setTotalOperations] = useState(0);
     const [operationTypes, setOperationTypes] = useState([]);
     const [loading, setLoading] = useState(false);
     const [page, setPage] = useState(0);


     const columns = [
          {
               name: 'Référence',
               selector: (row) => (<DetailOperation operation={row} />),
               style: { width: '200px' }
          },
          {
               name: 'Description',
               selector: (row) => row.label,
               style: { width: '200px' }
          },
          {
               name: 'Montant',
               selector: (row) => {
                    const amount=row.clientUID===clientUID?formattedNumber(row.amount):formattedNumber(row.amount*-1);
                    
                    return row.amount < 0 && row.clientUID===clientUID ? (
                         <Small bgcolor={bgSecondary}>{amount} ar</Small>
                    ) : (
                         <Small bgcolor={bgPrimary}>{amount} ar</Small>
                    )
               },
               style: { width: '200px' }
          },
          {
               name: 'Compte',
               selector: (row) => `${row.clientName} (${row.accountUID})`,
               style: { width: '200px' }

          },
          {
               name: 'Date',
               selector: (row) =>formatDate(new Date(row.dateTime)) ,
               style: { width: '200px' }
          },
          {
               name: 'Compte Transfert',
               selector: (row) =>
                    (<>{row.otherClientName} <div>{row.otherAccountUID ? `(${row.otherAccountUID})` : ""}</div></>),
               style: { width: '200px' }
          }
     ];
     const getOperation = async (currentPage = null) => {
          setLoading(true)
          // console.log(searchParam);
          getOperationsForClient(clientUID, currentPage == null ? page : currentPage, searchParam).then((res) => {
               setOperations(res.data.data.list);
               setTotalOperations(res.data.data.total);
               setLoading(false);
          }).catch((ex) => {
               setLoading(false);
               Promise.reject(ex);
          });
     }
     const getClient = () => {
          getClientInfoByUID(clientUID).then((res) => {
               setAccounts(res.data.data.Accounts || []);
               setClient(res.data.data.client);
          });
     }
     const getOperationsType = async () => {
          getOperationTypes().then((res) => {
               setOperationTypes(res.data.data);
          });
     }

     const handleChangePage = (_, newPage) => {
          newPage === 1 ? setPage(page + 1) : setPage(page - 1);
          getOperation(newPage === 1 ? (page + 1) : (page - 1));
     };
     const handleSearch = () => {
          getOperation(0);
     }
     const handleSearchChange = (event, param) => {
          setSearchParam({ ...searchParam, [param]: event.target.value })
     }
     useEffect(() => {
          try {
               getOperation();
               getOperationsType();
               getClient();
          } catch (e) {
               console.error(e);
          }
     }, []);

     return (
          <ClientNav client={client} currentValue="3" clientUID={clientUID} section={{ name: "Opérations" }}>
               <Box
                    sx={{
                         display: 'flex',
                         flexDirection: 'row-reverse',
                         borderRadius: 1,
                    }}
               >
                    <Button variant="contained" color="primary" onClick={() => navigate(`/client/${clientUID}/operation`)}>Faire une opération</Button>
               </Box>
               <br />
               <Filter handleSearch={handleSearch}>
                    <TextField
                         id="outlined-basic" label="Référence" variant="outlined" value={searchParam.reference || ""}
                         onChange={(event) => handleSearchChange(event, "reference")} />
                    <TextField
                         id="outlined-basic" label="Date début" variant="outlined" value={searchParam.startDate || ""}
                         onChange={(event) => handleSearchChange(event, "startDate")} type="date" InputLabelProps={{ shrink: true }} />
                    <TextField
                         id="outlined-basic" label="Date fin" variant="outlined" value={searchParam.endDate || ""}
                         onChange={(event) => handleSearchChange(event, "endDate")} type="date" InputLabelProps={{ shrink: true }} />
                    <TextField
                         id="outlined-select-currency"
                         select
                         label="Compte"
                         onChange={(event) => handleSearchChange(event, "accountUID")}
                         value={searchParam.accountUID || ""}
                    >
                         <MenuItem key='1account' value={""}>
                              Tous
                         </MenuItem>
                         {accounts.map((option) => (
                              <MenuItem key={option.accountUID} value={option.accountUID}>
                                   {option.accountUID}
                              </MenuItem>
                         ))}

                    </TextField>
                    <TextField
                         id="outlined-select-currency"
                         select
                         label="Opération type"
                         onChange={(event) => handleSearchChange(event, "idOperationType")}
                         value={searchParam.idOperationType || ""}
                    >
                         <MenuItem key='1op' value={""}>
                              Tous
                         </MenuItem>
                         {operationTypes.map((option) => (
                              <MenuItem key={option.code} value={option.idOperationType}>
                                   {option.name}
                              </MenuItem>
                         ))}
                    </TextField>
                   
               </Filter>
               <br />
               <PaginationTable data={operations} columns={columns} handleChangePage={handleChangePage} page={page} loading={loading} count={totalOperations}></PaginationTable>

          </ClientNav>
     )
}
export default ClientOperations;
