import { useState } from 'react'
import { useTheme, Grid } from '@mui/material';
import { SimpleCard } from "app/components";
import BarEchart from "app/components/dashboard/BarEchart";
import FilterChart from './FilterChart';

import { getTransactionAnalytics} from 'app/services/AnalyticsService';
const TransactionTypeAnalytics = () => {
     const theme = useTheme();
     const [typeTitle, setTypeTitle] = useState("");
     const [transactions, setTransactions] = useState([]);
     
     const getData=(type, startDate, endDate)=>{
          getTransactionAnalytics(type, startDate, endDate).then((res) => {
               const barData = res.data.data;
               setTransactions(barData ? barData.map(item => (
                    [item.date, item.amount]
               )) : []);
          });
     }

     return (
          <SimpleCard title={"Volume des transactions, "+typeTitle}>
               <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12} >
                    <br />
                         <FilterChart setTypeTitle={setTypeTitle} getData={getData} inline={true} />
                    </Grid>
               </Grid>
               <BarEchart title="Transaction de la semaine dernière" height="600px" color={[theme.palette.primary.dark, theme.palette.primary.light]}
                    legend={["semaine", "Volume transaction"]} data={transactions} />
          </SimpleCard>
     )
}
export default TransactionTypeAnalytics;
