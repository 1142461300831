import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//component
import { Icon, IconButton, TextField, Box } from "@mui/material";
import { Container } from 'app/components/Content/Container';
import { SimpleCard, Breadcrumb } from "app/components";
import { PaginationTable } from "app/components/table/PaginationTable";
import Filter from "app/components/Content/Filter";

import { findAllUser } from "app/services/UserService";

const Users = () => {
     const navigate = useNavigate();
     const [searchParam, setSearchParam] = useState({
          name: "",
          email: "",
          telephone: "",
     });

     const [users, setUsers] = useState([]);
     const [totalUser, setTotalUser] = useState(0);
     const [loading, setLoading] = useState(false);

     const columns = [
          {
               name: 'Identifiant',
               selector: (row) => row.username,
          },
          {
               name: 'Statut',
               selector: (row) => (<IconButton>
                    <Icon color={row.isEnabled ? "success" : "error"} >{row.isEnabled ? "check" : "clear"}</Icon>
               </IconButton>)
          },
          {
               name: 'Nom complet',
               selector: (row) => row.name,
          },
          {
               name: 'Téléphone',
               selector: (row) => row.telephone,
          },
          {
               name: 'Email',
               selector: (row) => row.email,
          },
          {
               name: 'Privilèges',
               selector: (row) => {
                    const privileges = row.roles.map((privilege) => privilege.code)
                    return privileges.join(", ");
               },
          },
          {
               name: 'Action',
               selector: (row) => (<IconButton onClick={() => navigate(`/user/${row.iduser}`)}>
                    <Icon color="primary">edit</Icon>
               </IconButton>),
               align: "right",
          },

     ];
     const getAllUsers = (currentPage=null) => {
          setLoading(true);
          findAllUser(searchParam, currentPage==null?page:currentPage).then((response) => {
               setUsers(response.data.data.list);
               setTotalUser(response.data.data.total);
               setLoading(false);
          }).catch((res)=>{
               setLoading(false);
               Promise.reject(res);
          });
     }
     const handleSearch = () => {
          getAllUsers(0);
     }
     const handleSearchChange = (event, param) => {
          setSearchParam({ ...searchParam, [param]: event.target.value })
          getAllUsers();
     }
     const [page, setPage] = useState(0);
     const handleChangePage = (_, newPage) => {
          newPage === 1 ? setPage(page + 1) : setPage(page - 1);
          getAllUsers(newPage === 1 ? (page + 1) : (page - 1));
     };
     useEffect(() => {
          try {
               getAllUsers();
          } catch (error) {
               console.error(error);
          }
     }, [page]);

     return (
          <Container>
               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={[{ name: 'Utilisateurs', path: '/users' }]}
                    />
               </Box>
               <Filter handleSearch={handleSearch}>
                    <TextField id="outlined-basic" label="Nom" variant="outlined" value={searchParam.name || ""} onChange={(event) => handleSearchChange(event, "search")} />
                    <TextField id="outlined-basic" label="Téléphone" variant="outlined" value={searchParam.telephone || ""} onChange={(event) => handleSearchChange(event, "telephone")} />
                    <TextField id="outlined-basic" label="Email" variant="outlined" value={searchParam.email || ""} onChange={(event) => handleSearchChange(event, "email")} />
               </Filter>
               <br />
               <SimpleCard title="Utilisateurs">
                    <PaginationTable data={users} columns={columns} handleChangePage={handleChangePage} page={page} loading={loading} count={totalUser}></PaginationTable>
               </SimpleCard>
          </Container >
     );
};

export default Users;
