import React from 'react';
import { Card, styled, useTheme, Grid, TextField, MenuItem } from '@mui/material';
import ReactEcharts from 'echarts-for-react';


//format data: [{ value: 65, name: 'Google' },]
const DoughnutChart = ({ height, data, pieName, color = [], bottom = 0, center = ["50%", "50%"] }) => {
     const theme = useTheme();
     // console.log(data);
     const option = {
          legend: {
               show: true,
               itemGap: 20,
               icon: 'circle',
               bottom: bottom,
               textStyle: {
                    color: theme.palette.text.secondary,
                    fontSize: 13,
                    fontFamily: 'roboto'
               }
          },
          tooltip: {
               show: false,
               trigger: 'item',
               formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          xAxis: [
               {
                    axisLine: {
                         show: false
                    },
                    splitLine: {
                         show: false
                    }
               }
          ],
          yAxis: [
               {
                    axisLine: {
                         show: false
                    },
                    splitLine: {
                         show: false
                    }
               }
          ],

          series: [
               {
                    name: { pieName },
                    type: 'pie',
                    radius: ['45%', '72.55%'],
                    center: center,
                    avoidLabelOverlap: false,
                    hoverOffset: 5,
                    stillShowZeroSum: false,
                    label: {
                         normal: {
                              show: false,
                              position: 'center', // shows the description data to center, turn off to show in right side
                              textStyle: {
                                   color: theme.palette.text.secondary,
                                   fontSize: 13,
                                   fontFamily: 'roboto'
                              },
                              formatter: '{a}'
                         },
                         emphasis: {
                              show: true,
                              textStyle: {
                                   fontSize: '14',
                                   fontWeight: 'normal'
                                   // color: "rgba(15, 21, 77, 1)"
                              },
                              formatter: '{b} \n{c} ({d}%)'
                         }
                    },
                    labelLine: {
                         normal: {
                              show: false
                         }
                    },
                    data: data,
                    itemStyle: {
                         emphasis: {
                              shadowBlur: 10,
                              shadowOffsetX: 0,
                              shadowColor: 'rgba(0, 0, 0, 0.5)'
                         }
                    }
               }
          ]
     };

     return (

          <ReactEcharts
               style={{ height: height }}
               option={{
                    ...option,
                    color: [...color]
               }}
          />

     );
};

export default DoughnutChart;
