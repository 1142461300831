import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'app/hooks/useAuth';

import { Box, styled, Button } from '@mui/material';
import { MatxLogo } from 'app/components';
import CBSLogo from 'app/components/CBSLogo';
import useSettings from 'app/hooks/useSettings';
import { Span } from './Typography';

const BrandRoot = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '20px 18px 20px 29px',
}));

const StyledSpan = styled(Span)( ({ mode }) => ({
  fontSize: 18,
  marginLeft: '.5rem',
  display: mode === 'compact' ? 'none' : 'block',
}));

const Brand = ({ color = "white", clickable = true, children }) => {
  const navigate = useNavigate();
  const { settings } = useSettings();
  const leftSidebar = settings.layout1Settings.leftSidebar;
  const { mode } = leftSidebar;
  const { user } = useAuth();
  const [homeUrl, setHomeUrl] = useState('/clients');

  useEffect(() => {
    let home = '/dashboard';
    //console.log(user.roles);
    if (user.roles.includes("ADMIN") || user.roles.includes("BASIC_USER") || user.roles.includes("ANALYTICS")) {
      home = '/dashboard';
    } else if (user.roles.includes("CLIENTS")) {
      home = '/clients';
    } else if (user.roles.includes("MANAGE_USER")) {
      home = '/users';
    } else if (user.roles.includes("APIKEY")) {
      home = '/apikeys';
    } else if (user.roles.includes("OPERATIONS")) {
      home = '/operations';
    }
    setHomeUrl(home);
  }, []);
  const onHancleClick = () => {
    if (clickable) {
      navigate(homeUrl);
    }
  };
  return (
    <BrandRoot>
      <Button display="flex" align="center" onClick={() => onHancleClick()} sx={{ color: color }}>
        {/* <MatxLogo /> */}
        <CBSLogo />
        <StyledSpan mode={mode} className="sidenavHoverShow">
          CBS23_I
        </StyledSpan>
      </Button>

      <Box className="sidenavHoverShow" sx={{ display: mode === 'compact' ? 'none' : 'block' }}>
        {children || null}
      </Box>
    </BrandRoot>
  );
};

export default Brand;
