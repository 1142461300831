import { Accordion, AccordionDetails, AccordionSummary, Typography, Button, Box, styled, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Container } from 'app/components/Content/Container';
import { SimpleCard, Breadcrumb } from "app/components";
import SettingIbAccount from "./SettingIbAccount";
import SettingMinAmount from "./SettingMinAmount";

const Settings = () => {
     return (
          <Container>
               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={[{ name: 'Règle de gestion', path: '/rules' }]}
                    />
               </Box>
               <Grid container spacing={6} alignItems="stretch">
                    <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mt: 2 }}>
                         <Box
                              sx={{
                                   '& .MuiTextField-root': { m: 1 },
                              }}
                              noValidate
                              autoComplete="off"
                              backgroundColor="white"
                              height="100%"
                         >
                              <Accordion style={{ "borderRadius": '5px', "boxShadow": "none", "&:before": { display: "none" } }} defaultExpanded={true}>
                                   <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Compte interne</Typography>
                                   </AccordionSummary>
                                   <AccordionDetails >

                                        <SettingIbAccount />

                                   </AccordionDetails>
                              </Accordion>
                         </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mt: 2 }}>
                         <Box
                              sx={{
                                   '& .MuiTextField-root': { m: 1 },
                              }}
                              noValidate
                              autoComplete="off"
                              backgroundColor="white"
                              height="100%"
                         >
                              <Accordion style={{ "borderRadius": '5px', "boxShadow": "none", "&:before": { display: "none" } }} defaultExpanded={true}>
                                   <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Montant minimum (pour le retrait)</Typography>
                                   </AccordionSummary>
                                   <AccordionDetails >

                                        <SettingMinAmount />
                                   </AccordionDetails>
                              </Accordion>
                         </Box>

                    </Grid>
               </Grid>
          </Container >
     );
}
export default Settings;
