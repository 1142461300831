import axios from 'axios';
import { createContext } from 'react';
export const api = axios.create();

api.interceptors.response.use(
     response => {
          return response;
     },
     error => {
          // console.log("interceptor");
          // console.log("error", error)
          if (error.response && error.response.status === 401) {
               window.location.assign('/login/error')
          } else if (error.response && error.response.status === 403) {
               //history.push('/login');
               //vers une page qui dit action refusée
               //window.location.assign('/unauthorized')
               alert("Action refusée, vous n'avez pas les privilèges pour "+error.response.config.url);
          }else if(error.code==="ERR_NETWORK"){
               alert(error.code, " : ",error.message)
          }else if(error.response.status===404){
               alert("Erreur 404, veuillez contacter l'administrateur")
          }
          return Promise.reject(error);
     }
);

const AxiosContext = createContext(api);

export default AxiosContext;

export const ApiProvider = ({ children }) => {
     return (
          < AxiosContext.Provider value={api} >
               {children}
          </AxiosContext.Provider >
     )
}
