import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

//component
import { Grid, Button, Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import ErrorDisplay from "app/components/Content/ErrorDisplay";
import { dispatchApiKeys } from 'app/redux/store';
import { StyledTextField } from "app/components/Content/CustomInput";
import { LoadButton } from "app/components/Content/CustomLoadButton";
import SuccessDisplay from "app/components/Content/SuccessDisplay";
import MGPhoneInput from "app/components/Content/MGPhoneInput";


import { addNewApplication } from "app/services/ApiKeyService";

const validationSchema = Yup.object().shape({
     appName: Yup.string().required('Le nom est obligatoire'),
     appDescription: Yup.string(),
     reference: Yup.string().required('La référence est obligatoire!').min(3, 'La référence doit avoir au moins 3 caractères'),
     managerName: Yup.string().required('Le responsable est obligatoire!').min(3, 'Le responsable doit avoir au moins 3 caractères'),
     managerEmail: Yup.string().email('L\'email est invalide'),
     managerPhone: Yup.string(),
});

const FormApplication = ({ searchParam, page }) => {
     const dispatch = useDispatch();
     const [loading, setLoading] = useState(false);
     const [messageError, setMessageError] = useState('');
     const [error, setError] = useState(false);
     const [success, setSuccess] = useState(false);

     const [open, setOpen] = useState(false);
     const handleOpen = () => setOpen(true);
     const handleClose = () => setOpen(false);

     const initialValues = {
          appName: '',
          appDescription: '',
          reference: '',
          managerName: '',
          managerEmail: '',
          managerPhone: '',
     }

     const handleFormSubmit = async (values) => {
          setLoading(true);
          try {
               await addNewApplication(values).then((res) => {
                    handleClose();
                    setLoading(false);
                    setError(false);
                    dispatchApiKeys(dispatch, searchParam, page);
               })
          } catch (e) {
               setMessageError(e.response.data.message)
               setLoading(false);
               setError(true);
          }
     }

     return (
          <div>
               <SuccessDisplay open={success} setOpen={setSuccess} message="Application enregistrée" />
               <Button variant="contained" color="primary" onClick={handleOpen}>Nouvelle application</Button>
               <Dialog onClose={handleClose} aria-labelledby="form-dialog-title" open={open}>
                    <DialogTitle id="form-dialog-title" onClose={handleClose} component="div">
                         Ajouter une nouvelle application
                    </DialogTitle>
                    <DialogContent dividers>
                         <Formik
                              onSubmit={handleFormSubmit}
                              validationSchema={validationSchema}
                              initialValues={initialValues}
                              method="POST"
                         >
                              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                   <form onSubmit={handleSubmit}>
                                        <Box>
                                             <Grid container spacing={6}>
                                                  <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                                       <StyledTextField
                                                            size="small"
                                                            type="text"
                                                            name="appName"
                                                            label="Nom de l'application"
                                                            variant="outlined"
                                                            onBlur={handleBlur}
                                                            value={values.appName}
                                                            onChange={handleChange}
                                                            helperText={touched.appName && errors.appName}
                                                            error={Boolean(errors.appName && touched.appName)}
                                                       />
                                                       <StyledTextField
                                                            size="small"
                                                            type="text"
                                                            multiline={true}
                                                            rows={3}
                                                            name="appDescription"
                                                            label="Description"
                                                            variant="outlined"
                                                            onBlur={handleBlur}
                                                            value={values.appDescription}
                                                            onChange={handleChange}
                                                            helperText={touched.appDescription && errors.appDescription}
                                                            error={Boolean(errors.appDescription && touched.appDescription)}
                                                       />
                                                       <StyledTextField
                                                            size="small"
                                                            type="text"
                                                            name="reference"
                                                            label="Référence"
                                                            variant="outlined"
                                                            onBlur={handleBlur}
                                                            value={values.reference}
                                                            onChange={handleChange}
                                                            helperText={touched.reference && errors.reference}
                                                            error={Boolean(errors.reference && touched.reference)}
                                                       />
                                                  </Grid>
                                                  <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                                       <StyledTextField
                                                            size="small"
                                                            type="text"
                                                            name="managerName"
                                                            label="Nom du responsable"
                                                            variant="outlined"
                                                            onBlur={handleBlur}
                                                            value={values.managerName}
                                                            onChange={handleChange}
                                                            helperText={touched.managerName && errors.managerName}
                                                            error={Boolean(errors.managerName && touched.managerName)}
                                                       />
                                                       
                                                       <MGPhoneInput name="managerTelephone" onChange={(event) => {
                                                            setFieldValue("managerTelephone", event);
                                                       }}
                                                            value={values.telephone || ""}
                                                            onBlur={handleBlur}
                                                            helperText={touched.telephone && errors.telephone}
                                                            error={Boolean(errors.telephone && touched.telephone)}
                                                            withValidation={true}
                                                            touched={touched}
                                                       />
                                                       <StyledTextField
                                                            size="small"
                                                            type="text"
                                                            name="managerEmail"
                                                            label="Email du responsable"
                                                            variant="outlined"
                                                            onBlur={handleBlur}
                                                            value={values.managerEmail}
                                                            onChange={handleChange}
                                                            helperText={touched.managerEmail && errors.managerEmail}
                                                            error={Boolean(errors.managerEmail && touched.managerEmail)}
                                                       />
                                                  </Grid>
                                             </Grid>
                                        </Box>
                                        <LoadButton loading={loading} >Ajouter</LoadButton>

                                        <ErrorDisplay error={error} messageError={messageError} />
                                   </form>
                              )}
                         </Formik>
                    </DialogContent>
               </Dialog>
          </div>
     );
}
export default FormApplication;
