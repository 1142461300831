import { getCall, postCall, baseUrl, putCall } from './Api';

export const findAllApiKeyByApplication = async (idApp, searchParam = "", page = null) => {
     const param = page != null ? {
          keyword: searchParam ? searchParam : null,
          managerName: searchParam ? searchParam : null,
          offset: (page),
          limit: 10
     } : null;
     return getCall(`${baseUrl}/cbs/api_keys`, true, param);
}
export const findAllApplication = async (searchParam="", page = null) => {
     const param = page != null ? {
          keyword: searchParam ? searchParam : null,
          managerName: searchParam ? searchParam : null,
          offset: (page),
          limit: 10
     } : null;
     return getCall(`${baseUrl}/cbs/applications`, true, param);
}
export const findApplicationByReference = (reference) => {
     return getCall(`${baseUrl}/cbs/application/${reference}`, true);
}


export const addNewApiKey = async (reference, date) => {
     return postCall(`${baseUrl}/cbs/application/${reference}/api_key`, null, true, {expireDate:date});
}
export const addNewApplication = async (data) => {
     return postCall(`${baseUrl}/cbs/application`, data, true);
}
export const expireApiKey = async (apiKeyUID) => {
     return putCall(`${baseUrl}/cbs/api_key/${apiKeyUID}`, null, true);
}
export const updateApplication = async (applicationUID, data) => {
     return putCall(`${baseUrl}/cbs/application/${applicationUID}`, data, true);
}
export const sendApikeyEmail = (reference) => {
     return postCall(`${baseUrl}/cbs/api_key/${reference}/sendemail`, null, true);
}
export const generateKey = (reference) => {
     return getCall(`${baseUrl}/cbs/api_key/generate_key`, true, { reference: reference });
}
export const updateExpireDateApiKey=(id, expireDate)=>{
     return putCall(`${baseUrl}/cbs/api_key/${id}`,null,  true,{expireDate:expireDate})
}

export const sendReport = (reference) => {
     return postCall(`${baseUrl}/cbs/report/${reference}/send`, null, true);
}
export const sendAllReport = () => {
     return postCall(`${baseUrl}/cbs/reports/send`, null, true)
}
export const getReport = (reference) => {
     return getCall(`${baseUrl}/cbs/report/${reference}`, true);
}
