import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { dispatchApiKeys } from "app/redux/store";

//component
import { Grid, Button, Icon, IconButton, Dialog, Switch, FormControlLabel, DialogTitle, DialogContent } from "@mui/material";
import ErrorDisplay from "app/components/Content/ErrorDisplay";
import { LoadButton } from "app/components/Content/CustomLoadButton";
import { TextFieldReadOnlyDisabled, StyledTextField } from "app/components/Content/CustomInput";
import SuccessDisplay from "app/components/Content/SuccessDisplay";

import { updateApplication, expireApiKey, sendApikeyEmail } from "app/services/ApiKeyService";
import MGPhoneInput from "app/components/Content/MGPhoneInput";

const validationSchema = Yup.object().shape({
     appName: Yup.string().required('Nom requis'),
     appDescription: Yup.string(),
     managerName: Yup.string().required('Responsable requis'),
     managerEmail: Yup.string().email('Email invalide'),
     managerTelephone: Yup.string()
});

const DetailApplication = ({ app }) => {
     const dispatch = useDispatch();
     const [loading, setLoading] = useState(false);
     const [messageError, setMessageError] = useState('');
     const [error, setError] = useState(false);
     const [success, setSuccess] = useState(false);

     const [loadingEmail, setLoadingEmail] = useState(false);

     const [successEmail, setSuccessEmail] = useState(false);

     const [open, setOpen] = useState(false);
     const handleOpen = () => setOpen(true);
     const handleClose = () => setOpen(false);

     const initialValues = {
          appName: app.appName,
          appDescription: app.appDescription,
          managerName: app.managerName,
          managerEmail: app.managerEmail,
          managerTelephone: app.managerTelephone,
          isEnabled: app.apiKey?.isEnabled || false,
     }
     const update = () => {
          handleClose();
          setLoading(false);
          setError(false);
          setSuccess(true);
          // dispatchApiKeys(dispatch, searchParam, page);
     }
     const updateFailed = (message) => {
          setMessageError(message)
          setLoading(false);
          setError(true);
     }
     const handleFormSubmit = async (values) => {
          setLoading(true);
          setSuccess(false);
          try {
               await updateApplication(app.reference, values).then((res) => {
                    update();
               })
          } catch (e) {
               updateFailed(e.response.data.message);
          }
     }
     return (<Formik
          onSubmit={(values) => {
               handleFormSubmit(values);
          }}
          validationSchema={validationSchema}
          initialValues={initialValues}
          method="POST"
     >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
               <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                         <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                              <StyledTextField
                                   size="small"
                                   type="text"
                                   name="appName"
                                   label="Nom"
                                   variant="outlined"
                                   onBlur={handleBlur}
                                   value={values.appName || ""}
                                   onChange={handleChange}
                                   helperText={touched.appName && errors.appName}
                                   error={Boolean(errors.appName && touched.appName)}
                              />
                              <TextFieldReadOnlyDisabled label="Reférence"
                                   value={app.reference || ""}
                              />
                              <StyledTextField
                                   size="small"
                                   type="text"
                                   name="appDescription"
                                   label="Description"
                                   variant="outlined"
                                   multiline={true}
                                   rows={3}
                                   onBlur={handleBlur}
                                   value={values.appDescription || ""}
                                   onChange={handleChange}
                                   helperText={touched.appDescription && errors.appDescription}
                                   error={Boolean(errors.appDescription && touched.appDescription)}
                              />
                              <StyledTextField
                                   size="small"
                                   type="text"
                                   name="managerName"
                                   label="Responsable"
                                   variant="outlined"
                                   onBlur={handleBlur}
                                   value={values.managerName || ""}
                                   onChange={handleChange}
                                   helperText={touched.managerName && errors.managerName}
                                   error={Boolean(errors.managerName && touched.managerName)}
                              />
                              <StyledTextField
                                   size="small"
                                   type="text"
                                   name="managerEmail"
                                   label="Email Responsable"
                                   variant="outlined"
                                   onBlur={handleBlur}
                                   value={values.managerEmail || ""}
                                   onChange={handleChange}
                                   helperText={touched.managerEmail && errors.managerEmail}
                                   error={Boolean(errors.managerEmail && touched.managerEmail)}
                              />
                              <MGPhoneInput name="managerTelephone" onChange={(event) => {
                                   setFieldValue("managerTelephone", event);
                              }}
                                   value={values.managerTelephone || ""}
                                   onBlur={handleBlur}
                                   helperText={touched.managerTelephone && errors.managerTelephone}
                                   error={Boolean(errors.managerTelephone && touched.managerTelephone)}
                                   withValidation={false}
                              />
                         </Grid>
                         <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                              {app.apiKey != null ? <FormControlLabel
                                   label="Actif"
                                   control={
                                        <Switch checked={values.isEnabled} onChange={(event) => {
                                             setFieldValue("isEnabled", event.target.checked);
                                        }}
                                             name="isEnabled"
                                             value={"isEnabled"} inputProps={{ "aria-label": "secondary checkbox" }} color="success" />
                                   }
                                   helpertext={touched.isEnabled && errors.isEnabled}
                                   error={Boolean(errors.isEnabled && touched.isEnabled).toString()}
                              /> : ""}
                              <TextFieldReadOnlyDisabled label="Clé API"
                                   value={app.apiKey?.key || ""}
                                   multiline={true}
                                   rows={2}
                              />

                              <TextFieldReadOnlyDisabled label="Date de création"
                                   type="date-time"
                                   value={app.apiKey?.createdAt || ""}
                              />
                              {/* <TextFieldReadOnlyDisabled label="Date d'expiration"
                              type="date-time"
                              value={app.apiKey?.expiredAt || ""}
                         /> */}
                         </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                         <Grid item lg={6} md={6} sm={6} xs={6} >
                              <LoadButton loading={loading} >Enregistrer</LoadButton>
                         </Grid>
                         <Grid item lg={6} md={6} sm={6} xs={6} >
                              {app.apiKey ?
                                   null
                                   : ""}

                         </Grid>
                    </Grid>
                    <ErrorDisplay error={error} messageError={messageError} />
               </form>
          )}
     </Formik>)
}
export default DetailApplication;
