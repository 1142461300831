import { Button } from '@mui/material';
import html2pdf from 'html2pdf.js'
const PdfGenerator = ({ filename="pdf",children, searchId="pdf-content", ...props}) => {
     const generatePDF = () => {
          const content = document.getElementById(searchId);
          // console.log(content);
          const pdfOptions = {
               margin: 10,
               filename: filename + '.pdf',
               image: { type: 'jpeg', quality: 0.98 },
               html2canvas: { scale: 2 },
               jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
          };

          html2pdf()
               .from(content)
               .set(pdfOptions)
               .save();
     }
     return(
          <Button variant="contained"  onClick={generatePDF} {...props} >{children}</Button>
     )

}
export default PdfGenerator;
