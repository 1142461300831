import React, { useState, Fragment } from "react";
import { Grid, MenuItem, Autocomplete, CircularProgress, Button, DialogTitle, DialogContent, Dialog } from "@mui/material";
import { StyledTextField } from "app/components/Content/CustomInput";
import { getAccountsByClientUID } from "app/services/AccountService";
import { elasticSearch } from "app/services/ClientService";


const AutocompleteAccount = ({ name, onChange, value, handleChange, handleBlur, touched, errors, autoLabel="Client à qui transferer" }) => {
     const [loadingElasticSearch, setLoadingElasticSearch] = useState(false); //loading for elastic search [client
     const [open, setOpen] = useState(false);
     const [optionRecipient, setOptionRecipient] = useState([]);
     const [inputValue, setInputValue] = useState("")
     const [accounts, setAccounts] = useState([]);


     const handleOptionChange = async (event, newValue) => {
          //prendre les comptes
          try {
               await getAccountsByClientUID(newValue.clientUID).then((res) => {
                    setAccounts(res.data.data);
               });
          } catch (error) {
               console.error('Error fetching options: ', error);
          }
     };
     const handleInputChange = async (event) => {
          if (event.target.value.length > 2 && event.target.value !== inputValue) {
               setInputValue(event.target.value);
               try {
                    setOpen(false)
                    setLoadingElasticSearch(true);
                    await elasticSearch(event.target.value).then((res) => {
                         setOptionRecipient(res.data.data);
                         setOpen(true);
                         setLoadingElasticSearch(false);
                    });
               } catch (error) {
                    setLoadingElasticSearch(false);
                    console.error('Error fetching options: ', error);
               }
          }else{
               setOpen(true);
               setOptionRecipient([]);
          }
     }
     return (
          <div>
               <Autocomplete
                    open={open}
                    options={optionRecipient}
                    id="asynchronous-demo"
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    getOptionLabel={(option) => option.firstNames + " " + option.lastName + " (" + option.email + ")"}
                    filterOptions={(optionRecipient) => optionRecipient}
                    onChange={handleOptionChange}
                    renderInput={(params) => (
                         <StyledTextField
                              {...params}
                              fullWidth
                              variant="outlined"
                              label={autoLabel}
                              onChange={handleInputChange}
                              multiline={true}
                              rows={2}
                              InputProps={{
                                   ...params.InputProps,
                                   endAdornment: (
                                        <Fragment>
                                             {loadingElasticSearch ? <CircularProgress color="inherit" size={20} /> : null}
                                             {params.InputProps.endAdornment}
                                        </Fragment>
                                   )
                              }}
                         />
                    )}
                    renderOption={(props, option) => (
                         <li {...props}>
                              {option.firstNames} {option.lastName} ({option.email})
                         </li>
                    )}
               />
               <StyledTextField
                    select
                    size="small"
                    name={name}
                    label="Compte"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={value || ""}
                    onChange={handleChange}
                    helperText={touched[name] && errors[name]}
                    error={Boolean(errors[name] && touched[name])}
                    disabled={accounts.length === 0}
               >
                    {accounts.map((acc, index) => {
                         return <MenuItem key={index} value={acc.accountUID}>
                              {acc.accountUID} ({acc.accountType?.acceptsDeposit ? "Accepte les dépôts" : "N'accepte pas les dépôts"})
                         </MenuItem>
                    })}
               </StyledTextField>
          </div>
     )
}
export default AutocompleteAccount;
