import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'app/components/Content/Container';
import { Box, TextField, MenuItem, Button, Typography, styled } from "@mui/material";
import { SimpleCard, Breadcrumb } from "app/components";
import { StyledTextField } from 'app/components/Content/CustomInput';
import Brand from 'app/components/Brand';
import SimpleTable from 'app/components/table/SimpleTable';
import { Span } from 'app/components/Typography';
import PdfGenerator from '../pdf/PdfGenerator';

import { formatOnlyDate, formatDate, formattedNumber } from 'app/services/Utils';
import { getAccountStatement } from 'app/services/AccountService';

const StyledClientInfo = styled(Box)(({ client }) => ({
     padding: '20px 18px 20px 29px',
}));

const StyledSpan = styled(Typography)(({ fontWeight = 'bold' }) => ({
     fontWeight: fontWeight,
     display: 'inline'
}));

const AccountStatement = ({ }) => {
     const { accountUID } = useParams();
     let date = new Date();
     date.setMonth(date.getMonth() != 1 ? date.getMonth() - 1 : 12);
     const [startDate, setStartDate] = useState(formatOnlyDate(date));
     const [endDate, setEndDate] = useState(formatOnlyDate(new Date()));

     const [account, setAccount] = useState({});
     const [operations, setOperations] = useState([]);
     const [total, setTotal] = useState(0);
     const [loading, setLoading] = useState(false);

     const getData = () => {
          setLoading(true);
          getAccountStatement(accountUID, startDate, endDate).then((res) => {
               setAccount(res.data.data.account);
               setOperations(res.data.data.operations);
               setTotal(res.data.data.totalOperation);
               setLoading(false);
          }).catch((err) => {
               console.error(err);
               setLoading(false);
          });
     }
     useEffect(() => {
          getData();
     }, [])
     const columns = [
          {
               name: 'Date',
               selector: (row) => formatDate(new Date(row.dateTime)),
               //style: { width: '120px' }
          },
          {
               name: 'Opération',
               selector: (row) => row.label + (row.accountUID !== accountUID ? ` (venant de ${row.otherAccountUID})` : (row.otherAccountUID != null ? ` (vers ${row.otherAccountUID})` : '')),
          },
          {
               name: 'Montant',
               selector: (row) => (<>{formattedNumber(row.accountUID !== accountUID ? -row.amount : row.amount)} Ar</>),
          },
          {
               name: 'Référence',
               selector: (row) => row.reference,
               // style: { width: '200px' }
          },

     ];
     return (
          <Container>
               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={[{name:account.clientName, path:'/client/'+account.clientUID+"/detail/2"},{ name: 'Relevé de compte', path: '/accounts' }, { name: accountUID }]}
                    />
               </Box>
               <br />
               <SimpleCard title={`Relevé de compte ${accountUID}`}>
                    <Box
                         sx={{
                              '& .MuiTextField-root': { m: 1, width: '25ch' }
                         }}
                         noValidate
                         autoComplete="off">
                         <StyledTextField
                              id="outlined-select-currency"
                              variant="outlined"
                              label="Début"
                              InputLabelProps={{ shrink: true }}
                              type="date"
                              value={startDate}
                              onChange={(e) => {
                                   setStartDate(e.target.value);
                              }}
                         />
                         <StyledTextField
                              id="outlined-select-currency"
                              label="Fin"
                              sx={{ width: '100%' }}
                              InputLabelProps={{ shrink: true }}
                              type="date"
                              value={endDate}
                              onChange={(e) => {
                                   setEndDate(e.target.value);
                              }}
                              max={formatOnlyDate(new Date())}
                         />
                    </Box>
                    <div className="account-card-action">
                         <Button variant="contained" color="primary" >Valider</Button>
                         <PdfGenerator color="secondary" filename={"Relevé-"+accountUID+"-"+startDate} searchId="releve-compte">Exporter en PDF</PdfGenerator>
                    </div>
               </SimpleCard>
               <br />
               <SimpleCard id="releve-compte">
                    <div className="account-card-action"  >
                         <div>
                              <Brand color="black" clickable={false}></Brand>
                         </div>
                         <StyledClientInfo >
                              <Box>{account.clientName}</Box>
                              <Box sx={{wordBreak:"break-all"}}>({account.clientUID})</Box>
                         </StyledClientInfo>
                    </div>

                    <br />
                    <Box>
                         <Typography>
                              <StyledSpan >Numéro de compte : </StyledSpan>
                              <StyledSpan fontWeight="none">{account.accountUID} </StyledSpan>
                         </Typography>
                         <Typography>
                              <StyledSpan >{account.accountTypeName}</StyledSpan>
                         </Typography>
                         <Typography>
                              <StyledSpan >Solde du {account.balanceModified?formatDate(new Date(account.balanceModified)):""}: </StyledSpan>
                              <StyledSpan fontWeight="none">{formattedNumber(account.balance)} Ar</StyledSpan>
                         </Typography>
                    </Box>
                    <SimpleTable data={operations || []} columns={columns} loading={loading}></SimpleTable>
                    <br />
                    <Typography>
                         <StyledSpan >Total des opérations : </StyledSpan>
                         <StyledSpan fontWeight="none">{formattedNumber(total)} Ar</StyledSpan>
                    </Typography>
               </SimpleCard>
          </Container >
     )
}
export default AccountStatement;
