
import { useState } from 'react';
import { Button, Box } from "@mui/material";
import { Container } from 'app/components/Content/Container';
import { SimpleCard, Breadcrumb } from "app/components";
import { PaginationTable } from "app/components/table/PaginationTable";
import ClientStatus from 'app/components/client/ClientStatus';
import { findAllValidationClient } from 'app/services/ClientService';

const ValidationClientsNotSent = ({ }) => {
     const [page, setPage] = useState(0);
     const [loadingData, setLoadingData] = useState(false);
     const [totalValidationClients, setTotalValidationClients] = useState(0);

     const [validationClients, setValidationClients] = useState([]);
     const getValidationClientsNotSent = (newPage = null) => {
          setLoadingData(true);
          findAllValidationClient(newPage == null ? page : newPage, false).then((res) => {
               setValidationClients(res.data.data.list);
               setTotalValidationClients(res.data.data.total);
               setPage(newPage == null ? page : newPage);
               setLoadingData(false);
          }).catch((err) => {
               console.error(err);
               setLoadingData(false);
          });
     }
     const notify = (id) => {
          setPage(0);
          getValidationClientsNotSent(0);

     }
     const columns = [
          {
               name: 'Client',
               selector: (row) => row.clientName,
               style: { width: '100px' }
          },
          {
               name: 'Commentaires',
               selector: (row) => row.comments,
          },
          {
               name: 'Statut du client',
               selector: (row) => <ClientStatus code={row.statusCode} />,
               style: { width: '100px' }
          },
          {
               name: 'Notifier',
               selector: (row) => <Button variant="contained" color="primary" onClick={() => notify(row.idValidation)}>Notifier</Button>,
          }
     ]
     const handleChangePage = (_, newPage) => {
          newPage === 1 ? setPage(page + 1) : setPage(page - 1);
          getValidationClientsNotSent(newPage === 1 ? (page + 1) : (page - 1));
     };
     return (
          <Container>
               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={[{ name: 'Validation de client non notifiés' },]}
                    />
               </Box>
               <SimpleCard title="Notifications non envoyés sur le processus de validation de client">
                    <br />
                    <PaginationTable data={validationClients} columns={columns} handleChangePage={handleChangePage} page={page}
                         loading={loadingData} count={totalValidationClients}></PaginationTable>
               </SimpleCard>
          </Container>
     );
}
export default ValidationClientsNotSent;
