import React from 'react'
import PhoneInput from 'react-phone-input-2'
import { styled } from '@mui/material';
import "./PhoneInput.css";
import { isValidPhoneNumber } from 'libphonenumber-js'

export const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
     margin: theme.spacing(1),
     width: "100%"
}));

const getSize = (size) => {
     switch (size) {
          case "small":
               return "24px";
          case "medium":
               return "28px";
          case "large":
               return "32px";
          case "xlarge":
               return "36px";
          case "xxlarge":
               return "40px";
          default:
               return "24px";
     }
}

const MGPhoneInput = ({ size = "small", label = "Téléphone", name = "telephone", withValidation = false, touched = false, onBlur = () => { }, ...props }) => {
     const styleMessageError=(display)=>{
          const input=document.querySelector(`#telephone-input-id`);
          if(input!==undefined && input!==null){
               input.style.display=display;
          }
     }
     return (
          <>
               <StyledPhoneInput
                    specialLabel={label}
                    placeholder="Téléphone"
                    country="mg"
                    disabledCountryCode={true}
                    disableDropdown={true}
                    inputStyle={{ width: "100%", height: getSize(size) }}
                    inputClass="inputStyle"
                    inputProps={{
                         name: name, // Set the name attribute for the input
                    }}
                    containerClass="labelStyle"
                    onBlur={onBlur}
                    {...props}
                    masks={{mg:'.. .. ... ..'}}
                    isValid={(inputNumber, country) => {
                         if (withValidation) {
                              // console.log(country);
                              if (country !== undefined) {
                                   const val = isValidPhoneNumber(inputNumber, country.iso2.toUpperCase());
                                   if(!touched[name]){
                                        return true;
                                   }
                                   if (touched[name] && !val) {
                                        styleMessageError("block");
                                   } else if(val) {
                                        styleMessageError("none");
                                   }                      
                                   return val;
                              } else {
                                   styleMessageError("none");
                                   return true;
                              }
                         } else {
                              styleMessageError("none");
                         }
                         return true;
                    }}
               />
               <p id="telephone-input-id" className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-ftcob6-MuiFormHelperText-root" style={{ display: "none" }}>Téléphone requis ou invalide</p>
          </>
     )
}
export default MGPhoneInput;
