import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';

//component
import { Grid, MenuItem } from "@mui/material";
import ErrorDisplay from "app/components/Content/ErrorDisplay";
import { TextFieldReadOnlyDisabled, StyledTextField } from "app/components/Content/CustomInput";

import { LoadButton } from "app/components/Content/CustomLoadButton";
import SuccessDisplay from "app/components/Content/SuccessDisplay";
import ClientNav from "./ClientNav";
import MGPhoneInput from "app/components/Content/MGPhoneInput";
import LoadingContainer from "app/components/Content/LoadingContainer";

import { updateClient, getClientInfoByUID } from "app/services/ClientService";
import { formatCompleteDate } from "app/services/Utils";


const validationSchema = Yup.object().shape({
     lastName: Yup.string().required('Nom requis'),
     firstNames: Yup.string().required('Prénom(s) requis'),
     telephone: Yup.string().required('Téléphone requis').min(10, 'Téléphone invalide'),
     email: Yup.string().required("Email requis").email('Email invalide'),
     identityNumber: Yup.string().required("Numéro de pièce d'identité requis"),
     statusCode: Yup.string().required('Statut requis'),
     comments: Yup.string().nullable(),
     // gatewayReferences: Yup.array().nullable(),
});

const DetailClient = () => {
     const { clientUID } = useParams();
     const [client, setClient] = useState({});
     const [loadingData, setLoadingData] = useState(false);
     const [loading, setLoading] = useState(false);
     const [messageError, setMessageError] = useState('');
     const [error, setError] = useState(false);
     const [success, setSuccess] = useState(false);

     const getClient = () => {
          setLoadingData(true);
          getClientInfoByUID(clientUID).then((res) => {
               setClient(res.data.data.client || {});
               setLoadingData(false);
          }).catch((err) => {
               console.error(err);
               setLoadingData(false);
          });
     }

     const handleFormSubmit = async (values) => {
          setLoading(true);
          setSuccess(false);
          try {
               // console.log(values);
               await updateClient(values, clientUID).then((res) => {
                    setLoading(false);
                    setError(false);
                    setSuccess(true);
                    // dispatchClientInfo(dispatch, clientUID);
                    getClient();
               });
          } catch (e) {
               setMessageError(e.response.data.message)
               setLoading(false);
               setError(true);
          }
     }

     useEffect(() => {
          try {
               getClient();
          } catch (ex) {
               console.error(ex);
          }

     }, [])
     return (
          <ClientNav client={client} currentValue="1" clientUID={clientUID} section={{ name: "Informations Générales" }}>
               <SuccessDisplay open={success} setOpen={setSuccess} message="Informations modifiées avec succès" />

               <LoadingContainer loading={loadingData}>
                    <Formik
                         onSubmit={handleFormSubmit}
                         validationSchema={validationSchema}
                         initialValues={client}
                         method="POST"
                         enableReinitialize
                    >
                         {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                              <form onSubmit={handleSubmit}>
                                   <Grid container spacing={6}>
                                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                             <StyledTextField
                                                  size="small"
                                                  type="text"
                                                  name="lastName"
                                                  label="Nom"
                                                  variant="outlined"
                                                  onBlur={handleBlur}
                                                  value={values.lastName || ""}
                                                  onChange={handleChange}
                                                  helperText={touched.lastName && errors.lastName}
                                                  error={Boolean(errors.lastName && touched.lastName)}
                                             />

                                             <StyledTextField
                                                  size="small"
                                                  type="text"
                                                  name="firstNames"
                                                  label="Prénom(s)"
                                                  variant="outlined"
                                                  onBlur={handleBlur}
                                                  value={values.firstNames || ""}
                                                  onChange={handleChange}
                                                  helperText={touched.firstNames && errors.firstNames}
                                                  error={Boolean(errors.firstNames && touched.firstNames)}
                                             />
                                             <StyledTextField
                                                  size="small"
                                                  type="text"
                                                  name="identityNumber"
                                                  label="CIN"
                                                  variant="outlined"
                                                  onBlur={handleBlur}
                                                  value={values.identityNumber || ""}
                                                  onChange={handleChange}
                                                  helperText={touched.identityNumber && errors.identityNumber}
                                                  error={Boolean(errors.identityNumber && touched.identityNumber)}
                                             />
                                             <MGPhoneInput name="telephone" onChange={(event) => {
                                                  setFieldValue("telephone", event);
                                             }}
                                                  value={values.telephone || ""}
                                                  onBlur={handleBlur}
                                                  helperText={touched.telephone && errors.telephone}
                                                  error={Boolean(errors.telephone && touched.telephone)}
                                                  touched={touched}
                                                  withValidation={true}
                                             />
                                             <StyledTextField
                                                  size="small"
                                                  type="text"
                                                  name="email"
                                                  label="Email"
                                                  variant="outlined"
                                                  onBlur={handleBlur}
                                                  value={values.email || ""}
                                                  onChange={handleChange}
                                                  helperText={touched.email && errors.email}
                                                  error={Boolean(errors.email && touched.email)}
                                             />
                                             <TextFieldReadOnlyDisabled
                                                  label="ClientUID"
                                                  value={values.clientUID || ""}
                                                  multiline={true}
                                                  rows="2"
                                             />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                             {client.statusCode !== "ENABLED" && client.statusCode !== "BLOCKED" ?
                                                  null
                                                  :
                                                  <StyledTextField
                                                       select
                                                       size="small"
                                                       type="text"
                                                       name="statusCode"
                                                       label="Statut"
                                                       variant="outlined"
                                                       onBlur={handleBlur}
                                                       value={values.statusCode || ""}
                                                       onChange={handleChange}
                                                       helperText={touched.statusCode && errors.statusCode}
                                                       error={Boolean(errors.statusCode && touched.statusCode)}
                                                  >
                                                       <MenuItem value="ENABLED">Activé</MenuItem>
                                                       <MenuItem value="BLOCKED">Bloqué</MenuItem>
                                                  </StyledTextField>
                                             }

                                             <TextFieldReadOnlyDisabled
                                                  label="Compte de frais"
                                                  value={values.feeAccountUID || ""}
                                             />
                                             <StyledTextField
                                                  size="small"
                                                  type="text"
                                                  name="comments"
                                                  label="Commentaire"
                                                  variant="outlined"
                                                  multiline={true}
                                                  rows="3"
                                                  onBlur={handleBlur}
                                                  value={values.comments || ""}
                                                  onChange={handleChange}
                                                  helperText={touched.comments && errors.comments}
                                                  error={Boolean(errors.comments && touched.comments)
                                                  }
                                             />
                                             <TextFieldReadOnlyDisabled type="datetime"
                                                  label="Date de création"
                                                  value={formatCompleteDate(values.dateTimeCreated) || ""}
                                             />
                                             <TextFieldReadOnlyDisabled type="datetime"
                                                  label="Date de modification"
                                                  value={formatCompleteDate(values.dateTimeModified) || ""}
                                             />
                                        </Grid>

                                   </Grid>
                                   <LoadButton loading={loading}>Modifier</LoadButton>

                                   <ErrorDisplay error={error} messageError={messageError} />
                              </form>
                         )}
                    </Formik>
                    <br />

               </LoadingContainer>
          </ClientNav>
     );
};

export default DetailClient;
