import { configureStore } from '@reduxjs/toolkit';
import clientInfoReducer from './clientInfoSlice';
import { setClientInfo } from './clientInfoSlice';
import { getClientInfoByUID } from "app/services/ClientService";
import apikeyReducer from './apiKeySlice';
import { findAllApplication } from 'app/services/ApiKeyService';
import { setApiKeys } from './apiKeySlice';
import userReducer from './userSlice';
import { setUser } from './userSlice';
import { getUserById } from 'app/services/UserService';


export const store = configureStore({
     reducer: {
          clientInfo: clientInfoReducer,
          apiKeys: apikeyReducer,
          user: userReducer,
     }
});
export const dispatchClientInfo = async (dispatch, clientUID = null, setLoading = (bool) => { }) => {
     try {
          if (clientUID == null) {
               dispatch(setClientInfo({ client: {}, accounts: [], validationClients: [], firstAccount: {} }));
          } else {
               getClientInfoByUID(clientUID).then((res) => {
                    const clientInfo = res.data.data;
                    clientInfo.firstAccount = null;
                    if (clientInfo.accounts != null && clientInfo.validationClients != null) {
                         let firstAccountID = null;
                         for (let i = 0; i < clientInfo.validationClients.length; i++) {
                              if (clientInfo.validationClients[i].idFirstAccount) {
                                   firstAccountID = clientInfo.validationClients[i].idFirstAccount;
                                   break;
                              }
                         }
                         //console.log(clientInfo.validationClients)
                         const acc = clientInfo.accounts.find((account) => account.idAccount === firstAccountID) || null;
                         clientInfo.firstAccount = acc || null;
                    }
                    setLoading(false);
                    dispatch(setClientInfo(clientInfo));
                    
               }).catch((e)=>console.error(e));
          }

     } catch (e) {
          //à traiter
          console.error(e);
     }
}

export const dispatchApiKeys = async (dispatch, search, page, setLoading = (bool) => { }) => {
     try {
          await findAllApplication(search, page).then((res) => {
               dispatch(setApiKeys(res.data.data));
               // console.log(res.data.data);
               setLoading(false);
          });
     } catch (e) {
          //à traiter
          console.error(e);
          setLoading(false);
     }
}

export const dispatchUser = async (dispatch, iduser, setLoading = (bool) => { }) => {
     try {
          await getUserById(iduser).then((res) => {
               //  console.log(res.data.data);
               const user=res.data.data;
               const localUser=JSON.parse(localStorage.getItem('user-info'));
               if(user.iduser===localUser.iduser){
                    const userInfo={...localUser, username:user.username, name:user.name, email:user.email, telephone:user.telephone, roles:user.roles.map((elt)=>elt.code)};
                    localStorage.setItem("user-info", JSON.stringify(userInfo));
               }
               // console.log(localStorage.getItem('user-info'));
               dispatch(setUser(user));
               setLoading(false);
          }).catch((e) => { console.error(e); setLoading(false) });
     } catch (e) {
          //à traiter
          console.error(e);
          setLoading(false);
     }
}


