import React, { useState, useEffect } from "react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";

import { Box, Grid, FormControlLabel, Checkbox, FormGroup, Button, Step, StepLabel, Stepper } from "@mui/material";
import { Container } from 'app/components/Content/Container';
import { SimpleCard, Breadcrumb } from "app/components";
import { StyledTextField } from "app/components/Content/CustomInput";
import { LoadButton } from "app/components/Content/CustomLoadButton";
import ErrorDisplay from "app/components/Content/ErrorDisplay";
import MGPhoneInput from "app/components/Content/MGPhoneInput";

import { getAllRoles, addUser } from "app/services/UserService";

const validationSchema = Yup.object().shape({
     username: Yup.string().required('Identifiant requis'),
     name: Yup.string().required('Nom complet requis').min(3, "Le nom complet doit au moins comporté 3 caractères"),
     password: Yup.string().required('Mot de passe requis').min(8, "Le mot de passe doit au moins comporté 8 caractères"),
     telephone: Yup.string().required('Téléphone requis'),
     email: Yup.string().email("Email invalide!"),
});
const initialValues = {
     username: '',
     name: '',
     password: '',
     telephone: '',
     email: '',
}
const FormUser = () => {
     const navigate = useNavigate();
     const [activeStep, setActiveStep] = React.useState(0);
     const [loading, setLoading] = useState(false);
     const [roles, setRoles] = useState([]);
     const [checkedValues, setCheckedValues] = useState([]);
     const [messageError, setMessageError] = useState('');
     const [error, setError] = useState(false);

     const steps = ["Informations générales", "Privilèges"];

     const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

     const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

     const handleChecked = (e) => {
          const check = e.target.checked;
          const value = e.target.value;
          setCheckedValues((prevCheckedValues) => {
               if (check) {
                    return [...prevCheckedValues, parseInt(value)];
               } else {
                    return prevCheckedValues.filter((checkedValue) => checkedValue !== parseInt(value));
               }
          });
     };

     const handleFormSubmit = async (values) => {

          if (checkedValues.length === 0) {
               setError(true);
               setMessageError("Veuillez sélectionner au moins un privilège");
          } else {
               setLoading(true);
               try {
                    await addUser(values, checkedValues).then((res) => {
                         setLoading(false);
                         setError(false);
                         navigate(`/user/${res.data.data.iduser}`)
                    })
               } catch (e) {
                    setMessageError(e.response.data.message)
                    setLoading(false);
                    setError(true);
               }
          }
     }
     useEffect(() => {
          getAllRoles().then((response) => {
               setRoles(response.data.data);
          }).catch((err) => console.error(err));
     }, []);

     return (
          <Container>
               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={[{ name: 'Utilisateurs', path: '/users' }, { name: `Nouvel utilisateur` }]}
                    />
               </Box>
               <SimpleCard title="Utilisateurs">
                    <Box>
                         <Stepper activeStep={activeStep} alternativeLabel>
                              {steps.map((label) => (
                                   <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                   </Step>
                              ))}
                         </Stepper>

                         <Box mt={4}>
                              <Formik
                                   onSubmit={handleFormSubmit}
                                   validationSchema={validationSchema}
                                   initialValues={initialValues}
                                   method="POST"
                              >
                                   {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, setFieldValue }) => (
                                        <form onSubmit={handleSubmit}>
                                             <Box display={activeStep === 0 ? "inherit" : "none"}>
                                                  <Grid container spacing={6}>
                                                       <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                                            <StyledTextField
                                                                 size="small"
                                                                 type="text"
                                                                 name="name"
                                                                 label="Nom complet"
                                                                 variant="outlined"
                                                                 onBlur={handleBlur}
                                                                 value={values.name}
                                                                 onChange={handleChange}
                                                                 helperText={touched.name && errors.name}
                                                                 error={Boolean(errors.name && touched.name)}
                                                            />
                                                            <StyledTextField
                                                                 size="small"
                                                                 type="text"
                                                                 name="username"
                                                                 label="Identifiant"
                                                                 variant="outlined"
                                                                 onBlur={handleBlur}
                                                                 value={values.username}
                                                                 onChange={handleChange}
                                                                 helperText={touched.username && errors.username}
                                                                 error={Boolean(errors.username && touched.username)}
                                                            />
                                                            <StyledTextField
                                                                 size="small"
                                                                 type="password"
                                                                 name="password"
                                                                 label="Mot de passe"
                                                                 variant="outlined"
                                                                 onBlur={handleBlur}
                                                                 value={values.password}
                                                                 onChange={handleChange}
                                                                 helperText={touched.password && errors.password}
                                                                 error={Boolean(errors.password && touched.password)}
                                                            />
                                                            <MGPhoneInput name="telephone" onChange={(event) => {
                                                                 setFieldValue("telephone", event);
                                                            }}
                                                                 value={values.telephone || ""}
                                                                 onBlur={handleBlur}
                                                                 helperText={touched.telephone && errors.telephone}
                                                                 error={Boolean(errors.telephone && touched.telephone)}
                                                                 withValidation={true}
                                                                 touched={touched}
                                                            />
                                                            <StyledTextField
                                                                 size="small"
                                                                 type="email"
                                                                 name="email"
                                                                 label="Email"
                                                                 variant="outlined"
                                                                 onBlur={handleBlur}
                                                                 value={values.email}
                                                                 onChange={handleChange}
                                                                 helperText={touched.email && errors.email}
                                                                 error={Boolean(errors.email && touched.email)}
                                                            />
                                                       </Grid>
                                                  </Grid>
                                                  <Box pt={2}>
                                                       <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            disabled
                                                            onClick={handleBack}
                                                       >
                                                            Revenir
                                                       </Button>

                                                       <Button sx={{ ml: 2 }} variant="contained" color="primary" onClick={handleNext} type="button" disabled={!isValid}>
                                                            Suivant
                                                       </Button>
                                                  </Box>
                                             </Box>
                                             <Box display={activeStep === 0 ? "none" : "inherit"}>
                                                  <Grid container spacing={6}>
                                                       <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                                            <FormGroup>
                                                                 {roles.map((role, index) => (
                                                                      <FormControlLabel
                                                                           key={index}
                                                                           control={
                                                                                <Checkbox onChange={handleChecked} value={role.idrole.toString()} />
                                                                           }
                                                                           name={role.idrole.toString()}
                                                                           label={`${role.name} (${role.code})`}
                                                                           onBlur={handleBlur}
                                                                      />
                                                                 ))}
                                                            </FormGroup>
                                                       </Grid>
                                                  </Grid>
                                                  <Box pt={2}>
                                                       <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={handleBack}
                                                       >
                                                            Revenir
                                                       </Button>

                                                       <LoadButton loading={loading} sx={{ ml: 2 }}>Créer</LoadButton>

                                                       <ErrorDisplay error={error} messageError={messageError} />

                                                  </Box>
                                             </Box>
                                        </form>
                                   )}
                              </Formik>
                         </Box>
                    </Box>
               </SimpleCard>
          </Container >)
}
export default FormUser;
