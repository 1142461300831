import { lazy } from 'react';
import AuthGuard from './auth/AuthGuard';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
//import materialRoutes from 'app/views/material-kit/MaterialRoutes';

import Clients from './views/clients/Clients';
import OperationType from './views/operations/OperationType';
import ApiKeys from './views/apiKeys/Applications';
import Users from './views/users/Users';
import FormUser from './views/users/FormUser';
import User from './views/users/User';
import Dashboard from './views/dashboard/Dashboard';
import Forbidden from './views/sessions/Forbidden';
import AuditLogs from './views/logs/AuditLogs';
import AuditLog from './views/logs/AuditLog';
import DetailClient from './views/clients/DetailClient';
import ClientOperations from './views/operations/ClientOperations';
import ClientAccounts from './views/accounts/ClientAccounts';
import Operations from './views/operations/Operations';
import DashBoardClient from './views/dashboard-client/DashboardClient';
import DashBoardApiKey from './views/dashboard-apikey/DashBoardApikey';
import ProfilUser from './views/users/ProfilUser';
import Accounts from './views/accounts/Accounts';
import PhaseClient from './views/client-validation/PhaseClient';
import ClientsDisabled from './views/client-validation/ClientsDisabled';
import ValidationClientsNotSent from './views/client-validation/ValidationClientsNotSent';
import AccountLogs from './views/logs/AccountLogs';
import TransferLogs from './views/logs/TransferLogs';
import AccountStatement from './views/accounts/AccountStatement';
import Report from './views/apiKeys/Report';
import Settings from './views/settings/Settings';
import DetailApplication from './views/apiKeys/DetailApplication';
import Application from './views/apiKeys/Application';

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));


const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      { path: '/clients', element: <Clients /> },
      { path: '/clientsdisabled', element: <ClientsDisabled /> },
      { path: '/client/:clientUID/detail/1', element: <DetailClient /> },
      { path: '/client/:clientUID/detail/2', element: <ClientAccounts /> },
      { path: '/client/:clientUID/detail/3', element: <ClientOperations /> },
      { path: '/client/:clientUID/detail/4', element: <DashBoardClient /> },
      { path: '/client/:clientUID/validations', element: <PhaseClient /> },
      { path: '/validation_clients', element: <ValidationClientsNotSent /> },
      { path: '/client/:clientUID/operation/:type?', element: <OperationType /> },
      { path: '/account/:accountUID/statement', element: <AccountStatement /> },
      { path: '/operations', element: <Operations /> },
      { path: '/applications', element: <ApiKeys /> },
      { path: '/application/:reference', element: <Application /> },
      { path: '/users', element: <Users /> },
      { path: '/newuser', element: <FormUser /> },
      { path: '/dashboard', element: <Dashboard /> },
      { path: '/user/profile', element: <ProfilUser /> },
      { path: '/user/:iduser', element: <User /> },
      { path: 'logs/account', element: <AccountLogs /> },
      { path: '/logs/transfer', element: <TransferLogs /> },
      { path: '/logs/:log/:filename', element: <AuditLog /> },
      { path: '/report/:reference', element: <Report /> },
      { path: '/rules', element: <Settings /> }
    ]
  },
  { path: '/login', element: < JwtLogin /> },
  { path: '/', element: < JwtLogin /> },
  { path: '/login/:context', element: < JwtLogin /> },
  { path: '/unauthorized', element: <Forbidden /> },
  { path: '*', element: <NotFound /> }
];

export default routes;

export const roleRoutes = [
  { requiredRoles: ['ADMIN', 'BASIC_USER', 'CLIENT', 'ACCOUNT', 'OPERATION'], pathPattern: /\/clients/ },
  { requiredRoles: ['ADMIN', 'BASIC_USER', 'CLIENT'], pathPattern: /\/clients\/disabled/ },
  { requiredRoles: ['ADMIN', 'BASIC_USER', 'CLIENT'], pathPattern: /\/client\/.*\/detail\/1/ },
  { requiredRoles: ['ADMIN', 'BASIC_USER', 'ACCOUNT'], pathPattern: /\/client\/.*\/detail\/2/ },
  { requiredRoles: ['ADMIN', 'BASIC_USER', 'OPERATION'], pathPattern: /\/client\/.*\/detail\/3/ },
  { requiredRoles: ['ADMIN', 'BASIC_USER', 'CLIENT', 'ANALYTICS'], pathPattern: /\/client\/.*\/detail\/4/ },
  { requiredRoles: ['ADMIN', 'BASIC_USER', 'CLIENT'], pathPattern: /\/validation_clients/ },
  { requiredRoles: ['ADMIN', 'BASIC_USER', 'OPERATION'], pathPattern: /\/operations/ },
  { requiredRoles: ['ADMIN', 'APIKEY'], pathPattern: /\/apikey.*/ },
  { requiredRoles: ['ADMIN', 'APIKEY'], pathPattern: /\/application.*/ },
  { requiredRoles: ['ADMIN', 'MANAGE_USER'], pathPattern: /\/users/ },
  { requiredRoles: ['ADMIN', 'MANAGE_USER'], pathPattern: /\/newuser/ },
  { requiredRoles: ['ADMIN', 'BASIC_USER', 'ANALYTICS'], pathPattern: /\/dashboard/ },
  { requiredRoles: ['ADMIN', 'BASIC_USER', 'ACCOUNT'], pathPattern: /\/logs\/account/ },
  { requiredRoles: ['ADMIN', 'BASIC_USER', 'OPERATION'], pathPattern: /\/logs\/transfer/ },
  { requiredRoles: ['ADMIN'], pathPattern: /\/rules/ }
];

