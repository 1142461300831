import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Grid, styled, useTheme, Box } from '@mui/material';
import StatCard from 'app/components/dashboard/StatCard';
import SimpleTable from 'app/components/table/SimpleTable';
import Breadcrumb from 'app/components/Breadcrumb';
import { Container } from 'app/components/Content/Container';
import TransactionTypeAnalytics from './TransactionTypeAnalytics';
import TransactionAnalytics from './TransactionAnalytics';
import Small from 'app/components/Content/Small';

import { getGlobalAnalytics, getOperationAnalytics } from 'app/services/AnalyticsService';
import { formattedNumber, formatDate } from 'app/services/Utils';
import { Navigate } from 'react-router-dom';

const ContentBox = styled('div')(({ theme }) => ({
     margin: '30px',
     [theme.breakpoints.down('sm')]: { margin: '16px' },
}));
const CardHeader = styled(Box)(() => ({
     display: 'flex',
     paddingLeft: '24px',
     paddingRight: '24px',
     marginBottom: '12px',
     alignItems: 'center',
     justifyContent: 'space-between',
}));

const Title = styled('span')(() => ({
     fontSize: '1rem',
     fontWeight: '500',
}));

const Dashboard = () => {
     const { palette } = useTheme();
     const bgPrimary = palette.primary.main;
     const bgSecondary = palette.secondary.main;

     const navigate = useNavigate();

     const [numberClient, setNumberClient] = useState(0);
     const [amountTransaction, setAmountTransaction] = useState(0);
     const [numberApplicationEnabled, setNumberApplicationEnabled] = useState(0);
     const [numberApplication, setNumberApplication] = useState(0);
     const [lastOperations, setLastOperations] = useState([]);
     const [topClient, setTopClient] = useState([]);

     const year = new Date().getFullYear();
     const columnLastOperation = [
          {
               name: 'Date',
               selector: (row) => formatDate(new Date(row.dateTime)),
               style: { width: '130px' }
          },
          {
               name: "Montant",
               selector: (row) => row.amount < 0 ? (
                    <Small bgcolor={bgSecondary}>{formattedNumber(row.amount)} ar</Small>
               ) : (
                    <Small bgcolor={bgPrimary}>{formattedNumber(row.amount)} ar</Small>
               )
          },
          {
               name: 'Référence',
               selector: (row) => row.reference,
               // style: { width: '170px' }

          }
     ];
     const columnTopClient = [
          {
               name: "Nom",
               selector: (row) => row.client.lastName + " " + row.client.firstNames,
          },
          {
               name: 'Montant total',
               selector: (row) => formattedNumber(row.amount) + " ar",
          }

     ]
     useEffect(() => {
          try {
               getGlobalAnalytics().then((res) => {
                    console.log(res);
                    setNumberClient(res.data.data.totalClientEnabled);
                    setAmountTransaction(res.data.data.totalAmountTransactionInYear);
                    setNumberApplicationEnabled(res.data.data.totalApplicationEnabled);
                    setNumberApplication(res.data.data.totalApplication);
               });
               getOperationAnalytics().then((res) => {
                    setLastOperations(res.data.data.lastOperations);
                    setTopClient(res.data.data.clientTransaction);
               });
          } catch (ex) {
               console.error(ex);
          }
     }, [])
     return (
          <div>
               <Container>
                    <Box className="breadcrumb">
                         <Breadcrumb
                              routeSegments={[{ name: 'Dashboard', path: '/dashboard' }]}
                         />
                    </Box>
               </Container>

               <Fragment>
                    <ContentBox className="analytics">
                         <Grid container spacing={3} sx={{ mb: '24px' }}>
                              <Grid item xs={12} sm={4} md={4}  >
                                   <StatCard statName="Clients actifs" statCount={formattedNumber(numberClient) || 0} icon="group" color={[17, 105, 88]} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}  >
                                   <StatCard statName="Transactions" statCount={(formattedNumber(amountTransaction) || 0) + " ar"} percentage={year} icon="swap_horiz"
                                        color={[240, 182, 5]} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}  >
                                   <StatCard
                                        statName="Applications actives"
                                        statCount={numberApplicationEnabled || 0}
                                        icon="view_comfy" color={[71, 186, 163]}
                                        percentage={"total: " + numberApplication}
                                        onClick={() => navigate("/apikeys")}
                                        sx={{
                                             '&:hover': {
                                                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                             },
                                        }}
                                   />
                              </Grid>
                         </Grid>
                         <Grid container spacing={3}>
                              <Grid item lg={6} md={12} sm={12} xs={12}>
                                   <Card elevation={3} sx={{ padding: '20px 24px' }}>
                                        <CardHeader>
                                             <Title>Dernières opérations</Title>
                                        </CardHeader>
                                        <SimpleTable data={lastOperations} columns={columnLastOperation} />
                                   </Card>
                              </Grid>
                              <Grid item lg={6} md={12} sm={12} xs={12}>
                                   <Card elevation={3} sx={{ padding: '20px 24px' }}>
                                        <CardHeader>
                                             <Title>Clients ayant effectué le plus de transaction la semaine dernière</Title>
                                        </CardHeader>
                                        <SimpleTable data={topClient} columns={columnTopClient} />
                                   </Card>
                              </Grid>
                         </Grid>
                    </ContentBox>
                    <ContentBox className="analytics">
                         <TransactionTypeAnalytics />
                         <TransactionAnalytics />

                    </ContentBox>

               </Fragment>
          </div>
     );
}
export default Dashboard;
