import { Card, Typography, useTheme } from '@mui/material';
import Small from 'app/components/Content/Small';

import { formattedNumber, formatDate } from 'app/services/Utils';

const OperationCard = ({ operation }) => {
     const { palette } = useTheme();
     const bgPrimary = palette.primary.main;
     const date= operation.dateTime?new Date(operation.dateTime) : new Date();
     
     return (
          <Card sx={{ borderRadius: "5px", padding: "15px 15px 0px", marginBottom: "5px" }} >
               <div className="account-card-action">
                    <Typography> {operation.otherClientName !== null ? <> à {operation.otherClientName}</> : ""}</Typography>
                    <Small bgcolor={bgPrimary}>{formattedNumber(operation.amount || 0) + " Ar"}</Small>                 
               </div>
               <br />
               <div className="account-card-action">
                    <Typography level="title-md" >ref: {operation.reference}, </Typography>
                    <Typography level="title-md" >{formatDate(date)}</Typography>
               </div>
          </Card>
     )
}
export default OperationCard;
