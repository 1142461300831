import { LoadingButton } from "@mui/lab"

export const LoadButton = ({ loading, children, ...props }) => {
     return (
          <LoadingButton
               type="submit"
               color="primary"
               loading={loading}
               variant="contained"
               sx={{my:2}}
               {...props}
          >
               {children}
          </LoadingButton>
     )
}
