import React, { useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { dispatchClientInfo } from "app/redux/store";

//component
import { LoadButton } from "app/components/Content/CustomLoadButton";
import { Grid, MenuItem, Autocomplete, CircularProgress, Button, DialogTitle, DialogContent, Dialog } from "@mui/material";
import ErrorDisplay from "app/components/Content/ErrorDisplay";
import { StyledTextField } from "app/components/Content/CustomInput";
import { TextFieldReadOnlyDisabled} from "app/components/Content/CustomInput";
import SuccessDisplay from "app/components/Content/SuccessDisplay";

import { transfer } from "app/services/OperationService";
import { elasticSearch } from "app/services/ClientService";
import { getAccountsByClientUID } from "app/services/AccountService";

const validationSchema = Yup.object().shape({
     sourceAccountUID: Yup.string().required('Le choix du compte est requis'),
     label: Yup.string().required('Un libellé est requis'),
     transferredAmount: Yup.number().required('Le montant est requis').min(1, 'Le montant doit être supérieur à 0'),
     recipientAccountUID: Yup.string().required('Le compte du destinataire est requis').min(11, 'Le compte du destinataire non valide'),
});

const Transfer = ({ clientUID }) => {
     const dispatch = useDispatch();
     const urlParams=new URLSearchParams(window.location.search);

     const client = useSelector((state) => state.clientInfo.dataClient.client);
     const accountsClient = useSelector((state) => state.clientInfo.dataClient.accounts);

     const [loading, setLoading] = useState(false);
     const [loadingElasticSearch, setLoadingElasticSearch] = useState(false); //loading for elastic search [client
     const [messageError, setMessageError] = useState('');
     const [error, setError] = useState(false);
     const [success, setSuccess] = useState(false);
     const [open, setOpen] = useState(false);
     const [optionRecipient, setOptionRecipient] = useState([]);
     const [inputValue, setInputValue] = useState("")
     const [accountsRecipient, setAccountsRecipient] = useState([])

     const initialValues = {
          sourceAccountUID: urlParams.get('accountUID')||"",
          recipientAccountUID: '',
          label: '',
          transferredAmount: urlParams.get('amount')||"",
     }

     //get the client that match the autocomplete and if select then select the recipientAccountUID
     const handleInputChange = async (event) => {
          if (event.target.value.length > 2 && event.target.value !== inputValue) {
               setInputValue(event.target.value);
               try {
                    setOpen(false)
                    setLoadingElasticSearch(true);
                    await elasticSearch(inputValue).then((res) => {
                         setOptionRecipient(res.data.data);
                         setOpen(true);
                         setLoadingElasticSearch(false);
                    });
               } catch (error) {
                    setLoadingElasticSearch(false);
                    console.error('Error fetching options: ', error);
               }
          }
     }
     const handleOptionChange = async (event, newValue) => {
          //prendre les comptes
          try {
               await getAccountsByClientUID(newValue.clientUID).then((res) => {
                    setAccountsRecipient(res.data.data);
               });
          } catch (error) {
               console.error('Error fetching options: ', error);
          }
     };
     const handleFormSubmit = async (values, action) => {
          setLoading(true);
          setSuccess(false);
          try {

               await transfer(clientUID, values).then((res) => {
                    setLoading(false);
                    setError(false);
                    action.resetForm({ values: initialValues });
                    dispatchClientInfo(dispatch, clientUID);
                    setSuccess(true);
                    handleClose();
               });
          } catch (e) {
               setMessageError(e.response.data.message)
               setLoading(false);
               setError(true);
               handleClose();
          }
     }
     const [openValidation, setOpenValidation] = useState(false);
     const handleOpen = () => setOpenValidation(true);
     const handleClose = () => setOpenValidation(false);
     return (
          <div>
               <SuccessDisplay open={success} setOpen={setSuccess} message="Transfert réussi" />

               <Formik
                    onSubmit={handleFormSubmit}
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    method="POST"
                    enableReinitialize
               >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                         <form onSubmit={handleSubmit}>
                              <Grid container spacing={6}>
                                   <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                        <TextFieldReadOnlyDisabled label="client" value={client.lastName + " " + client.firstNames} />

                                        <StyledTextField
                                             select
                                             size="small"
                                             name="sourceAccountUID"
                                             label="Compte"
                                             variant="outlined"
                                             onBlur={handleBlur}
                                             value={values.sourceAccountUID || ""}
                                             onChange={handleChange}
                                             helperText={touched.sourceAccountUID && errors.sourceAccountUID}
                                             error={Boolean(errors.sourceAccountUID && touched.sourceAccountUID)}
                                        >
                                             <MenuItem key='1' value={""}>
                                                  Choisir un compte
                                             </MenuItem>
                                             {accountsClient.map((option) => (
                                                  <MenuItem key={option.accountUID} value={option.accountUID}>
                                                       {option.accountUID} ({option.balance} Ar)
                                                  </MenuItem>
                                             ))}
                                        </StyledTextField>

                                        <Autocomplete
                                             open={open}
                                             options={optionRecipient}
                                             id="asynchronous-demo"
                                             onOpen={() => setOpen(true)}
                                             onClose={() => setOpen(false)}
                                             getOptionLabel={(option) => option.firstNames + " " + option.lastName + " (" + option.email + ")"}
                                             filterOptions={(optionRecipient) => optionRecipient}
                                             onChange={handleOptionChange}
                                             renderInput={(params) => (
                                                  <StyledTextField
                                                       {...params}
                                                       fullWidth
                                                       variant="outlined"
                                                       label="Client à qui transférer"
                                                       onChange={handleInputChange}
                                                       multiline={true}
                                                       rows={2}
                                                       InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                 <Fragment>
                                                                      {loadingElasticSearch ? <CircularProgress color="inherit" size={20} /> : null}
                                                                      {params.InputProps.endAdornment}
                                                                 </Fragment>
                                                            )
                                                       }}
                                                  />
                                             )}
                                             renderOption={(props, option) => (
                                                  <li {...props}>
                                                       {option.firstNames} {option.lastName} ({option.email})
                                                  </li>
                                             )}
                                        />
                                        <StyledTextField
                                             select
                                             size="small"
                                             name="recipientAccountUID"
                                             label="Compte"
                                             variant="outlined"
                                             onBlur={handleBlur}
                                             value={values.recipientAccountUID || ""}
                                             onChange={handleChange}
                                             helperText={touched.recipientAccountUID && errors.recipientAccountUID}
                                             error={Boolean(errors.recipientAccountUID && touched.recipientAccountUID)}
                                             disabled={accountsRecipient.length === 0}
                                        >
                                             {accountsRecipient.map((acc, index) => {
                                                  return <MenuItem key={index} value={acc.accountUID}>
                                                       {acc.accountUID} ({acc.accountType?.acceptsDeposit ? "Accepte les dépôts" : "N'accepte pas les dépôts"})
                                                  </MenuItem>
                                             })}
                                        </StyledTextField>

                                        <StyledTextField
                                             size="small"
                                             type="text"
                                             name="label"
                                             label="Libellé"
                                             variant="outlined"
                                             onBlur={handleBlur}
                                             value={values.label || ""}
                                             onChange={handleChange}
                                             helperText={touched.label && errors.label}
                                             error={Boolean(errors.label && touched.label)}
                                        />
                                        <StyledTextField
                                             size="small"
                                             type="number"
                                             name="transferredAmount"
                                             label="Montant"
                                             variant="outlined"
                                             onBlur={handleBlur}
                                             value={values.transferredAmount || ""}
                                             onChange={handleChange}
                                             helperText={touched.transferredAmount && errors.transferredAmount}
                                             error={Boolean(errors.transferredAmount && touched.transferredAmount)}
                                        />
                                   </Grid>
                              </Grid>
                              <Button color="primary" variant="contained" onClick={handleOpen}>Faire le transfert</Button>
                              <Dialog onClose={handleClose} aria-labelledby="form-dialog-title" open={openValidation}>
                                   <DialogContent dividers>
                                        <DialogTitle id="form-dialog-title" onClose={handleClose} component="div">
                                             Etes-vous sûr de vouloir faire ce transfert ?
                                        </DialogTitle>
                                        <div style={{ display: "flex" }}>
                                        <LoadButton loading={loading} onClick={handleSubmit} >Valider</LoadButton>

                                             <Button color="error" variant="contained" sx={{my:2, marginLeft:"auto"}} onClick={handleClose}>Annuler</Button>
                                        </div>
                                   </DialogContent>
                              </Dialog>

                              <ErrorDisplay error={error} messageError={messageError} />
                         </form>
                    )}
               </Formik>
          </div>
     )
}
export default Transfer;
