import { useTheme } from "@mui/material";
import { Paragraph } from 'app/components/Typography';
const ErrorDisplay = ({ messageError, error = true }) => {
     const theme = useTheme();
     return (<>
          {error ? <Paragraph style={{ 'color': theme.palette.error.main }} >
               {messageError}
          </Paragraph> : null}
     </>);
}
export default ErrorDisplay
