import React, { useState, useEffect } from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import { Button, DialogTitle, DialogContent, Dialog, Typography } from "@mui/material";
import AutocompleteAccount from "./AutocompleteAccount";
import { LoadButton } from "app/components/Content/CustomLoadButton";
import { getFirstDepositAmount, changeFirstDepositAmount } from "app/services/SettingService";
import SuccessDisplay from "app/components/Content/SuccessDisplay";
import ErrorDisplay from "app/components/Content/ErrorDisplay";
import { StyledTextField } from "app/components/Content/CustomInput";


const validationSchema = Yup.object().shape({
     min_deposit: Yup.number().required("Un nombre est requis").min(0, "Le montant minimum doit être supérieur à 0"),
})
const SettingMinAmount = () => {
     const [initialValues, setInitialValues] = useState({
          min_deposit: 0
     })

     const [loading, setLoading] = useState(false); //loading for elastic search 
     const [success, setSuccess] = useState(false);
     const [error, setError] = useState(false);
     const [messageError, setMessageError] = useState("");

     const [openValidation, setOpenValidation] = useState(false);
     const handleOpen = () => setOpenValidation(true);
     const handleClose = () => setOpenValidation(false);

     const handleFormSubmit = (values, action) => {
          setLoading(true);
          setSuccess(false);
          try {
               changeFirstDepositAmount(values.min_deposit).then((res) => {
                    setLoading(false);
                    setSuccess(true);
                    setError(false);
                    handleClose();
                    action.resetForm({ values: initialValues });
                    firstdeposit();
               });
          } catch (e) {
               setMessageError(e.response.data.message)
               setLoading(false);
               setError(true);
               handleClose();
          }
     }

     const firstdeposit = () => {
          getFirstDepositAmount().then((res) => {
               setInitialValues({ min_deposit: res.data.data.value });
          }).catch((ex) => console.error(ex));
     }
     useEffect(() => {
          firstdeposit();
     },[]);
     return (
          <>
               <SuccessDisplay open={success} setOpen={setSuccess} message="Changement réussi" />

               <Formik
                    onSubmit={handleFormSubmit}
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    method="POST"
                    enableReinitialize
               >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                         <form onSubmit={handleSubmit}>
                              <Typography>
                                   Montant minimum: {values.min_deposit}
                              </Typography>
                              <br />
                              <StyledTextField
                                   size="small"
                                   type="number"
                                   name="min_deposit"
                                   label="Montant"
                                   variant="outlined"
                                   onBlur={handleBlur}
                                   value={values.min_deposit || ""}
                                   onChange={handleChange}
                                   helperText={touched.min_deposit && errors.min_deposit}
                                   error={Boolean(errors.min_deposit && touched.min_deposit)}
                              />
                              
                              <Button color="primary" variant="contained" onClick={handleOpen} sx={{ mt: 2, mx: 1 }}>Changer montant minimum</Button>
                              <Dialog onClose={handleClose} aria-labelledby="form-dialog-title" open={openValidation}>
                                   <DialogContent dividers>
                                        <DialogTitle id="form-dialog-title" onClose={handleClose} component="div">
                                             Etes-vous sûr de vouloir changer le montant minimum à déposer durant la validation de client?
                                        </DialogTitle>
                                        <div style={{ display: "flex" }}>
                                             <LoadButton loading={loading} onClick={handleSubmit} >Valider</LoadButton>

                                             <Button color="error" variant="contained" sx={{ my: 2, marginLeft: "auto" }} onClick={handleClose}>Annuler</Button>
                                        </div>
                                   </DialogContent>
                              </Dialog>
                              <ErrorDisplay error={error} messageError={messageError} />

                         </form>
                    )}
               </Formik>
          </>

     )
}
export default SettingMinAmount;
