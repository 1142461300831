import { useEffect, useState } from 'react';

import { Card, styled, useTheme, Grid, TextField, MenuItem, lighten, Button } from '@mui/material';
import DoughnutChart from 'app/components/dashboard/DoughnutChart';
import FilterChart from './FilterChart';

import { getTransactionTypeAnalytics, getCurrentWeek } from 'app/services/AnalyticsService';
import { formatOnlyDate } from 'app/services/Utils';
const Title = styled('span')(() => ({
     fontSize: '1rem',
     fontWeight: '500',
     marginRight: '.5rem',
}));

const SubTitle = styled('span')(({ theme }) => ({
     fontSize: '0.875rem',
     color: theme.palette.text.secondary,
}));



const TransactionTypeAnalytics = () => {
     const { palette } = useTheme();
     const bgPrimary = palette.primary.main;
     const bgSecondary = palette.secondary.main;
     const colorPie = [palette.primary.dark, bgSecondary, bgPrimary, palette.primary.light, lighten(bgSecondary, 0.50), lighten(bgPrimary, 0.50),
     palette.secondary.dark]
     const [transactionsType, setTransactionsType] = useState([]);

     
     const [typeTitle, setTypeTitle] = useState("");

     
     const getData = ( year, month, week) => {
          getTransactionTypeAnalytics(year, month, week).then((res) => {
               //console.log(res.data.data);
               const piedata = res.data.data;
               setTransactionsType(piedata ? piedata.map(item => ({
                    name: item.operationType.code,
                    value: item.amount
               })) : []);
          });
     }

     return (
          <Card sx={{ px: 3, py: 2, mb: 3 }}>
               <Title>Volume de transaction</Title>
               <SubTitle>par type d'opérations, {typeTitle}</SubTitle>
               <Grid container spacing={3}>
                    <Grid item lg={3} md={3} sm={4} xs={6} >
                         <br />
                         <FilterChart setTypeTitle={setTypeTitle} getData={getData} chooseType={false}/>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                         <DoughnutChart height="400px" bottom={0}
                              pieName="Opération" data={transactionsType} color={colorPie} center={["50%", "40%"]} />
                    </Grid>
               </Grid>
          </Card >
     )
}
export default TransactionTypeAnalytics;
