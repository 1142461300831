import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { dispatchUser } from 'app/redux/store';

//component
import { Container } from 'app/components/Content/Container';
import { Box, Tab } from '@mui/material';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import { SimpleCard, Breadcrumb } from "app/components";
import DetailUser from './DetailUser';
import Privilege from './Privilege';
import Password from './Password';

const User = () => {
     const { iduser } = useParams();
     const dispatch = useDispatch();
     const [value, setValue] = useState('1');
     const [loading, setLoading]=useState(false);
     const user = useSelector((state) => state.user.user);

     const handleChange = (event, newValue) => {
          setValue(newValue);
     };
     useEffect(() => {
          setLoading(true);
          dispatchUser(dispatch, iduser, setLoading);
     }, []);

     return (
          <Container>
               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={[{ name: 'Utilisateurs', path: '/users' }, { name: `${user.username}` }]}
                    />
               </Box>
               <SimpleCard title="Espace Utilisateurs">
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                         <TabContext value={value}>
                              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                   <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="Informations Générales" value="1" />
                                        <Tab label="Privilèges" value="2" />
                                        <Tab label="Mot de passe" value="3" />
                                   </TabList>
                              </Box>
                              <TabPanel value="1"><DetailUser /> </TabPanel>
                              <TabPanel value="2"><Privilege /></TabPanel>
                              <TabPanel value="3"><Password /></TabPanel>
                         </TabContext>
                    </Box>
               </SimpleCard>
          </Container>
     )
}
export default User;
