import { createContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import { MatxLoading } from 'app/components';

import { postCall, baseUrl } from 'app/services/Api';
const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false
};
const loginUrl = `${baseUrl}/login`;

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case 'LOGIN': {
      const user = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => { },
  logout: () => { }
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (username, password) => {
    await axios.post(loginUrl, null,{params:{ username: username, password: password }}).then((res) => {
      localStorage.setItem('user-token', res.data.data.token);
      localStorage.setItem('user-info', JSON.stringify(res.data.data));
      dispatch({ type: 'LOGIN', payload: res.data.data });
      Promise.resolve(res.data.data);
    }).catch((err) => {return Promise.reject(err)});
  };

  const logout = () => {
    localStorage.removeItem('user-token');
    localStorage.removeItem('user-info');
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await JSON.parse(localStorage.getItem('user-info'));
        if (data == null || data === undefined || Object.keys(data) === 0) {
          throw new Error('No user info');
        }
        dispatch({ type: 'INIT', payload: { isAuthenticated: true, user: data } });
      } catch (err) {
        console.error(err);
        dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
      }
    })();
  }, []);

  // SHOW LOADER
  if (!state.isInitialised) return <MatxLoading />;

  return (
    <AuthContext.Provider value={{ ...state, method: 'JWT', login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
