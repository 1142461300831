import { getCall, baseUrl, postCall } from "./Api"

export const getAllOperations=async(page, param={})=>{
     param={
          ...param,
          offset:(page),
          limit:10
     }
     return getCall(`${baseUrl}/cbs/operations`,true, param);
}
export const getOperationsForClient = async (clientUID, page, param = {}) => {
     param = {
          ...param,
          offset: (page),
          limit: 10
     }
     return getCall(`${baseUrl}/cbs/client/${clientUID}/operations`, true, param);
}

export const getOperationTypes = async () => {
     return getCall(`${baseUrl}/cbs/operations_types`, true);
}

export const deposit = (clientUID, data) => {
     return postCall(`${baseUrl}/cbs/client/${clientUID}/deposits`, data, true);
}
export const withdrawal = (clientUID, data) => {
     return postCall(`${baseUrl}/cbs/client/${clientUID}/withdrawals`, data, true);
}
export const transfer = (clientUID, data) => {
     return postCall(`${baseUrl}/cbs/client/${clientUID}/transfers`, data, true);
}

