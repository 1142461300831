import useAuth from 'app/hooks/useAuth';
import { Navigate, useLocation } from 'react-router-dom';
import { roleRoutes } from 'app/routes';

const AuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const { pathname } = useLocation();

  if (isAuthenticated) {
    //filtre les roles possibles pour le url 
    const roleRoute = roleRoutes.filter((role) => role.pathPattern.test(pathname));

    if (!roleRoute  || roleRoute.length===0) return <>{children}</>;
    else {
      //verifie si possède le role requis
      const hasRequiredRoles = user.roles.filter((role) => {
        return roleRoute[0].requiredRoles.includes(role || "");
      }
      );

      const regex=/\/client\/.*\/detail\/.*/
      if(pathname.startsWith("/user/") && (hasRequiredRoles === undefined || hasRequiredRoles.length === 0) )
        return <Navigate to="/user/profile" />;
      if((regex.test(pathname)) && (hasRequiredRoles === undefined || hasRequiredRoles.length === 0)){
        if(user.roles.includes("CLIENT")){
          return <Navigate to={pathname.slice(0, -1)+'1'} />;
        }else if(user.roles.includes("ACCOUNT")){
          return <Navigate to={pathname.slice(0, -1)+'2'} />;
        }else if(user.roles.includes("OPERATION")){
          return <Navigate to={pathname.slice(0, -1)+'3'} />;
        }
      }
      if (hasRequiredRoles === undefined || hasRequiredRoles.length === 0) {
        return <Navigate to="/unauthorized" />;
      } else {
        return <>{children}</>;
      }
    }
  }

  return <Navigate replace to="/login" state={{ from: pathname }} />;
};

export default AuthGuard;
