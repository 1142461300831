import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

//component
import { Grid, MenuItem, Button, DialogTitle, DialogContent, Dialog } from "@mui/material";
import ErrorDisplay from "app/components/Content/ErrorDisplay";
import { StyledTextField } from "app/components/Content/CustomInput";
import { TextFieldReadOnlyDisabled } from "app/components/Content/CustomInput";
import { LoadButton } from "app/components/Content/CustomLoadButton";
import SuccessDisplay from "app/components/Content/SuccessDisplay";

import { deposit, withdrawal } from "app/services/OperationService";
import { dispatchClientInfo } from "app/redux/store";

const validationSchema = Yup.object().shape({
     accountUID: Yup.string().required('Le choix du compte est requis'),
     label: Yup.string().required('Un libellé est requis'),
     amount: Yup.number().required('Le montant est requis').min(1, 'Le montant doit être supérieur à 0'),
});

const SingleOperation = ({ clientUID, type = "deposit" }) => {
     const dispatch = useDispatch();
     const urlParams = new URLSearchParams(window.location.search);
     const action = type === "deposit" ? "Faire un dépôt" : "Faire un retrait";
     const client = useSelector((state) => state.clientInfo.dataClient.client);
     const [loading, setLoading] = useState(false);
     const [messageError, setMessageError] = useState('');
     const [error, setError] = useState(false);
     const [success, setSuccess] = useState(false);

     const accounts = useSelector((state) => state.clientInfo.dataClient.accounts);

     const initialValues = {
          accountUID: urlParams.get('accountUID') || "",
          label: '',
          amount: urlParams.get('amount') || "",
     };
     const handleFormSubmit = async (values, actions) => {
          setLoading(true);
          setSuccess(false);
          try {
               type === "deposit" ?
                    await deposit(clientUID, values).then((res) => {
                         actions.resetForm({ values: initialValues });
                         done();
                    }) :
                    await withdrawal(clientUID, values).then((res) => {
                         actions.resetForm({ values: initialValues });
                         done();
                    });
          } catch (e) {
               setMessageError(e.response.data.message)
               setLoading(false);
               setError(true);
          }
     }
     const done = () => {
          setLoading(false);
          setError(false);
          setSuccess(true);
          dispatchClientInfo(dispatch, clientUID);
          handleClose();
     }

     const [open, setOpen] = useState(false);
     const handleOpen = () => setOpen(true);
     const handleClose = () => setOpen(false);
     return (
          <div>
               <SuccessDisplay open={success} setOpen={setSuccess} message={type === "deposit" ? "Dépôt réussi" : "Retrait réussi"} />

               <Formik
                    onSubmit={handleFormSubmit}
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    method="POST"
                    enableReinitialize
               >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                         <form onSubmit={handleSubmit}>
                              <Grid container spacing={6}>
                                   <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                        <TextFieldReadOnlyDisabled label="client" value={client.lastName + " " + client.firstNames} />
                                        <StyledTextField
                                             select
                                             size="small"
                                             name="accountUID"
                                             label="Compte"
                                             variant="outlined"
                                             onBlur={handleBlur}
                                             value={values.accountUID || ""}
                                             onChange={handleChange}
                                             helperText={touched.accountUID && errors.accountUID}
                                             error={Boolean(errors.accountUID && touched.accountUID)}
                                        >
                                             <MenuItem key='1' value={""}>
                                                  Choisir un compte
                                             </MenuItem>
                                             {accounts.map((option) => (
                                                  type === "deposit" ? option.accountType.acceptsDeposit ? <MenuItem key={option.accountUID} value={option.accountUID} >
                                                       {option.accountUID} (Solde, {option.balance} ar)
                                                  </MenuItem> : null : option.accountType.acceptsWithdrawal ? <MenuItem key={option.accountUID} value={option.accountUID} >
                                                       {option.accountUID} (Solde, {option.balance} ar)
                                                  </MenuItem> : null
                                             ))}
                                        </StyledTextField>

                                        <StyledTextField
                                             size="small"
                                             type="text"
                                             name="label"
                                             label="Libellé"
                                             variant="outlined"
                                             onBlur={handleBlur}
                                             value={values.label || ""}
                                             onChange={handleChange}
                                             helperText={touched.label && errors.label}
                                             error={Boolean(errors.label && touched.label)}
                                        />
                                        <StyledTextField
                                             size="small"
                                             type="number"
                                             name="amount"
                                             label="Montant"
                                             variant="outlined"
                                             onBlur={handleBlur}
                                             value={values.amount || ""}
                                             onChange={handleChange}
                                             helperText={touched.amount && errors.amount}
                                             error={Boolean(errors.amount && touched.amount)}
                                        />
                                   </Grid>

                              </Grid>

                              <Button color="primary" variant="contained" onClick={handleOpen}>{action}</Button>
                              <Dialog onClose={handleClose} aria-labelledby="form-dialog-title" open={open}>
                                   <DialogContent dividers>
                                        <DialogTitle id="form-dialog-title" onClose={handleClose} component="div">
                                             Etes-vous sûr de vouloir faire ce {type === "deposit" ? "dépôt" : "retrait"} ?
                                        </DialogTitle>
                                        <div style={{ display: "flex" }}>
                                             <LoadButton loading={loading} onClick={handleSubmit}>Valider</LoadButton>
                                             <Button color="error" variant="contained" sx={{my:2, marginLeft:"auto"}} onClick={handleClose}>Annuler</Button>
                                        </div>
                                   </DialogContent>
                              </Dialog>


                              <ErrorDisplay error={error} messageError={messageError} />
                         </form>
                    )}
               </Formik>
          </div>
     )

}
export default SingleOperation;
