import {Box, Typography, Grid, Switch } from '@mui/material';
import './dashboard-client.css';

import { formattedNumber } from 'app/services/Utils';

const AccountCard = ({account, bg = "white", }) => {
     return (
          <Grid item lg={5} md={5} sm={6} xs={7} className="account-card-placement" >
               <Box sx={{ background: bg, borderRadius: "5px", padding: "15px 15px 0px" }}>
                    <div className="account-card-action">
                         <Typography level="title-md" >{account?.accountTypeName} ({account?.accountType?.code})</Typography>
                    </div>
                    <br />
                    <Typography level="body-sm" align="center" className="account-solde">{formattedNumber(account?.balance)} Ar</Typography>
                    <br />
                    <Typography level="title-md" align="center">{account.accountUID}</Typography>
                    <br />
                    <div className="account-card-action">
                         <Typography >{account.statusCode}</Typography>
                         <Typography level="title-md" ><Switch
                              checked={account.isEnabled}
                              color="secondary"
                         /></Typography>
                    </div>
               </Box>
          </Grid>
     )
}
export default AccountCard;
