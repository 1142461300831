import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Accordion, AccordionDetails, AccordionSummary, Typography, Button, Box, styled, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingContainer from "app/components/Content/LoadingContainer";
import ClientInfo from "./ClientInfo";
import { Container } from 'app/components/Content/Container';
import { SimpleCard, Breadcrumb } from "app/components";
import SimpleTable from "app/components/table/SimpleTable";
import ClientStatus from "app/components/client/ClientStatus";
import AccountStatus from "app/components/account/AccountStatus";
import ClientAction from "app/components/client/ClientAction";
import { H4 } from 'app/components/Typography';

import { formattedNumber, formatDate } from "app/services/Utils";
import CardClientValidation from "app/components/client/CardClientValidation";
import HistoryClientValidation from "app/components/client/HistoryClientValidation";
import { dispatchClientInfo } from "app/redux/store";
import { getFirstDepositAmount } from "app/services/SettingService";

const PhaseClient = () => {
     const { clientUID } = useParams();
     const dispatch = useDispatch();
     const clientInfo = useSelector((state) => state.clientInfo.dataClient);
     const [firstVersement, setFirstVersement] = useState(0);

     const [loadingData, setLoadingData] = useState(false);
     const [expanded, setExpanded] = useState(true);

     useEffect(() => {
          dispatchClientInfo(dispatch);
          setLoadingData(true);
          dispatchClientInfo(dispatch, clientUID, setLoadingData);
          getFirstDepositAmount().then((res) => {
               setFirstVersement(res.data.data.value);
          });
     }, []);
     const columns = [
          {
               name: 'Numéro de compte',
               selector: (row) => row.accountUID,
               style: { width: '200px' }
          },
          {
               name: 'Client',
               selector: (row) => row.clientName,
               style: { width: '200px' }
          },
          {
               name: 'Solde',
               selector: (row) => (<>{formattedNumber(row.balance)} Ar</>),
               style: { width: '100px' }
          },
          {
               name: 'Modification solde',
               selector: (row) => row.balanceModified ? formatDate(new Date(row.balanceModified)) : null,
               style: { width: '200px' }
          },
          {
               name: 'Statut',
               selector: (row) => (<AccountStatus code={row.statusCode}></AccountStatus>),
               style: { width: '100px' }
          },
          {
               name: 'Type de compte',
               selector: (row) => (row.accountTypeName),
               style: { width: '200px' }
          }
     ];

     return (
          <Container>
               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={[{ name: 'Clients' + (clientInfo.client.isEnabled === false ? ' non activés' : ''), path: '/clients' + (clientInfo.client.isEnabled === false ? 'disabled' : '') }, { name: clientInfo.client.lastName + " " + clientInfo.client.firstNames, path: clientInfo.client.isEnabled === true ? '/client/' + clientUID + '/detail/1' : null }, { name: "Phase de validation" }]}
                    />
               </Box>
               {clientInfo.client.statusCode === "DISABLED" && clientInfo.client.feeAccountUID == null ?
                    <><Box
                         sx={{
                              display: 'flex',
                              flexDirection: 'row-reverse',
                              borderRadius: 1,
                         }}
                    >
                         <ClientAction colorButton="secondary" messageSuccess="Processus réinitialisé" codeAction="REINITIALIZE_PROCESS" button="Réinitialiser processus"
                               clientUID={clientUID} actionButton="Confirmer" >Etes vous sûr de vouloir réinitialiser le processus?</ClientAction>
                         
                    </Box><br /></> : null
               }


               <Accordion style={{ "borderRadius": '5px', "boxShadow": "none", "&:before": { display: "none" } }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                         <Typography>Informations du client : {clientInfo.client.lastName} {clientInfo.client.firstNames} </Typography>
                         <Typography sx={{ marginLeft: "20px" }} ><ClientStatus code={clientInfo.client.statusCode} /></Typography>

                    </AccordionSummary>
                    <AccordionDetails >
                         <LoadingContainer loading={loadingData}>
                              <ClientInfo client={clientInfo?.client} />
                         </LoadingContainer>
                    </AccordionDetails>
               </Accordion >
               <br />

               <SimpleCard title={`Compte par défaut créé ${clientInfo.firstAccount?.accountUID || ""}`}>
                    {clientInfo.firstAccount == null ? <Typography>Aucun compte par défaut</Typography> : <SimpleTable data={[clientInfo.firstAccount]} columns={columns} loading={loadingData}></SimpleTable>}
               </SimpleCard>

               <br />
               <SimpleCard title="Phase de validations">
                    <Grid container spacing={3}>
                         <Grid item lg={6} md={6} sm={6} xs={6} sx={{ mt: 2 }}>
                              <H4>Actions</H4>
                              <br />
                              <CardClientValidation icon="fiber_new" step="Création du client" >
                                   <Grid container spacing={1}>
                                        {clientInfo.client.statusCode === "CREATED" ?
                                             (<>
                                                  <Grid item lg={6} md={6} sm={0} xs={0} ></Grid>
                                                  <Grid item lg={6} md={6} sm={12} xs={12}>
                                                       <ClientAction colorButton="error" messageSuccess="Client désactivé" codeAction="CANCELLED_CREATION_CLIENT" button="Annuler"
                                                            title="Annuler la création de ce client, le désactiver" clientUID={clientUID} actionButton="Confirmer l'annulation" /></Grid>
                                             </>) : null}
                                   </Grid>
                              </CardClientValidation>

                              <CardClientValidation icon="fiber_new" step="Création du compte par défaut" >
                                   {clientInfo.client.statusCode === "CREATED" ?
                                        (<Grid container spacing={1}>
                                             <Grid item lg={6} md={6} sm={12} xs={12}  >
                                             <ClientAction messageSuccess="Compte par défaut créé" button="Créer" codeAction="FIRST_ACCOUNT" title="Créer un compte par défaut, qui sera le compte de frais" clientUID={clientUID} actionButton="Confirmer la création" >
                                                  Le compte créé sera un compte courant par défaut.
                                             </ClientAction>
                                             </Grid>
                                             <Grid item lg={6} md={6} sm={0} xs={0} ></Grid>
                                        </Grid>) : null}
                                   {(clientInfo.client.statusCode === "FIRST_ACCOUNT" && clientInfo.firstAccount != null) ?
                                        clientInfo.firstAccount.statusCode === "CREATED" && clientInfo.firstAccount.balance < 1 ?
                                             (<Grid container spacing={1}>
                                                  <Grid item lg={6} md={6} sm={0} xs={0} ></Grid>

                                                  <Grid item lg={6} md={6} sm={12} xs={12}  > <ClientAction colorButton="error" button="Annuler" messageSuccess="Annulation réussi" codeAction="CANCELLED_FIRST_ACCOUNT" title="Annuler la création du compte" clientUID={clientUID} accountUID={clientInfo.firstAccount.accountUID} actionButton="Confirmer l'annulation du compte" />
                                                  </Grid>
                                             </Grid>) : null : null
                                   }
                              </CardClientValidation>

                              <CardClientValidation icon="attach_money" step="Premier dépôt du compte" >
                                   {(clientInfo.client.statusCode === "FIRST_ACCOUNT" && clientInfo.firstAccount != null) ?
                                        (clientInfo.firstAccount.statusCode === "CREATED" && clientInfo.firstAccount.balance < 1) ?
                                             (<Grid container spacing={1}>
                                                  <Grid item lg={6} md={6} sm={12} xs={12}  >
                                                       <ClientAction messageSuccess="Premier dépôt effectué avec succès" button="Dépôt" codeAction="FIRST_DEPOSIT" title="Faire le premier dépôt sur le compte par défaut" clientUID={clientUID} accountUID={clientInfo.firstAccount.accountUID} actionButton="Confirmer le dépôt" firstDeposit={true}>
                                                            Le premier dépôt est de {firstVersement} Ar minimum.        
                                                       </ClientAction>
                                                  </Grid>
                                                  <Grid item lg={6} md={6} sm={0} xs={0} ></Grid>
                                             </Grid>) : null : null
                                   }
                                   {(clientInfo.client.statusCode === "DISABLED" && clientInfo.firstAccount != null) ?
                                        (clientInfo.firstAccount.statusCode === "CREATED" && clientInfo.firstAccount.balance > 0) ?
                                             (<Grid container spacing={1}>
                                                  <Grid item lg={6} md={6} sm={0} xs={0} ></Grid>
                                                  <Grid item lg={6} md={6} sm={12} xs={12}  >
                                                       <ClientAction colorButton="error" messageSuccess="Annulation du dépôt" button="Annuler" codeAction="CANCELLED_FIRST_DEPOSIT" title="Annuler le dépôt" clientUID={clientUID} account accountUID={clientInfo.firstAccount.accountUID} actionButton="Annuler le dépôt" >
                                                            Le compte principale va être créditer de {clientInfo.firstAccount.balance} Ar
                                                       </ClientAction>
                                                  </Grid>
                                             </Grid>) : null : null
                                   }
                              </CardClientValidation>
                              <CardClientValidation icon="priority_high" step="Validation du client" >
                                   {(clientInfo.client.statusCode === "FIRST_ACCOUNT" && clientInfo.firstAccount != null) ?
                                        clientInfo.firstAccount.statusCode === "CREATED" && clientInfo.firstAccount.balance > 0 ?
                                             (<Grid container spacing={1}>
                                                  <Grid item lg={6} md={6} sm={12} xs={12}>
                                                       <ClientAction colorButton="primary" button="Valider" messageSuccess="Le client a été validé" codeAction="APPROVED" title="Valider la création du client et de son compte" clientUID={clientUID} actionButton="Confirmer la validation" />
                                                  </Grid>
                                                  <Grid item lg={6} md={6} sm={12} xs={12}>
                                                       <ClientAction colorButton="error" button="Refuser" messageSuccess="Le client a été refusé" codeAction="REFUSED" title="Refuser la création du client et de son compte" clientUID={clientUID} actionButton="Confirmer le refus" />
                                                  </Grid>
                                             </Grid>)
                                             : null : null}
                              </CardClientValidation>
                         </Grid>
                         <Grid item lg={6} md={6} sm={6} xs={6} sx={{ mt: 2 }}>
                              <H4>Historique</H4>
                              <br />
                              {clientInfo.validationClients.map((validation, index) => {
                                   return (<HistoryClientValidation validationClient={validation} key={index} ></HistoryClientValidation>)
                              })}
                         </Grid>
                    </Grid>
               </SimpleCard>
          </Container>
     )
}
export default PhaseClient;
