import { Box, Icon, IconButton, Typography, Button } from '@mui/material';
import ClientStatus from './ClientStatus';
import { formatDate } from 'app/services/Utils';

const HistoryClientValidation = ({ validationClient }) => {
     return (
          <Box sx={{
               backgroundColor: "white",
               borderRadius: "10px",
               boxShadow: "3px 3px 3px rgba(200,200,200,0.5)",
               padding: "10px",
               marginBottom: "10px",
               overflowWrap: "break-word",
               wordWrap: "break-word",
               wordBreak: "break-all",
          }}>
               <div className="account-card-action">
                    <Typography level="title-md" sx={{ fontSize: '12px' }}>{validationClient ? formatDate(new Date(validationClient?.dateTime)) : null}</Typography>

                    <Typography level="title-md" >
                         <ClientStatus code={validationClient.statusCode} />
                    </Typography>
               </div>
               <p>{validationClient.comments}</p>
               <div className="account-card-action">
                    <Typography level="title-md" >par l'utilisateur {validationClient.username}</Typography>
               </div>
          </Box >
     );
}
export default HistoryClientValidation;
