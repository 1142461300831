import { format } from 'date-fns'

//format Jour mois en lettre année, heure:minute
export const formatDate = (date) => {
     return new Intl.DateTimeFormat('fr-FR', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
     }).format(date);
}
//format YYYY-MM-DD HH:MM:SS
export const formatCompleteDate = (date) => {
     if (date === undefined || date === null) {
          return date;
     }
     let dateComplete = date;

     if (date.constructor === undefined || date.constructor !== Date) {
          dateComplete = new Date(date);
     }
     return new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
     }).format(dateComplete);
}
export const formatOnlyDate = (date) => {
     if (date === undefined || date === null) {
          return date;
     }
     if (date.constructor === undefined || date.constructor !== Date) {
          date = new Date(date);
     }
     return format(date, 'yyyy-MM-dd');
}
export const formattedNumber = (amount) => {
     if (amount === undefined || amount === null) {
          return 0;
     }
     // console.log(amount)
     return new Intl.NumberFormat('fr-FR', {
          useGrouping:true,
     }).format(amount);
}
//french date
export function transformFrenchDate(dateString) {
     const regex = /^\d{4}-\d{2}-\d{2}$/;
     if (dateString === undefined || !regex.test(dateString)) {
          return dateString;
     }
     const date = new Date(dateString);
     const options = { day: "numeric", month: "long", year: "numeric" };
     const formattedDate = date.toLocaleDateString("fr", options);

     return formattedDate;
}
export function getLastDayOfMonth(yearMonthString) {
     const [year, month] = yearMonthString.split("-");
     const lastDay = new Date(year, month, 0).getDate();
     const formattedDate = `${yearMonthString}-${lastDay.toString().padStart(2, "0")}`;
     return formattedDate;
}

export const transformFrenchMonth = (inputMonth) => {
     const regex = /^\d{4}-\d{2}$/;
     if (inputMonth === undefined || !regex.test(inputMonth)) {
          return inputMonth;
     }
     const [year, month] = inputMonth.split("-");
     const date = new Date(year, month - 1);
     const options = { month: "long", year: "numeric" };
     const formattedDate = date.toLocaleDateString("fr", options);
     return formattedDate;
}
