import { api } from 'app/contexts/ApiContext';
export const baseUrl = process.env.REACT_APP_CBS_URL;

console.log("baseUrl", baseUrl);
export const getCall = (url, auth = false, params = {}) => {
     let config = { params }
     if (auth) {
          config.headers = { Authorization: "Bearer " + localStorage.getItem('user-token') };
     }
     //console.log(config);
     return api
          .get(url, config)
          .then((res) => (Number.parseInt(res.status / 100) === 2 ? res : Promise.reject(res)))
          .then((res) => res)
}

export const postCall = (url, data, auth = false, params = {}) => {
     let config = { params }
     if (auth) {
          config.headers = { Authorization: `Bearer ${localStorage.getItem('user-token')}` }
     }
     return api
          .post(url, data, config)
          .then((res) => (Number.parseInt(res.status / 100) === 2 ? res : Promise.reject(res)))
          .then((res) => res)
}
export const putCall = (url, data, auth = false, params = {}) => {
     let config = { params }
     if (auth) {
          config.headers = { Authorization: `Bearer ${localStorage.getItem('user-token')}` }
     }
     return api
          .put(url, data, config)
          .then((res) => (Number.parseInt(res.status / 100) === 2 ? res : Promise.reject(res)))
          .then((res) => res.data)
}
export const deleteCall = (url, data, auth = false, params = {}) => {
     let config = { params }
     if (auth) {
          config.headers = { Authorization: `Bearer ${localStorage.getItem('user-token')}` }
     }
     return api
          .delete(url, data, config)
          .then((res) => (Number.parseInt(res.status / 100) === 2 ? res : Promise.reject(res)))
          .then((res) => res.data)
}
export const downloadFile=(url, auth = false, params = {}) => {
     let config = { params }
     config.responseType= 'blob';
     if (auth) {
          config.headers = { Authorization: `Bearer ${localStorage.getItem('user-token')}` }
     }
     return api
          .get(url, config)
          .then((res) => (Number.parseInt(res.status / 100) === 2 ? res : Promise.reject(res)))
          .then((res) => res.data)
}
