import Small from 'app/components/Content/Small';
import { useTheme } from '@mui/material';
const statusColor = (palette) => {
     return {
          "CREATED": [palette.third.main,palette.primary.main],
          'FIRST_ACCOUNT': [palette.secondary.main, palette.secondary.main],
          'ENABLED': [palette.success.main, palette.success.main],
          'DISABLED': [palette.error.main,  palette.error.main],
          'BLOCKED':[palette.error.main, palette.error.main]
     }
}
const ClientStatus = ({ code, gameColor=0 }) => {
     const { palette } = useTheme();
     const color = statusColor(palette);
     const findColor = code ? color[code]?color[code][gameColor] : palette.primary.main: palette.primary.main;
     if (color) {
          return <Small bgcolor={findColor}>{code}</Small>
     } else {
          return <></>
     }
}
export default ClientStatus;

