import AuditLogs from "./AuditLogs";

const AcountLogs=()=>{
     return (
          <AuditLogs log="account" title="Log des processus de validation de client et des premiers comptes" 
               routeSegment={[{ name: 'Logs'},{ name: 'Log des processus de création de compte', path: '/logs/account' }]}
          />
     )
}
export default AcountLogs;
