import { useState } from "react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from "react-redux";
import { dispatchUser } from "app/redux/store";

//component
import { LoadButton } from "app/components/Content/CustomLoadButton";
import { Grid, FormControlLabel, Switch } from "@mui/material";
import ErrorDisplay from "app/components/Content/ErrorDisplay";
import { TextFieldReadOnlyDisabled, StyledTextField } from "app/components/Content/CustomInput";
import MGPhoneInput from "app/components/Content/MGPhoneInput";
import SuccessDisplay from "app/components/Content/SuccessDisplay";
import LoadingContainer from "app/components/Content/LoadingContainer";

import { updateUserInfo } from "app/services/UserService";
import { formatCompleteDate } from "app/services/Utils";

const validationSchema = Yup.object().shape({
     username: Yup.string().required("L'identifiant est obligatoire"),
     name: Yup.string().required('Le nom est obligatoire'),
     email: Yup.string().email('L\'email est invalide').required('L\'email est obligatoire'),
     telephone: Yup.string(),
     isEnabled: Yup.boolean().required('Le statut est obligatoire'),
});
const DetailUser = ({ loadingData = false }) => {
     const dispatch = useDispatch();
     const user = useSelector((state) => state.user.user);
     const initialValues = {
          ...user,
          isEnabled: user.isEnabled,
     }
     const [loading, setLoading] = useState(false);
     const [messageError, setMessageError] = useState('');
     const [error, setError] = useState(false);
     const [success, setSuccess] = useState(false);

     const handleFormSubmit =(values) => {
          setLoading(true);
          setSuccess(false);
          updateUserInfo(values, user).then((res) => {
               setLoading(false);
               setError(false);
               dispatchUser(dispatch, user.iduser);
               setSuccess(true);
          }).catch((e) => {
               setMessageError(e.response.data.message)
               setLoading(false);
               setError(true);
          });
     }

     return (
          <div>
               <SuccessDisplay open={success} setOpen={setSuccess} message="Modification enregistrée" />
               <LoadingContainer loading={loadingData}>
                    <Formik
                         onSubmit={(values) => {
                              handleFormSubmit(values)
                         }}
                         validationSchema={validationSchema}
                         initialValues={initialValues}
                         method="POST"
                         enableReinitialize
                    >
                         {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldError }) => (
                              <form onSubmit={handleSubmit}>
                                   <Grid container spacing={6}>
                                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                             <StyledTextField
                                                  size="small"
                                                  type="text"
                                                  name="username"
                                                  label="Identifiant"
                                                  variant="outlined"
                                                  onBlur={handleBlur}
                                                  value={values.username || ""}
                                                  onChange={handleChange}
                                                  helperText={touched.username && errors.username}
                                                  error={Boolean(errors.username && touched.username)}
                                             />
                                             <StyledTextField
                                                  size="small"
                                                  type="text"
                                                  name="name"
                                                  label="Nom complet"
                                                  variant="outlined"
                                                  onBlur={handleBlur}
                                                  value={values.name || ""}
                                                  onChange={handleChange}
                                                  helperText={touched.name && errors.name}
                                                  error={Boolean(errors.name && touched.name)}
                                             />
                                             <MGPhoneInput name="telephone" onChange={(event) => {
                                                  setFieldValue("telephone", event);
                                             }}
                                                  value={values.telephone || ""}
                                                  onBlur={handleBlur}
                                                  helperText={touched.telephone && errors.telephone}
                                                  error={Boolean(errors.telephone && touched.telephone)}
                                                  touched={touched}
                                                  withValidation={true}
                                             />
                                             <StyledTextField
                                                  size="small"
                                                  type="text"
                                                  name="email"
                                                  label="Email"
                                                  variant="outlined"
                                                  onBlur={handleBlur}
                                                  value={values.email || ""}
                                                  onChange={handleChange}
                                                  helperText={touched.email && errors.email}
                                                  error={Boolean(errors.email && touched.email)}
                                             />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                             <FormControlLabel
                                                  label="Activé ?"
                                                  control={
                                                       <Switch
                                                            name="isEnabled"
                                                            value={"enabled"}
                                                            checked={values.isEnabled || false}
                                                            onChange={(event) => { setFieldValue("isEnabled", event.target.checked) }}
                                                            inputProps={{ "aria-label": "secondary checkbox" }} color="success"
                                                       />} />
                                             <TextFieldReadOnlyDisabled label="Date de création"
                                                  type="date-time"
                                                  value={formatCompleteDate(values.createdDate) || ""}
                                             />
                                             <TextFieldReadOnlyDisabled label="Date de modification"
                                                  type="date-time"
                                                  value={formatCompleteDate(values.updatedDate) || ""}
                                             />
                                        </Grid>

                                   </Grid>
                                   <LoadButton loading={loading} type="submit">Modifier</LoadButton>

                                   <ErrorDisplay error={error} messageError={messageError} />
                              </form>
                         )}
                    </Formik>
               </LoadingContainer>
          </div>
     );
};

export default DetailUser;
