import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Container } from 'app/components/Content/Container';
import { Grid, styled, useTheme, Box, Typography } from '@mui/material';
import Breadcrumb from 'app/components/Breadcrumb';
import StatCard from 'app/components/dashboard/StatCard';
import BarEchart from 'app/components/dashboard/BarEchart';
import FilterChart from '../dashboard/FilterChart';

import { getGlobalAnalyticsGateway, getCurrentWeek, getTransactionAnalyticsGateway } from 'app/services/AnalyticsService';
import { formattedNumber } from 'app/services/Utils';

const ContentBox = styled('div')(({ theme }) => ({
     margin: '30px',
     [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const DashBoardApiKey = () => {
     const { gatewayReference } = useParams();
     const { palette } = useTheme();
     const [totalClient, setTotalClient] = useState(0);
     const [totalAmountTransaction, setTotalAmountTransaction] = useState(0);
     const [totalNumberTransaction, setTotalNumberTransaction] = useState(0);

     const [transactions, setTransactions] = useState([]);
     const year = new Date().getFullYear();

     useEffect(() => {
          getGlobalAnalyticsGateway(gatewayReference).then((res) => {
               //console.log(res.data.data);
               setTotalClient(res.data.data.totalClient);
               setTotalAmountTransaction(res.data.data.totalAmountTransaction);
               setTotalNumberTransaction(res.data.data.totalNumberTransaction);
          });
     }, []);

     const [typeTitle, setTypeTitle] = useState("");

     const getData = (type, startDate, endDate) => {
          getTransactionAnalyticsGateway(gatewayReference, type, startDate, endDate).then((res) => {
               // console.log(res.data.data);
               const barData = res.data.data;
               setTransactions(barData ? barData.map(item => (
                    [item.date, item.amount]
               )) : []);
          });
     }
     return (
          <div>
               <Container>
                    <Box className="breadcrumb">
                         <Breadcrumb
                              routeSegments={[{ name: 'Clé apis', path: '/apikeys' }, {name:gatewayReference}, { name: 'Statistiques' }]}
                         />
                    </Box>
               </Container>
               <Fragment>
                    <ContentBox className="analytics">
                         <Grid container spacing={3} sx={{ mb: '24px' }}>
                              <Grid item xs={12} sm={4} md={4}  >
                                   <StatCard statName="Clients actifs" statCount={formattedNumber(totalClient) || 0} icon="group" color={[17, 105, 88]}  />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}  >
                                   <StatCard statName="Volume de transactions"
                                        statCount={(formattedNumber(totalAmountTransaction) || 0) + " ar"}
                                        percentage={year} icon="swap_horiz"
                                        color={[240, 182, 5]} />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}  >
                                   <StatCard
                                        statName="Nombre de transactions"
                                        statCount={totalNumberTransaction || 0}
                                        icon="view_comfy" color={[71, 186, 163]}
                                   />
                              </Grid>
                         </Grid>
                    </ContentBox>
                    <ContentBox className="analytics">
                         <Box sx={{backgroundColor:'white', borderRadius:'5px', padding:'20px'}}>
                              <Typography>Volume de transactions, {typeTitle}</Typography>
                              <Grid container spacing={3}>
                                   <Grid item lg={12} md={12} sm={12} xs={12} >
                                        <br />
                                        <FilterChart setTypeTitle={setTypeTitle} getData={getData} inline={true} />
                                   </Grid>
                              </Grid>
                              <BarEchart title="Volume de transactions" height="600px" color={[palette.primary.dark, palette.primary.light]}
                                   legend={["temps", "Volume transaction"]} data={transactions} />
                         </Box>
                    </ContentBox>
               </Fragment>
          </div>
     );
}
export default DashBoardApiKey;
