import { Card, Fab, Grid, Icon, lighten, styled, useTheme } from '@mui/material';

const ContentBox = styled('div')(() => ({
     display: 'flex',
     flexWrap: 'wrap',
     alignItems: 'center',
     marginBottom: '10px',
}));
const FabIcon = styled(Fab)(() => ({
     width: '44px !important',
     height: '44px !important',
     boxShadow: 'none !important',
}));
const H4 = styled('h4')(({ textcolor }) => ({
     margin: 0,
     color: textcolor,
     fontWeight: '500',
     marginLeft: '12px',
}));
const Span = styled('span')(({ textcolor }) => ({
     fontSize: '13px',
   }));
const StatCardClient = ({ title="", value="", icon, color = [0, 0, 0] }) => {
     const baseColor=`rgb(${color[0]}, ${color[1]}, ${color[2]})`;
     const colorLight = `rgb(${color[0]}, ${color[1]}, ${color[2]}, 0.15)`;
     return (
          <ContentBox>
               <FabIcon size="medium" sx={{ background: colorLight }}>
                    <Icon sx={{ color: baseColor }}>{icon}</Icon>
               </FabIcon>
               <H4 textcolor={baseColor}>{title+" :"}&nbsp;</H4><Span>{value}</Span>
          </ContentBox>
     )

}
export default StatCardClient;
