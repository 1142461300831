import {
     styled, TextField
} from "@mui/material";

export const StyleTextField = styled(TextField)(({ theme }) => ({
     margin: theme.spacing(1),
     width: "100%",
     autoComplete: "off"
}));
export const StyledTextField=(props)=>{
     return(
          <StyleTextField
          autoComplete="off"
          {...props}
          />
     )
}
export const TextFieldReadOnlyDisabled = ({ label, value = "", type = "text", ...props }) => {
     return (

          <StyledTextField
               size="small"
               type={type}
               label={label}
               variant="outlined"
               value={value}
               disabled
               InputLabelProps={{ shrink: true }}
               autoComplete="off"
               {...props}
          />
     );
}
export const TextFieldReadOnly = ({ label, value = "", type = "text", ...props }) => {
     return (

          <StyledTextField
               size="small"
               type={type}
               label={label}
               variant="outlined"
               value={value}
               InputLabelProps={{ shrink: true }}
               autoComplete="off"
               {...props}
          />
     );
}
