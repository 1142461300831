import {styled} from '@mui/material';

const Small = styled('small')(({ bgcolor }) => ({
     // width: 50,
     height: 20,
     color: '#fff',
     padding: '2px 8px',
     borderRadius: '4px',
     overflow: 'hidden',
     background: bgcolor,
     boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
}));
export default Small;
