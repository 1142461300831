import PropTypes from 'prop-types'
import { Box, styled, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, CircularProgress } from "@mui/material";


const StyledTable = styled(Table)(() => ({
     whiteSpace: "pre",
     "& thead": {
          "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
     },
     "& tbody": {
          "& tr": { "& td": { paddingLeft: 0 } },
     },
}));

const SimpleTable = (
     {
          data, columns, title, selectable, linkFunction, handleRowSelection, contextActions, selectableRowDisabled,
          clearRow, loading = false }) => {
     if (loading) return <Box width="100%" align="center"><CircularProgress className="progress" /></Box>
     else
          return (
               <Box width="100%" overflow="auto">
                    <StyledTable>
                         <TableHead>
                              <TableRow>
                                   {columns.map((column, index) => {
                                        return (
                                             <TableCell key={index} align={column.align ? column.align : "left"} children={column.name} sx={column.style}>
                                             </TableCell>
                                        );
                                   })}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {data.map((row, index) => (
                                   <TableRow key={index}>
                                        {columns.map((column, i) => {
                                             return (
                                                  <TableCell key={i} align={column.align ? column.align : "left"} sx={column.style} >
                                                       {column.selector(row)}
                                                  </TableCell>
                                             );
                                        })}
                                   </TableRow>
                              ))}
                         </TableBody>
                    </StyledTable>
               </Box>
          );
};
export default SimpleTable;
SimpleTable.propTypes = {
     data: PropTypes.array.isRequired,
     columns: PropTypes.array.isRequired,
     title: PropTypes.string,
     selectable: PropTypes.bool,
     linkFunction: PropTypes.func,
     handleRowSelection: PropTypes.func,
     contextActions: PropTypes.object,
     selectableRowDisabled: PropTypes.func,
     clearRow: PropTypes.bool,
     loading: PropTypes.bool
}

