import React from 'react';
import useAuth from 'app/hooks/useAuth';

const PrivilegeContent = ({ roles = [],alt=null,  children }) => {
     const { user } = useAuth();
     let bool = false;
     if (roles.length === 0) bool = true;
     else {
          for (let i = 0; i < roles.length; i++) {
               if (user.roles.includes(roles[i])) {
                    bool = true;
                    break;
               }
          }
     }
     if(bool && children){
          return children;
     }else{
          return alt;
     }
}
export default PrivilegeContent;
