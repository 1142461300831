
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//component
import { Icon, IconButton, TextField, Box, MenuItem, Button } from "@mui/material";
import { SimpleCard, Breadcrumb } from "app/components";
import { PaginationTable } from "app/components/table/PaginationTable";
import { Container } from 'app/components/Content/Container';
import Filter from "app/components/Content/Filter";
import FormClient from 'app/views/clients/FormClient';
import ClientStatus from "app/components/client/ClientStatus";
import SuccessDisplay from "app/components/Content/SuccessDisplay";
import PrivilegeContent from 'app/components/Content/PrivilegeContent';

import { findAllClients, getClientStatus } from "app/services/ClientService";
import { updateClient } from "app/services/ClientService";

const ClientsDisabled = () => {
     const navigate = useNavigate();
     const [searchParam, setSearchParam] = useState({});
     const [clients, setClients] = useState([]);
     const [loading, setLoading] = useState(false);
     const [page, setPage] = useState(0);
     const [status, setStatus] = useState([]);
     const [totalClient, setTotalClient] = useState(0);

     const [messageUpdate, setMessageUpdate] = useState('');
     const [severity, setSeverity] = useState("success");
     const [open, setOpen] = useState(false);

     const getClients = (paramPage = null) => {
          setLoading(true);
          try {
               findAllClients(searchParam, paramPage == null ? page : paramPage, false).then((response) => {
                    setClients(response.data.data.list);
                    setTotalClient(response.data.data.total);
                    setLoading(false);
               });
          } catch (e) {
               setLoading(false);
               console.error(e);
          }
     }
     const unblock = (client) => {
          setSeverity("success");
          const data = { ...client, statusCode: 'ENABLED' };
          updateClient(data, client.clientUID).then((res) => {
               getClients();
               setMessageUpdate("Client débloqué");
               setOpen(true);
          }).catch((err) => {
               console.error(err);
               setMessageUpdate(err.response.data.message);
               setSeverity("error");
               setOpen(true);
          })
     }
     const handleSearch = () => {
          getClients(0);
     }
     const handleSearchChange = (event, param) => {
          setSearchParam({ ...searchParam, [param]: event.target.value });

     }
     const handleChangePage = (_, newPage) => {
          newPage === 1 ? setPage(page + 1) : setPage(page - 1);
          getClients(newPage === 1 ? (page + 1) : (page - 1));
     };
     useEffect(() => {
          try {
               getClients();
               getClientStatus().then((response) => {
                    setStatus(response.data.data);
               });
          } catch (error) {
               console.error(error);
          }
     }, [page]);
     const columns = [
          {
               name: 'Nom',
               selector: (row) => row.lastName,
               style: { width: '100px' }
          },
          {
               name: 'Prénom(s)',
               selector: (row) => row.firstNames,
               style: { width: '100px' }
          },
          {
               name: 'Téléphone',
               selector: (row) => row.telephone,
               style: { width: '110px' }
          },
          {
               name: 'Email',
               selector: (row) => row.email,
               style: { width: '200px' }
          },
          {
               name: 'Statut',
               selector: (row) => (<><div><ClientStatus code={row.statusCode} /></div>{row.feeAccountUID != null && row.statusCode==="DISABLED"? "(retirer le dépôt)" : ""}</>),
               style: { width: '150px' }
          },
          {
               name: 'Validation',
               selector: (row) => (<IconButton onClick={() => navigate(`/client/${row.clientUID}/validations`)}>
                    <Icon color="secondary">compare_arrows</Icon>
               </IconButton>),
               style: { width: '100px' }
          },
          {
               name: 'Débloquer',
               selector: (row) => row.statusCode === "BLOCKED" ? <Button color="error" variant="contained" onClick={() => unblock(row)}>
                    Débloquer
               </Button> : null,
               style: { width: '120px' }
          }
     ];
     return (
          <Container>
               <SuccessDisplay open={open} setOpen={setOpen} severity={severity} message={messageUpdate} />

               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={[{ name: 'Clients non activés', path: '/clients' }]}
                    />
               </Box>

               <PrivilegeContent roles={["ADMIN", "BASIC_USER", "CLIENT"]}>
                    <Box
                         sx={{
                              display: 'flex',
                              flexDirection: 'row-reverse',
                              borderRadius: 1,
                         }}
                    >
                         <FormClient />
                    </Box>
               </PrivilegeContent>
               <br />
               <Filter handleSearch={handleSearch}>
                    <TextField id="outlined-basic" label="Recherche" variant="outlined" value={searchParam.search || ""} onChange={(event) => handleSearchChange(event, "search")} />
                    <TextField id="outlined-basic" label="Téléphone" variant="outlined" value={searchParam.telephone || ""} onChange={(event) => handleSearchChange(event, "telephone")} />
                    <TextField id="outlined-basic" label="Email" variant="outlined" value={searchParam.email || ""} onChange={(event) => handleSearchChange(event, "email")} />
                    <TextField
                         id="outlined-select-currency"
                         select
                         label="Statut"
                         onChange={(event) => handleSearchChange(event, "status")}
                         value={searchParam.status || ""}
                    >
                         <MenuItem key='a' value={""}>
                              Tous
                         </MenuItem>
                         {status.map((option, index) =>
                              option.code != "ENABLED" ?
                                   <MenuItem key={index} value={option.code}>
                                        {option.code}
                                   </MenuItem> : null

                         )}
                    </TextField>
               </Filter>
               <br />

               <SimpleCard title="Clients">
                    <br />
                    <PaginationTable data={clients} columns={columns} handleChangePage={handleChangePage} page={page} loading={loading} count={totalClient}>
                    </PaginationTable>
               </SimpleCard>
          </Container >
     );
}
export default ClientsDisabled;
