import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { Box, styled, Button, CircularProgress } from "@mui/material";
import { Container } from 'app/components/Content/Container';
import { Breadcrumb, SimpleCard } from "app/components";

import { getLogByName, getLogByNameApi, downloadLog, downloadLogApi, getCurrentLog } from "app/services/LogService";

const StyledBox = styled(Box)(({ theme }) => ({
     fontFamily: '"IBM Plex Mono", "Courier New", Courier, monospace',
     fontSize: '0.7rem',
     padding: '1rem',
     height: '100%',
     overflow: 'scroll',
     wordWrap: 'wrap',
     boxShadow: 'inset 0 0 0 1px rgba(0,0,0,.1)',
}));
const Span = styled("span")(({ theme }) => ({
     color: theme.palette.primary.main,
}));
const AuditLog = () => {
     const { log, filename } = useParams();
     const [auditlog, setAuditlog] = useState([]);
     const [loading, setLoading] = useState(false);


     useEffect(() => {
          setLoading(true);
          if (filename === "current") {
               getCurrentLog(log).then(res=>{
                    setAuditlog(res.data.split(/[\r\n]+/));
                    setLoading(false);
               }).catch(err => {
                    console.error(err);
                    setLoading(false);
               });
          } else {
               getLogByName(filename, log).then(res => {
                    setAuditlog(res.data.split(/[\r\n]+/));
                    setLoading(false);
               }).catch(err => {
                    console.error(err);
                    setLoading(false);
               });
          }
     }, []);
     const download = () => {
          const actionDownload = (res) => {
               try {
                    if (navigator.msSaveBlob) {
                         navigator.msSaveBlob(res, filename);
                    } else {
                         // Create a data URL and open it in a new window
                         const url = window.URL.createObjectURL(res);
                         const a = document.createElement('a');
                         a.href = url;
                         a.download = filename;
                         document.body.appendChild(a);
                         a.click();
                         // Cleanup the URL and anchor element
                         window.URL.revokeObjectURL(url);
                         document.body.removeChild(a);
                    }
               } catch (error) {
                    console.error('Error downloading resource:', error);
               }
          }
          downloadLog(filename).then(res => {
               actionDownload(res);
          });
     }

     const pattern = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{4} \[[^\]]+\] (INFO|ERROR)[^:]+:/g;
     const styledLog = (line, index) => {
          const matches = line.match(pattern);

          if (matches) {
               // Get the first match (assuming there's only one match in your example)
               const firstMatch = matches[0];

               // Replace the first match with a new value
               const replacedString = line.replace(firstMatch, '');
               // console.log(replacedString);
               return (<div key={index} ><Span color="primary">{firstMatch}</Span>{replacedString}</div>);
          } else {
               return (<div key={index} style={{ paddingLeft: "30px" }}>
                    {line}
               </div>)
          }
     }

     return (
          <Container>
               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={[{ name: 'Audit Logs', path: '/logs' }, { name: filename, path: `/logs/${filename==="current"?"Log actuel "+log:filename}` }]}
                    />
               </Box>
              

               <SimpleCard title={`${filename==="current"?"Log actuel "+log:filename}, cbs`}>

                    {filename==="current"?null:<><Box
                         sx={{
                              display: 'flex',
                              flexDirection: 'row-reverse',
                              borderRadius: 1,
                         }}>
                         <Button color="primary" variant="outlined" onClick={download}>Télécharger</Button>
                    </Box>
                    <br /></>}

                    {loading ? (<Box width="100%" align="center"><CircularProgress className="progress" /></Box>) :
                         (<StyledBox>
                              {auditlog.map((line, index) => {
                                   return (<span key={index}>{styledLog(line, index)}</span>)
                              })}
                         </StyledBox>)
                    }
               </SimpleCard>
          </Container>
     )
}
export default AuditLog;
