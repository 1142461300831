import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

//component
import { Container } from 'app/components/Content/Container';
import { Box, IconButton, Icon, Grid, Button } from "@mui/material";
import { Breadcrumb, SimpleCard } from "app/components";
import { PaginationTable } from "app/components/table/PaginationTable";
import SuccessDisplay from "app/components/Content/SuccessDisplay";

import { getLogs } from 'app/services/LogService';

const AuditLogs = ({ log = "account", title = "", routeSegment = [] }) => {
     const navigate = useNavigate();
     //const title=log==="transfer"? "Log des transactions 2PC":"Log des processus de création de compte";
     const [logs, setLogs] = useState([]);
     const [totalLogs, setTotalLogs] = useState(0);
     const [page, setPage] = useState(0);
     const [loadingLog, setLoadingLog] = useState(false);

     const columns = () => [
          {
               name: 'Fichiers',
               selector: (row) => row
          },
          {
               name: '',
               selector: (row) => (<IconButton onClick={() => navigate(`/logs/${log}/${row}`)}>
                    <Icon color="primary">keyboard_arrow_right</Icon>
               </IconButton>),
               align: "right"
          }
     ];
     const getAllLogs = (newPage=null) => {
          setLoadingLog(true);
          getLogs(newPage!=null?newPage:page, log).then(res => {
               // console.log(res.data.data);
               setLogs(res.data.data.list);
               setTotalLogs(res.data.data.total);
               setLoadingLog(false);
          }).catch(err => {
               console.error(err);
               setLogs([]);
               setLoadingLog(false);
          });
     }

     const handleChangePage = (_, newPage) => {
          newPage === 1 ? setPage(page + 1) : setPage(page - 1);
          getAllLogs(newPage === 1 ? (page + 1) : (page - 1));
     };
     useEffect(() => {
          getAllLogs();
     }, []);

     return (
          <>
          <Container>
               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={routeSegment}
                    />
               </Box>

               <Grid container spacing={3} sx={{ mb: '24px' }}>
                    <Grid item xs={12} sm={12} md={12}  >
                         <SimpleCard title={title}>
                              <Button variant="contained" color="primary" onClick={() => navigate(`/logs/${log}/current`)}>Log actuel</Button>
                              <br />
                              <PaginationTable data={logs} columns={columns()} handleChangePage={handleChangePage} page={page} loading={loadingLog} count={totalLogs}></PaginationTable>
                         </SimpleCard>
                    </Grid>
                    {/* <Grid item xs={6} sm={6} md={6}  >

                         <SimpleCard title="Log des transactions 2PC, cbs API">
                              <br />
                               <PaginationTable data={logsApi} columns={columns("api")} handleChangePage={handleChangePageApi} page={pageApi} loading={loadingLog2} count={totalLogsApi}></PaginationTable> 
                         </SimpleCard>
                    </Grid> */}
               </Grid>
          </Container>
          </>
     );
}
export default AuditLogs;

