import { useState, useEffect } from 'react'
import { styled, TextField, MenuItem, Button, Box } from '@mui/material';
import { formatOnlyDate } from 'app/services/Utils';
import { transformFrenchDate, getLastDayOfMonth, transformFrenchMonth } from 'app/services/Utils';
const SubTitle = styled('span')(({ theme }) => ({
     fontSize: '0.875rem',
     color: theme.palette.text.secondary,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
     margin: `${theme.spacing(2)} ${theme.spacing(0)}`,
     //width: "100%"
}));

const types = ["Jour", "Mois"];

const FilterChart = ({ setTypeTitle, getData = (type, startDate, endDate) => { }, oneType = null, chooseType = true, inline = false }) => {
     const [type, setType] = useState(oneType != null ? oneType : 1);
     const [startDate, setStartDate] = useState("");

     const [endDate, setEndDate] = useState(formatOnlyDate(new Date()));
     const changeTypeTitle = (sDate = null, eDate = null) => {
          if (type === 1 || type === "1") {
               setTypeTitle(transformFrenchDate(sDate == null ? startDate : sDate) + " au " + transformFrenchDate(eDate == null ? endDate : eDate));
          } else {
               setTypeTitle(transformFrenchMonth(sDate == null ? startDate : sDate) + " au " + transformFrenchMonth(eDate == null ? endDate : eDate));
          }
     }
     const getDatas = (sDate = null) => {

          sDate = sDate == null ? startDate : sDate;
          sDate = type == "2" ? sDate + "-01" : sDate;
          const eDate = type == "2" ? transformFrenchMonth(getLastDayOfMonth(endDate)) : endDate;
          getData(types[type - 1], sDate == null ? startDate : sDate, eDate);
     }
     useEffect(() => {
          try {
               let date = new Date();
               date.setMonth(date.getMonth() != 1 ? date.getMonth() - 1 : 12);
               date = formatOnlyDate(date)
               setStartDate(date);

               getDatas(date);
               changeTypeTitle(date);
          } catch (ex) {
               console.error(ex);
          }
     }, []);
     return (
          <>
               <SubTitle>Filtre</SubTitle>
               <Box
                    sx={inline ? {
                         '& .MuiTextField-root': { m: 1, width: '25ch' },
                    } : {}}
                    noValidate
                    autoComplete="off">

                   
                    {chooseType ?
                         <StyledTextField
                              id="outlined-select-currency"
                              variant="outlined"
                              select
                              label="Type"
                              sx={inline ? {} : { width: '100%' }}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => setType(e.target.value)}
                              value={type}
                         >
                              {oneType != null ? (<MenuItem value={oneType} selected>{types[oneType]}</MenuItem>) :
                                   <MenuItem value="1" selected>Jour</MenuItem>

                              }
                              {oneType == null ? <MenuItem value="2">Mois</MenuItem> : null}

                         </StyledTextField > : null
                    }

                    <StyledTextField
                         id="outlined-select-currency"
                         variant="outlined"
                         label="Début"
                         sx={inline ? {} : { width: '100%' }}
                         InputLabelProps={{ shrink: true }}
                         type={
                              type === 1 || type === "1" ? "date" : "month"
                         }
                         value={startDate}
                         onChange={(e) => {
                              setStartDate(e.target.value);
                         }}
                         max={formatOnlyDate(new Date())}
                    />
                    <StyledTextField
                         id="outlined-select-currency"
                         label="Fin"
                         sx={{ width: '100%' }}
                         InputLabelProps={{ shrink: true }}
                         type={
                              type === 1 || type === "1" ? "date" : "month"
                         }
                         value={endDate}
                         onChange={(e) => {
                              setEndDate(e.target.value);
                         }}
                         max={formatOnlyDate(new Date())}
                    />
                    <Button variant="contained" color="primary" sx={inline ? {display: "block"} : { width: '100%' }} onClick={() => {
                         changeTypeTitle();
                         getDatas()
                    }
                    }>Filtrer</Button>

               </Box>
          </>
     )
}
export default FilterChart;
