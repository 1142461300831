import React, { useState, useSelector } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, Icon, Dialog, DialogContent, DialogTitle, Button, Box, styled } from '@mui/material';
import SuccessDisplay from '../Content/SuccessDisplay';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { StyledTextField } from '../Content/CustomInput';
import { LoadButton } from '../Content/CustomLoadButton';
import ErrorDisplay from '../Content/ErrorDisplay';

import { doClientValidationAction } from '../../services/ClientService';
import { dispatchClientInfo } from 'app/redux/store';

const StyledBox = styled(Box)(({ theme, color = "primary" }) => ({
     borderRadius: '5px',
     border: '1px dashed',
     borderColor: theme.palette[color].main,
     padding: '5px',
}));
const validationSchema = Yup.object().shape({
     amount: Yup.number().required('Nom requis').min(1,'Le montant doit être supérieur à 0'),
     comments: Yup.string()
});
const ClientAction = ({ button = "valider", colorButton = "primary", messageSuccess = "Modification effectué",
     codeAction, title = null, clientUID, accountUID, actionButton, buildStepValidation = () => { }, firstDeposit = false, children }) => {
     const dispatch = useDispatch();
     // const stepValidation=useSelector((state)=>state.account.dataAccount.validationAccount);
     const [loading, setLoading] = useState(false);
     const [messageError, setMessageError] = useState('');
     const [error, setError] = useState(false);
     const [success, setSuccess] = useState(false);

     const [open, setOpen] = useState(false);
     const handleOpen = () => setOpen(true);
     const handleClose = () => { setError(false); setOpen(false) };

     const handleFormSubmit = async (values) => {
          setLoading(true);
          setSuccess(false);
          doClientValidationAction(codeAction,{ comments:values.comments,amount:values.amount}, clientUID, accountUID).then((res) => {
               setLoading(false);
               setSuccess(true);
               handleClose();
               dispatchClientInfo(dispatch, clientUID);
          }).catch((ex) => {
               setMessageError(ex.response.data.message)
               setLoading(false);
               setError(true);
          });
     }

     return (
          <div>
               <SuccessDisplay open={success} setOpen={setSuccess} message={messageSuccess} />
               {title !== null ? <StyledBox color={colorButton}>
                    <p>{title}</p>
                    <Button variant="contained" color={colorButton} onClick={handleOpen} sx={{ marginRight: '10px', width: "100%" }}>{button}</Button>
               </StyledBox> :
                    <Button variant="contained" color={colorButton} onClick={handleOpen} sx={{ marginRight: '10px', width: "100%" }}>{button}</Button>}

               <Dialog onClose={handleClose} aria-labelledby="form-dialog-title" open={open}>
                    <DialogTitle id="form-dialog-title" onClose={handleClose} component="div">
                         {title}
                    </DialogTitle>
                    <DialogContent dividers>
                         {children}
                         <Formik
                              onSubmit={handleFormSubmit}
                              method="POST"
                              initialValues={{ comments: "", amount:0 }} 
                              validationSchema={firstDeposit?validationSchema:null}>
                              
                              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                   <form onSubmit={handleSubmit}>
                                        {firstDeposit ? <StyledTextField
                                             multiline={true}
                                             size="small"
                                             type="number"
                                             name="amount"
                                             label="Montant"
                                             variant="outlined"
                                             onBlur={handleBlur}
                                             value={values.amount || ""}
                                             onChange={handleChange}
                                        /> : null}
                                        <StyledTextField
                                             multiline={true}
                                             rows="3"
                                             size="small"
                                             type="text"
                                             name="comments"
                                             label="Commentaires"
                                             variant="outlined"
                                             onBlur={handleBlur}
                                             value={values.comments || ""}
                                             onChange={handleChange}
                                        >
                                        </StyledTextField>
                                        <LoadButton loading={loading}>{actionButton}</LoadButton>

                                        <ErrorDisplay error={error} messageError={messageError} />
                                   </form>
                              )}
                         </Formik>
                    </DialogContent>
               </Dialog>
          </div>
     );
}
export default ClientAction;
