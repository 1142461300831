import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { SimpleCard, Breadcrumb } from "app/components";
import { Container } from 'app/components/Content/Container';
import { Box, Grid, FormControlLabel, Switch, Button } from "@mui/material";
import { TextFieldReadOnlyDisabled, StyledTextField } from "app/components/Content/CustomInput";
import { formatCompleteDate } from "app/services/Utils";

import { getUserById } from "app/services/UserService";

const ProfilUser = () => {
     const navigate = useNavigate();
     const [user, setUser] = useState([]);
     const [updatable, isUpdatable] = useState(false);
     useEffect(() => {
          try {
               const userInfo = JSON.parse(localStorage.getItem('user-info'));
               getUserById(userInfo.iduser).then((res) => {
                    // console.log(res.data.data);
                    setUser(res.data.data);
                    if (res.data.data.roles.filter((role) => (role.code === "ADMIN" || role.code === "MANAGE_USER")).length > 0) {
                         isUpdatable(true);
                    }
               });


          } catch (ex) {
               console.error(ex);
          }
     }, [])
     return (
          <Container>
               <Box className="breadcrumb">
                    <Breadcrumb
                         routeSegments={[{ name: 'Utilisateurs', path: '/users' }, { name: `${user.username}` }]}
                    />
               </Box>
               <SimpleCard title="Profil">
                    {updatable ? <div style={{ display: 'flex', justifyContent: 'flex-end' }}><Button variant="outlined" onClick={() => {
                         navigate(`/user/${user.iduser}`)
                    }}>Modifier le profil</Button></div> : ""}
                    <Grid container spacing={6}>
                         <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                              <StyledTextField
                                   size="small"
                                   type="text"
                                   name="username"
                                   label="Identifiant"
                                   variant="outlined"
                                   value={user.username || ""}
                              />
                              <StyledTextField
                                   size="small"
                                   type="text"
                                   name="name"
                                   label="Nom complet"
                                   variant="outlined"
                                   value={user.name || ""}
                              />
                              <TextFieldReadOnlyDisabled label="Téléphone"
                                   type="text"
                                   value={user.telephone || ""}
                              />
                              <StyledTextField
                                   size="small"
                                   type="text"
                                   name="email"
                                   label="Email"
                                   variant="outlined"
                                   value={user.email || ""}
                              />
                         </Grid>
                         <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                              <FormControlLabel
                                   label="Activé ?"
                                   control={
                                        <Switch
                                             name="isEnabled"
                                             value={"enabled"}
                                             checked={user.isEnabled || false}
                                             inputProps={{ "aria-label": "secondary checkbox" }} color="success"
                                        />} />
                              <TextFieldReadOnlyDisabled label="Date de création"
                                   type="date-time"
                                   value={formatCompleteDate(user.createdDate) || ""}
                              />
                              <TextFieldReadOnlyDisabled label="Date de modification"
                                   type="date-time"
                                   value={formatCompleteDate(user.updatedDate) || ""}
                              />
                         </Grid>

                    </Grid>
               </SimpleCard>
          </Container>

     )
}
export default ProfilUser;
