import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import './dashboard-client.css';
import { Grid, useTheme, lighten, Box, Typography } from '@mui/material';
import { SimpleCard } from "app/components";
import ClientNav from '../clients/ClientNav';
import AccountCard from './AccountCard';
import OperationCard from 'app/components/dashboard/OperationCard'
import BarEchart from 'app/components/dashboard/BarEchart';
import StatCardClient from 'app/components/dashboard/StatCardClient';
import FilterChart from '../dashboard/FilterChart';
import PdfGenerator from '../pdf/PdfGenerator';

import { getGlobalAnalyticsClient, getTransactionAnalyticsClient } from 'app/services/AnalyticsService';
import { formattedNumber } from 'app/services/Utils';

const DashBoardClient = () => {
     const { clientUID } = useParams();
     const { palette } = useTheme();

     const [client, setClient] = useState({});
     const [accounts, setAccounts] = useState([]);
     const [lastOperations, setLastOperations] = useState([]);
     const [totalNumberTransactions, setTotalNumberTransactions] = useState(0);
     const [totalAmountTransactions, setTotalAmountTransactions] = useState(0);
     const [totalAmountIn, setTotalAmountIn] = useState(0);

     const [transactions, setTransactions] = useState([]);

     const cardColor = [lighten(palette.primary.main, 0.50), palette.primary.main, lighten(palette.secondary.main, 0.50), palette.primary.light]
     const statColor = [240, 182, 5];

     const [typeTitle, setTypeTitle] = useState("");


     const getStat = (type, startDate, endDate) => {
          getTransactionAnalyticsClient(clientUID,type,startDate, endDate).then((res) => {
               const data = res.data.data;
               // console.log(data);
               setTransactions(data ? data.map(item => (
                    [item.date, item.amount]
               )) : []);
          });
     }
     useEffect(() => {
          try {
               getGlobalAnalyticsClient(clientUID).then((res) => {
                    const data = res.data.data;
                    // console.log(data);

                    setClient(data.client);
                    setAccounts(data.accounts);
                    setLastOperations(data.lastOperations);
                    setTotalNumberTransactions(data.totalNumberTransaction);
                    setTotalAmountTransactions(data.totalAmountTransaction);
                    setTotalAmountIn(data.totalAmountIn);
               });
          } catch (e) {
               console.error(e);
          }

     }, []);
     return (
          <ClientNav client={client} currentValue="4" clientUID={clientUID} section={{ name: "Analyses" }}>
               <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12} >
                         <div className="account-card-container"
                         >
                              {accounts.map((account, index) => (
                                   <AccountCard key={index} bg={cardColor[index % 4]} account={account} />
                              ))}
                         </div>
                    </Grid>
               </Grid>
               <br />
               <Grid container spacing={3}>
                    <Grid item lg={6} md={6} sm={6} xs={12} >
                         <SimpleCard>
                              <StatCardClient
                                   value={formattedNumber(totalAmountIn) + " Ar"}
                                   title="Total de solde"
                                   icon="view_comfy" color={statColor}
                              />
                              <StatCardClient
                                   value={formattedNumber(totalNumberTransactions)}
                                   title="Nombre de transactions de l'année"
                                   icon="view_comfy" color={statColor}
                              />
                              <StatCardClient
                                   value={formattedNumber(totalAmountTransactions) + " Ar"}
                                   title="Volume de transactions de l'année"
                                   icon="view_comfy" color={statColor}
                              />
                         </SimpleCard>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                         <Typography>Dernières activités</Typography>
                         <Box>
                              {lastOperations.map((last, index) => (
                                   <OperationCard
                                        operation={last}
                                        key={index}
                                   />
                              ))}
                         </Box>
                    </Grid>
               </Grid>
               <br />
               <Box>
                    <Typography>Volume de transactions</Typography>
                    <Grid container spacing={3}>
                         <Grid item lg={12} md={12} sm={12} xs={12} >
                              <br />
                              <FilterChart setTypeTitle={setTypeTitle} getData={getStat} inline={true}/>
                         </Grid>
                    </Grid>
                    <BarEchart title="Volume de transactions" height="600px" color={[palette.primary.dark, palette.primary.light]}
                         legend={["temps", "Volume transaction"]} data={transactions} />

               </Box>
          </ClientNav>
     )
}
export default DashBoardClient;
