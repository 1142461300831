import { Box, Icon, IconButton, Typography, Button } from '@mui/material';
import ClientStatus from './ClientStatus';
import { formatDate } from 'app/services/Utils';

const CardClientValidation = ({ icon, step, actived = false, children }) => {
     return (<Box sx={{
          backgroundColor: actived ? "white" : "#f5f5f5",
          borderRadius: "10px",
          boxShadow: "3px 3px 3px rgba(200,200,200,0.5)",
          padding: "10px",
          marginBottom: "10px",
     }}>
          <div>
               <IconButton>
                    <Icon color="primary" >{icon}</Icon>
               </IconButton>
               {step}
          </div>
          {/* <br /> */}
          <div className="account-card-action">
               {children}
          </div>
     </Box>)
}
export default CardClientValidation;
