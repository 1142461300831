import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import routes from './routes';
import { Provider, combineReducers } from 'react-redux';
import { store } from './redux/store';
import { ApiProvider } from './contexts/ApiContext';
// Crée le store 

const App = () => {
  const content = useRoutes(routes);

  return (
    <Provider store={store}>
      <SettingsProvider>
        <AuthProvider>
          <ApiProvider>

            <MatxTheme>
              <CssBaseline />
              {content}
            </MatxTheme>
          </ApiProvider>

        </AuthProvider>
      </SettingsProvider>
    </Provider>
  );
};

export default App;
