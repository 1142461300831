import { baseUrl, getCall, downloadFile} from './Api.js';

// const logUrlApi=`http://localhost:8090`;
const logUrlApi=`https://cbs.ibonia.com`;

export const getLogs=(page,log="transfer")=>{
     const param={
          offset:page,
          limit:10
     }
     return getCall(`${baseUrl}/cbs/logs/${log}`, true, param);
}

export const getLogByName=(filename, log="transfer")=>{
     return getCall(`${baseUrl}/cbs/logs/${log}/${filename}`, true);
}
export const getCurrentLog=(log="transfer")=>{
     return getCall(`${baseUrl}/cbs/logs/${log}/current`, true);
}

export const getLogsApi=(page, log="transfer")=>{
     const param={
          offset:page,
          limit:5
     }
     return getCall(`${logUrlApi}/cbs/logs/${log}`, true, param);
}

export const getLogByNameApi=(filename, log="transfer")=>{
     return getCall(`${logUrlApi}/cbs/logs/${log}/${filename}`, true);
}

export const downloadLog=(filename, log="transfer")=>{
     return downloadFile(`${baseUrl}/cbs/logs/${log}/${filename}/download`, true);
}
export const downloadLogApi=(filename, log="transfer")=>{
     return downloadFile(`${logUrlApi}/cbs/logs/${log}/${filename}/download`, true);
}
